import { Link } from "react-router-dom";
import logo from "../../../../assets/logo.png";
import "./Logo.scss";

const Logo = ({ path }: any) => {
  return (
    <div className="logo">
      <Link to={path}>
        <img src={logo} />
      </Link>
    </div>
  );
};

export default Logo;
