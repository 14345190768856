import { useEffect } from "react";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, updateExpertStatus } from "../users/usersSlice";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "app/features/tools/Loading";
import avatar from "../../../../../assets/avatar.png";
import "./Experts.scss";

const Experts = () => {
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const users = useSelector((state: RootState) => state.users.users);
  const loading = useSelector((state: RootState) => state.users.loading);
  const dispatch = useDispatch();

  function inputsHelper(expert: any) {
    return {
      id: expert.id,
      name: expert.name,
      email: expert.email,
      username: expert.username,
      role: expert.role,
      country: expert.country,
      charity: expert.charity,
      approved: expert.approved,
      denied: expert.denied,
    };
  }

  const handleApprove = async (e: any) => {
    const experts = users as any;
    const expert = experts[e.target.id];
    if (expert["approved"]) return;
    const inputs = inputsHelper(expert);
    inputs["approved"] = true;
    const response = (await dispatch(
      updateExpertStatus({ token: accessToken, user: inputs })
    )) as any;
  };

  const handleDisable = async (e: any) => {
    const experts = users as any;
    const expert = experts[e.target.id];
    if (!expert["approved"]) return;
    const inputs = inputsHelper(expert);
    inputs["approved"] = false;
    const response = (await dispatch(
      updateExpertStatus({ token: accessToken, user: inputs })
    )) as any;
  };

  const handleDeny = async (e: any) => {
    const experts = users as any;
    const expert = experts[e.target.id];
    const inputs = inputsHelper(expert);
    inputs["denied"] = true;
    const response = (await dispatch(
      updateExpertStatus({ token: accessToken, user: inputs })
    )) as any;
  };

  async function response() {
    dispatch(getUsers({ token: accessToken, role: "expert" }));
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="admin_experts">
      <div className="content_header">
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchIcon />
          </div>
          <div className="items">
            <div className="item"></div>
          </div>
        </div>
      </div>
      <div className="content_body">
        <div className="content_body_header">
          <h2>Experts</h2>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {users?.map((v: any, i: any) => {
              return (
                <div key={v.id} className="admin_expert_card">
                  <div>
                    <img
                      style={{ width: 50, height: 50, borderRadius: "50%" }}
                      src={v.avatar ? v.avatar : avatar}
                    />
                    <div>Name: {v.name}</div>
                    <div>Status: {v.approved ? "Approved" : "Pending"}</div>
                  </div>
                  <div className="admin_expert_controls">
                    <button
                      className="approve"
                      id={i}
                      onClick={handleApprove}
                      style={{ display: v.approved ? "none" : "block" }}
                    >
                      approve
                    </button>
                    <button
                      className="deny"
                      id={i}
                      onClick={handleDeny}
                      style={{ display: v.approved ? "none" : "block" }}
                    >
                      deny
                    </button>
                    <button className="disable" id={i} onClick={handleDisable}>
                      disable
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Experts;
