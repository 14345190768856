import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; //step 1

import {
  createSubmission,
  readSubmissions,
  readSubmission,
  deleteSubmission,
  readChallengeSubmissions,
  readSubmissionFeedback,
  readRequestFeedbacks,
} from "../../api/submissionAPI";

interface SubmissionState {
  submission?: any;
  submissions?: any;
  loading: boolean;
  error: boolean;
  errorMessages: string[];
}

interface submissionsData {
  token: string | undefined;
  submission: Submission | any;
}

interface submissionData {
  token: string | undefined;
  submission: any;
}

interface feedbackData {
  token: string | undefined;
  id: number;
}

const initialState: SubmissionState = {
  submission: [],
  submissions: [],
  loading: true,
  error: false,
  errorMessages: [],
};

export interface Submission {
  description: string;
  challenge_id: string | undefined;
}

export const submitSubmission = createAsyncThunk(
  "submission/submitSubmission",
  async (payload: submissionsData, { rejectWithValue }) => {
    const response = await createSubmission(payload.token, payload.submission);
    // if response has errors rejectwithvalue
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload

    return response;
  }
);

export const getSubmissions = createAsyncThunk(
  "submission/getSubmissions",
  async (payload: submissionData, { rejectWithValue }) => {
    const response = await readSubmissions(payload.token, payload.submission);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getSubmission = createAsyncThunk(
  "submission/getSubmission",
  async (payload: submissionData, { rejectWithValue }) => {
    const response = await readSubmission(payload.token, payload.submission);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getChallengeSubmissions = createAsyncThunk(
  //Should be in Challenge
  "submission/getChallengeSubmissions",
  async (token: string | undefined, { rejectWithValue }) => {
    const response = await readChallengeSubmissions(token);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getSubmissionFeedback = createAsyncThunk(
  "submission/getSubmissionFeedback",
  async (payload: feedbackData, { rejectWithValue }) => {
    const response = await readSubmissionFeedback(payload);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const destroySubmission = createAsyncThunk(
  "submission/destroySubmission",
  async (payload: submissionData, { rejectWithValue }) => {
    const response = await deleteSubmission(payload.token, payload.submission);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

// MOVE THIS TO CREATOR SLICE WHEN YOU CREATE IT -- REMOVE THIS
// export const getUserRequestFeedback = createAsyncThunk(
//   "submission/getUserRequestFeedback",
//   async (payload: submissionData, { rejectWithValue }) => {
//     const response = await readRequestFeedbacks(payload);
//     if (response.error) {
//       return rejectWithValue(response.data);
//     }

//     return response;
//   }
// );
// MOVE THIS TO CREATOR SLICE WHEN YOU CREATE IT

export const submissionSlice = createSlice({
  name: "submission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChallengeSubmissions.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallengeSubmissions.fulfilled, (state, action: any) => {
        state.submissions = action.payload.submissions.flat();
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallengeSubmissions.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      });
  },
});

// step 6
//   export const { } = submissionSlice.actions;

//step 7
export default submissionSlice.reducer;
