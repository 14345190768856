import { Routes, Route } from "react-router-dom";
import PrivateRoute from "app/features/routes/PrivateRoute";
import Challenges from "app/features/challenges/Challenges";
import Challenge from "app/features/challenges/challenge/Challenge";
import LeaderboardExpanded from "app/features/challenges/challenge/leaderboard/LeaderboardExpanded";
import JudgesListExpanded from "app/features/challenges/challenge/judges_list/JudgesListExpanded";
import Payment from "app/features/challenges/challenge_payment_method/ChallengePaymentMethod";
import Pay from "app/features/challenges/challenge_payment_method/pay/Pay";
import Uploaded from "app/features/submissions/Uploaded";
import { useLocation } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

const ChallengesRoutes = () => {
  const location = useLocation();
  const { search } = location as any;
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  return (
    <>
      <Routes>
        {/* <Route
          index
          element={
            <PrivateRoute>
              <Challenges />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path={"/:id"}
          element={
            <PrivateRoute>
              <Challenge />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/:id/leaderboard_list"
          element={
            <PrivateRoute>
              <LeaderboardExpanded />
            </PrivateRoute>
          }
        />
        <Route
          path="/:id/judges_list"
          element={
            <PrivateRoute>
              <JudgesListExpanded />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/:id/payment"
          element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="/:id/payment/pay"
          element={
            <PrivateRoute>
              <Pay />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="paid/:id"
          element={
            <PrivateRoute>
              <Submissions />
            </PrivateRoute>
          }
        /> */}
        {/* <Route
          path="uploaded"
          element={
            <PrivateRoute>
              <Uploaded>
                {!search ? (
                  <SubmissionSuccess name={currentUser?.username} />
                ) : (
                  <OneToOneSuccess />
                )}
              </Uploaded>
            </PrivateRoute>
          }
        /> */}
      </Routes>
    </>
  );
};

export default ChallengesRoutes;

function OneToOneSuccess() {
  return (
    <>
      <p>The video and text response you uploaded were</p>
      <p>successfully sent to the expert!</p>
    </>
  );
}

function SubmissionSuccess({ name }: any) {
  return (
    <>
      <p>
        The files you uploaded were <b>successfully added!</b>
      </p>
      <p>Good Luck, {name}!</p>
    </>
  );
}
