import List from "./list/List";
import UsersForm from "./users_form/UsersForm";
import ConfirmationModal from "./confirmationModal/ConfirmationModal";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { destroyUsers, getUsers } from "./usersSlice";
import Loading from "app/features/tools/Loading";
import "./Users.scss";

export default function Users() {
  const refreshToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const users = useSelector((state: RootState) => state.users.users);
  const loading = useSelector((state: RootState) => state.users.loading);

  const dispatch = useDispatch();
  const [show, setShow] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<Array<number>>([]);

  const [confirmation, setConfirmation] = useState<boolean>(false);

  function handleRowSelection(selectionModel: any) {
    setSelectionModel(selectionModel);
  }
  function closeModal(event: any) {
    setConfirmation(false);
  }

  function handleClick(event: any) {
    if (selectionModel.length === 0) return;
    setConfirmation(true);
  }

  function sendData(event: any) {
    setConfirmation(false);
    dispatch(destroyUsers({ token: refreshToken, ids: selectionModel }));
  }

  function handleCreateUser(event: any) {
    setShow(true);
  }

  async function response() {
    dispatch(getUsers({ token: accessToken, role: undefined }));
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="admin_users">
      <div className="content_header">
        {/* <Navbar handleClick={handleClick} setShow={setShow} /> */}
        <div className="wrapper">
          {/* MAKE INTO CONPONENT */}
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchIcon />
          </div>
          <div className="items">
            <div className="item">
              <DeleteIcon
                className="icon"
                style={{ color: "red" }}
                onClick={handleClick}
              />
            </div>
            <div className="item">
              <PersonAddAltRoundedIcon
                className="icon"
                onClick={handleCreateUser}
              />
            </div>
            <div className="item">
              <DarkModeIcon className="icon" />
            </div>
            <div className="item">
              <NotificationsIcon className="icon" />
              <div className="counter">2</div>
            </div>
          </div>
        </div>
      </div>
      <section className="content_body">
        <div className="content_body_header">
          <h2>Users</h2>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {" "}
            <List rows={users} handleRowSelection={handleRowSelection} />
            <UsersForm show={show} setShow={setShow} />
            <ConfirmationModal
              confirmation={confirmation}
              closeModal={closeModal}
              sendData={sendData}
            />
          </>
        )}
      </section>
    </div>
  );
}
