import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import VideoRecorder from "app/features/media/recorder/VideoRecorder";
import FileUpload from "app/features/media/file_upload/FileUpload";
import Selfie from "app/features/media/selfie/Selfie";

import { submitRequestFeedback } from "../expertSlice";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { FaChevronLeft } from "react-icons/fa";
import { create_slug } from "app/tools";
import photo from "assets/images/experts/photo.png";
import browse from "assets/images/experts/browse.png";
import video_icon from "assets/images/experts/video.png";
import "./ExpertRequestFeedbackForm.scss";

const ExpertRequestFeedbackForm = () => {
  const locate = useLocation();
  const { state } = locate as any;
  const { id, amount } = state;
  interface ExpertSubmission {
    description: string;
    medias: any;
    price: number;
    judge_id: number;
  }

  const initialState: ExpertSubmission = {
    description: "",
    medias: "",
    price: 0,
    judge_id: id,
  };

  const [showSelfie, setShowSelfie] = useState<boolean>(false);
  const [showRecorder, setShowRecorder] = useState<boolean>(false);
  const [word, setWord] = useState(0);
  const [alert, setAlert] = useState(false);

  const [inputs, setInputs] = useState<ExpertSubmission>(initialState);

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };

  function handleChange(e: any) {
    const { name, value } = e.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    const word_list = value.split(" ").filter((v: any, i: any) => v != false);
    setWord(word_list.length);
  }

  // async function handleSubmit(e: any) {
  // e.preventDefault();
  // if (!inputs.medias) return;
  // const formData = new FormData();
  // inputs.price = amount;

  // for (const prop in inputs) {
  //   if (prop === "medias") continue;
  //   formData.append(`request_feedback[${prop}]`, (inputs as any)[prop]);
  // }

  // formData.append("request_feedback[medias][]", inputs.medias);

  // const response = (await dispatch(
  //   submitRequestFeedback({ token: accessToken, request_feedback: formData })
  // )) as any;

  // if (!response.payload.err) {
  //   navigate(`/creator/expert_pay`, {
  //     state: { amount },
  //   });
  // }
  //ERRORS GET TAKEN CARE OF IN THE SLICE CODE
  // }

  const handleSelfie = () => {
    setShowSelfie((prev) => {
      // document.body.style.overflow = !prev ? "hidden" : "scroll";
      return !prev;
    });
    setShowRecorder(false);
  };

  const handleRecorder = () => {
    setShowRecorder((prev) => {
      // document.body.style.overflow = !prev ? "hidden" : "scroll";
      return !prev;
    });
    setShowSelfie(false);
  };

  const handleRemoveFile = (e: any) => {
    setInputs((prevState: any) => ({ ...prevState, medias: "" }));
  };

  return (
    <div className="expert_request_feedback_form">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Upload your files</h3>
          <p>
            Select files, take a picture or video, or write the type of feedback
            you want it to recieve feedback from the expert you choose.
          </p>
        </div>
        <Alert alert={alert} setAlert={setAlert} />
      </div>
      <div className="upload_container">
        {showRecorder && (
          <VideoRecorder
            setInputs={setInputs}
            handleRecorder={handleRecorder}
          />
        )}
        {showSelfie && (
          <Selfie setInputs={setInputs} handleSelfie={handleSelfie} />
        )}
        <form>
          <div className="upload_selection">
            <div>
              <img src={photo} />
              <p>
                Maximum <b>50 MB</b>
              </p>
              <p onClick={handleSelfie}>Take photo</p>
            </div>
            <div>
              <img src={browse} />
              <p>Upload a file</p>
              <FileUpload setInputs={setInputs} />
            </div>
            <div>
              <img src={video_icon} />
              <p>
                You&apos;ll have <b>120 seconds</b> to record your video.
              </p>
              <p onClick={handleRecorder}>Record video</p>
            </div>
          </div>
          <FileTable inputs={inputs} handleRemoveFile={handleRemoveFile} />
          <div className="description_section">
            <span>Description:</span>
            <textarea
              value={inputs.description}
              name="description"
              onChange={handleChange}
              placeholder="Write here"
            ></textarea>
            <span>{word}/1000</span>
          </div>
          <div className="btn_container">
            <button type="submit" disabled={!inputs.medias ? true : false}>
              Proceed to payment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExpertRequestFeedbackForm;

export function FileTable({ inputs, handleRemoveFile }: any) {
  function bytesToSize(bytes: any) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  }

  return (
    <div
      className="uploaded_files"
      style={{ display: inputs.medias ? "block" : "none" }}
    >
      <h3>Your file</h3>
      <table>
        <thead>
          <tr>
            <th>File name</th>
            <th>File size</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{inputs.medias?.name}</td>
            <td>{bytesToSize(inputs.medias?.size)}</td>
            <td>
              <span onClick={handleRemoveFile}>X</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Alert({ alert, setAlert }: any) {
  const handleRemoveAlert = (e: any) => setAlert(false);
  return (
    <div
      className="upload_alert_modal"
      style={{ display: alert ? "block" : "none" }}
    >
      <div className="upload_alert_header">
        <p>File size too big</p>
        <span onClick={handleRemoveAlert}>X</span>
      </div>
      <div className="upload_alert_body">
        <p>The maximum size of a file that can be uploaded in 50 mb</p>
      </div>
    </div>
  );
}
