import { useLocation, useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import avatar from "../../../../../assets/avatar.png";
import "./JudgesListExpanded.scss";

const JudgesListExpanded = () => {
  const location = useLocation();
  const { state } = location as any;
  const judges = JSON.parse(state);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };
  return (
    <div className="judge_list_expanded">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Judges</h3>
          <p>Take a look at the judges for this challenge...</p>
        </div>
      </div>
      <div className="body">
        <div className="judges_list_body">
          {judges?.map((v: any, i: any) => {
            return (
              <div key={i} className="judge_list_item">
                <div className="judge_list_item_avatar_container">
                  <img src={v.avatar ? v.avatar : avatar} />
                </div>
                <div className="judge_list_item_name_request_feedback">
                  <h3>{v.name}</h3>
                  <p>Actor - Fifth Line</p>
                  <p>$100.00</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JudgesListExpanded;
