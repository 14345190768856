import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSubmissions } from "../../submissions/submissionSlice";
import { getIndividualFeedbackRequests } from "../../experts/expertSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import CreatorItem from "../creator_item/CreatorItem";
import { create_slug } from "app/tools";
import "./CreatorContent.scss";

const CreatorContent = () => {
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [feedbacks, setFeedbacks] = useState<any[]>([]);

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const slug = create_slug(currentUser?.name);

  const dispatch = useDispatch();

  async function response() {
    const submission = { token: accessToken, submission: currentUser?.id };
    const request = { token: accessToken, id: currentUser?.id };
    const res = (await dispatch(getSubmissions(submission))) as any;
    const feedback = (await dispatch(
      getIndividualFeedbackRequests(request)
    )) as any;
    const submissions_sliced = res.payload.submissions.slice(0, 4);
    setSubmissions(submissions_sliced);
    const feedback_sliced = feedback.payload.requests.slice(0, 4);
    setFeedbacks(feedback_sliced);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div>
      <ContentContainer
        creator={"feedback"}
        title={"1-1 feedback submissions"}
        slug={slug}
      >
        {feedbacks.map((v: any, i: any) => {
          return (
            <CreatorItem
              key={i}
              media={!v.request_media ? "" : v.request_media}
              description={v.request.description}
              type={v.type}
            ></CreatorItem>
          );
        })}
      </ContentContainer>{" "}
      {/* <ContentContainer
        creator={"creator_challenges"}
        title={"Challenge submissions"}
        slug={slug}
      >
        {submissions.map((v: any, i: any) => {
          return (
            <CreatorItem
              key={i}
              media={v.media}
              description={v.submission.description}
              type={v.type}
            />
          );
        })}
      </ContentContainer>{" "} */}
    </div>
  );
};

export default CreatorContent;

function ContentContainer({ creator, title, children, slug }: any) {
  const navigate = useNavigate();
  const handleShowAll = (e: any) => {
    const { name } = e.target.dataset;
    navigate(`/creator/${slug}/creator_tabs/${creator}`, { state: { name } });
  };

  return (
    <div className={`creator_items ${creator}`}>
      <div className="creator_items_header">
        <p className={creator}>{title}</p>
        <span onClick={handleShowAll} data-name={title}>
          Show all &nbsp; &gt;
        </span>
      </div>
      <div className="creator_item_list">{children}</div>
    </div>
  );
}
