import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Star from "app/features/tools/star/Star";
import { RootState } from "app/store";
import { validate, validateErrors } from "../../../../tools";
import { submitResetPassword } from "../../sessionSlice";
import "./ResetPassword.scss";

const ResetPassword = () => {
  const { token } = useParams();
  interface Inputs {
    email: string;
    password: string;
    token: string;
  }

  const initialState: Inputs = {
    email: "",
    password: "",
    token: "",
  };

  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );

  const msg = useSelector((state: RootState) => state.session.msg);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [inputs, setInputs] = useState<Inputs>(initialState);
  // const [msg, setMsg] = useState(false);
  const [valid, setValid] = useState({
    email: "*",
    password: "*",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate("/");
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
    // console.log("CHILDREN", name, value, valid, inputs);
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    (await dispatch(submitResetPassword(inputs))) as any;

    if (errorMessages.length > 0) {
      //This should update message
      setErrors(errorMessages);
    }
  }

  useEffect(() => {
    setInputs((prevState: any) => ({ ...prevState, token: token }));
  }, []);

  return (
    <section className="reset_password">
      {" "}
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
        <Star />
        <div className="title">
          <h3>Reset your Password</h3>
        </div>
        <div>
          <p>Enter your new password.</p>
        </div>
        <div style={{ display: msg ? "block" : "none" }}>
          <p>{msg}</p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                value={inputs.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Enter password"
                value={inputs.password}
                onChange={handleChange}
                required
              />
              <div className="valid_check"></div>
            </div>
          </div>
          <button type="submit" disabled={!validate(valid) ? true : false}>
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
