export const partition = (arr: any) => {
  const unjudged: any[] = [];
  let judged = arr;
  judged = judged.filter(function (v: any) {
    if (v.feedback.length == 0) {
      unjudged.push(v);
    } else {
      return true;
    }
  });
  return [unjudged, judged];
};

export const challengePartition = (arr: any) => {
  const overdue: any[] = [];
  let current = arr;
  current = current.filter(function (v: any) {
    const date_now = new Date();
    const date = new Date(v.challenge.end_date);
    if (date < date_now) {
      overdue.push(v);
    } else {
      return true;
    }
  });
  return [current, overdue];
};

export const requestPartition = (arr: any) => {
  const overdue: any[] = [];
  let request = arr;

  function addDays(date: any, days: any) {
    date.setDate(date.getDate() - days);
    return date;
  }

  const date = new Date();

  request = request.filter(function (v: any) {
    const created_at = new Date(v.request.created_at);
    const newDate = addDays(date, 2);
    if (created_at.getTime() < newDate.getTime()) {
      overdue.push(v);
    } else {
      return true;
    }
  });
  return [request, overdue];
};

export const requestAnsweredPartition = (arr: any) => {
  const answered: any[] = [];
  let request = arr;

  request = request.filter(function (v: any) {
    if (v.request.status === "submitted") {
      answered.push(v);
    } else {
      return true;
    }
  });
  return [request, answered];
};

export const parseTime = (time: any) => {
  const date = +new Date(time);
  let delta = Math.abs(date - +new Date()) / 1000;
  let days: string | number = Math.floor(delta / 86400);
  delta -= days * 86400;
  days = days < 10 ? "0" + days : days;

  let hours: string | number = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  hours = hours < 10 ? "0" + hours : hours;

  let mins: string | number = Math.floor(delta / 60) % 60;
  delta -= mins * 60;
  mins = mins < 10 ? "0" + mins : mins;

  const seconds = delta % 60; //
  return { days, hours, mins, seconds };
};

export const parseTimePast = (time: any) => {
  const date1 = new Date(time);
  const date2 = new Date();
  const time_difference = date2.getTime() - date1.getTime();
  const days = time_difference / (1000 * 60 * 60 * 24);
  return Math.floor(Math.abs(days));
};
