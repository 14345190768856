import { useState, useEffect } from "react";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getSubmissionFeedback } from "../submissionSlice";
import { useLocation, useNavigate } from "react-router-dom";
import SubmissionStar from "./SubmissionStar";
import "./SubmissionFeedback.scss";

const SubmissionFeedback = () => {
  const location = useLocation();
  const { state } = location as any;
  const [data, setData] = useState([]);

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const dispatch = useDispatch();

  async function response() {
    const payload = { token: accessToken, id: state.submission.id };
    const res = (await dispatch(getSubmissionFeedback(payload))) as any;
    setData(res.payload);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div>
      <Feedback data={data} />
    </div>
  );
};

export default SubmissionFeedback;

function Feedback({ data }: any) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleRequestFeedback = (judge: any) => {
    navigate("/submission/request_feedback");
  };

  return (
    <div>
      <div className="submission_feedback_header">
        <button onClick={goBack}>Back</button>
      </div>
      <div className="criteria_container">
        <div style={{ textAlign: "center" }}>Total: {data?.total}</div>
        {data?.judges?.map((v: any, i: any) => {
          return (
            <div key={i} style={{ height: 400, border: "thin solid black" }}>
              {v.value === 0 ? (
                <div>Feedback not ready yet</div>
              ) : (
                <div>Score: {v.value}</div>
              )}
              {/* THIS SHOULD BE A CONDITIONAL USING data?.judges.criteria.length */}
              <div>
                {v.criteria.map((v: any, j: any) => {
                  return (
                    <div key={j}>
                      <SubmissionStar
                        name={v.criterium.name}
                        rating={v.value}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="feedback_container">
                <textarea
                  style={{ display: v.feedback ? "block" : "none" }}
                  value={v.feedback ? v.feedback.content : ""}
                  readOnly
                />
              </div>
              <div className="request_feedback_btn">
                <button onClick={() => handleRequestFeedback(v.judge)}>
                  Request Feedback
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
