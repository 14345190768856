import { useState, useEffect } from "react";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
// import { getRequestFeedbacks, getChallengeSubmissions } from "../expertSlice";
import {
  getRequestFeedbacks,
  getChallengeSubmissions,
} from "../experts/expertSlice";
import avatar from "../../../assets/avatar.png";
import "./Profile.scss";

import ProfileForm from "app/features/profile/profile_form/ProfileForm";
import ProfileContent from "./profle_content/ProfileContent";
// import CurrentOneToOneFeedbackRequest from "../experts/experts_landing_page/current_one_to_one_feedback_request/CurrentOneToOneFeedbackRequest";

export default function Profile() {
  const [showModal, setShowModal] = useState(false);

  // const [open, setOpen] = useState<boolean>(false);
  // const [show, setShow] = useState<boolean>(false);
  // const [video, setVideo] = useState();
  // const [upload, setUpload] = useState({});
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  // const answered = useSelector((state: RootState) => state.expert.answered);
  // const pending = useSelector((state: RootState) => state.expert.pending);
  // const ongoing_challenges = useSelector(
  //   (state: RootState) => state.expert.ongoing_challenges
  // );
  // const overdue_challenges = useSelector(
  //   (state: RootState) => state.expert.overdue_challenges
  // );

  // const ongoing_requests = useSelector(
  //   (state: RootState) => state.expert.ongoing_requests
  // );
  // const overdue_requests = useSelector(
  //   (state: RootState) => state.expert.overdue_requests
  // );

  // const current_requests_sliced = ongoing_requests.slice(0, 3);
  // const overdue_requests_sliced = overdue_requests.slice(0, 3);

  const dispatch = useDispatch();

  function toggleShow() {
    setShowModal((prev) => {
      // document.body.style.overflow = !prev ? "hidden" : "scroll";
      return !prev;
    });
  }

  async function response() {
    // await dispatch(getJudgeNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    // await dispatch(getNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
    await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
  }, []);
  return (
    <section className="profile">
      <div className="header"></div>
      <div className="body">
        <div className="data_area">
          <div className="avatar_container">
            <div className="avatar_wrapper">
              <img src={!currentUser?.avatar ? avatar : currentUser?.avatar} />
              <div></div>
            </div>
          </div>
          <div className="data_container">
            <h1>{currentUser?.name}</h1>
            <h4>{currentUser?.official_title}</h4>
            <button>
              <div></div> Expert
            </button>
          </div>
          <div className="edit_container">
            <button onClick={toggleShow}>
              Edit <div></div>
            </button>
          </div>
        </div>
        <div className="text_area">
          <h3>About me</h3>
          <p>
            {currentUser?.bio}
          </p>
        </div>
        <div className="profile_body">
          <ProfileContent />
        </div>
      </div>
      <ProfileForm toggleShow={toggleShow} show={showModal} />
    </section>
  );
}
