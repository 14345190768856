import { useLocation, useNavigate } from "react-router-dom";
import Criteria from "../../../criteria/Criteria";

const JudgeForm = () => {
  const location = useLocation();
  const { state } = location as any;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div
      className="submission_judging_container"
      style={{ paddingTop: "13vh" }}
    >
      <div className="submission_judging_header">
        <button onClick={goBack}>Back</button>
      </div>
      <div className="submission_judging_body">
        <div
          className=""
          style={{ borderBottom: "thin solid #aaa", textAlign: "center" }}
        >
          <div className="">Challenge</div>
          <div className="">
            <video
              style={{ height: 200, width: 300 }}
              src={state?.challenge_media}
              controls
            ></video>
          </div>
        </div>
        <div
          className=""
          style={{ borderBottom: "thin solid #aaa", textAlign: "center" }}
        >
          <div className="">Submission</div>
          <div className="">
            <video
              style={{ height: 200, width: 300 }}
              src={state?.submission_media}
              controls
            ></video>
          </div>
        </div>
        {state.feedback ? (
          <div style={{ height: 300, display: "grid", placeItems: "center" }}>
            Already judged this submission
          </div>
        ) : (
          <Criteria
            criteria={state?.criteria}
            id={state?.submission.id}
            feedback={state?.feedback}
          />
        )}
      </div>
    </div>
  );
};

export default JudgeForm;
