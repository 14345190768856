import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getChallengeSubmissions } from "app/features/experts/expertSlice";
import CurrentChallenge from "app/features/experts/experts_landing_page/current_challenge/CurrentChallenge";

const PendingChallengeTab = ({ setOpen, setVideo, setUpload }: any) => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const ongoing_challenges = useSelector(
    (state: RootState) => state.expert.ongoing_challenges
  );
  async function response() {
    await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
  }, []);

  return (
    <div className="items">
      {" "}
      {ongoing_challenges.map((v: any, i: any) => {
        return (
          <CurrentChallenge
            v={v}
            key={i}
            setOpen={setOpen}
            setVideo={setVideo}
            setUpload={setUpload}
          />
        );
      })}
    </div>
  );
};

export default PendingChallengeTab;
