import { createSlice } from "@reduxjs/toolkit";

interface AuthState {
  open?: boolean;
}

const initialState: AuthState = {
  open: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    toggleProfile: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const { toggleProfile } = profileSlice.actions;

//step 7
export default profileSlice.reducer;
