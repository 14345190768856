import { Key, useEffect, useState } from "react";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getLeaders } from "../../challengeSlice";
import { useNavigate } from "react-router-dom";

// import { getLeaders } from "../sessions/sessionSlice";
import "./Leaderboard.scss";
import { BiSearch } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";

const Leaderboard = () => {
  // const [leaders, setLeaders] = useState([]);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const leaders = useSelector((state: RootState) => state.challenge.leaders);

  // const loading = useSelector((state: RootState) => state.session.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowAll = () =>
    navigate("leaderboard_list", { state: JSON.stringify(leaders) });

  useEffect(function () {
    async function response() {
      await dispatch(getLeaders(accessToken));
    }
    response();
  }, []);
  return (
    <div className="leaderboard">
      <div className="leaderboard_header">
        <h1>Leaderboard</h1>
        <button onClick={handleShowAll}>
          Show All <FiChevronRight />
        </button>
      </div>
      <div className="leaderboard_title">
        <span>Name</span>
        <span>Rank</span>
        <span>Points</span>
      </div>
      <div className="leaderboard_body">
        <div className="leaderboard_item">
          <div className="leaderboard_item_avatar">
            <img src={leaders[0] && leaders[0].avatar} />
          </div>
          <div className="leaderboard_item_info">
            <div className="leaderboard_item_name">
              <h5>{leaders[0] && leaders[0].name}</h5>
            </div>
            <div className="leaderboard_item_place">
              🥇{" "}
              <span>
                {(leaders[0] && leaders[0].place) || "1st Place!"} &#8729; $
                {395}
              </span>
            </div>
            <div className="leaderboard_item_points">
              <h5>
                {39} <span className="pts">pts</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="leaderboard_item">
          <div className="leaderboard_item_avatar">
            <img src={leaders[0] && leaders[0].avatar} />
          </div>
          <div className="leaderboard_item_info">
            <div className="leaderboard_item_name">
              <h5>{leaders[0] && leaders[0].name}</h5>
            </div>
            <div className="leaderboard_item_place">
              🥈{" "}
              <span>
                {(leaders[0] && leaders[0].place) || "2nd Place!"} &#8729; $
                {250}
              </span>
            </div>
            <div className="leaderboard_item_points">
              <h5>
                {37} <span className="pts">pts</span>
              </h5>
            </div>
          </div>
        </div>
        <div className="leaderboard_item">
          <div className="leaderboard_item_avatar">
            <img src={leaders[0] && leaders[0].avatar} />
          </div>
          <div className="leaderboard_item_info">
            <div className="leaderboard_item_name">
              <h5>{leaders[0] && leaders[0].name}</h5>
            </div>
            <div className="leaderboard_item_place">
              🥉{" "}
              <span>
                {(leaders[0] && leaders[0].place) || "3rd Place!"} &#8729; $
                {150}
              </span>
            </div>
            <div className="leaderboard_item_points">
              <h5>
                {36} <span className="pts">pts</span>
              </h5>
            </div>
          </div>
        </div>
        {leaders.map((v: any, i: Key | null | undefined) => {
          return (
            <div className="leaderboard_item" key={i}>
              <div className="leaderboard_item_avatar">
                <img src={v.avatar} />
              </div>
              <div className="leaderboard_item_info">
                <div className="leaderboard_item_name">
                  <h5>{v.name}</h5>
                </div>
                <div
                  className="leaderboard_item_place"
                  style={{ display: v.place ? "flex" : "none" }}
                >
                  🥇{" "}
                  <span>
                    {v.place || "1st Place!"} &#8729; ${v.score}
                  </span>
                </div>
                <div className="leaderboard_item_points">
                  <h5>
                    {v.total} <span className="pts">pts</span>
                  </h5>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Leaderboard;
