import { acceptRequest, declineRequest } from "../../expertSlice";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "app/features/tools/Avatar";
import "./CurrentOneToOneFeedbackRequest.scss";

const CurrentOneToOneFeedbackRequest = ({
  v,
  setOpen,
  setMedia,
  setUpload,
  setType,
}: any) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const handleAccept = async (e: any) => {
    //HOW SHOULD acceptRequest BEHAVE. SHOULD THE DB BE UPDATED AUTOMATICALLY OR JUST WAIT UNTIL EXPERT SUBMITS
    // WHAT HAPPENS IF EXPERT ACCEPTS BUT DON'T FINISH SUBMITTING
    const response = (await dispatch(
      acceptRequest({ token: accessToken, id: v.request.id })
    )) as any;
    if (response.payload.accepted) {
      setOpen(true);
      setMedia(v.request_media[0]);
      setType(v.type);
      setUpload({
        submission_id: v.request.id,
        type: "one_to_one feedback",
      });
    }
  };

  const handleDecline = async (e: any) => {
    const response = (await dispatch(
      declineRequest({ token: accessToken, id: v.request.id })
    )) as any;
  };

  return (
    <div className="current_request_item">
      <div className="overdue_header">
        <Avatar v={v} />
        <div className="overdue_info">
          <p>{v.user.name}</p>
        </div>
      </div>
      <p className="overdue_text">{v.request.description}</p>
      <div className="overdue_footer">
        <button onClick={handleAccept}>Accept</button>
        <button className="decline" onClick={handleDecline}>
          Decline
        </button>
      </div>
    </div>
  );
};

export default CurrentOneToOneFeedbackRequest;
