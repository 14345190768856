import { useState } from "react";
import "./Header.scss";

const Header = ({ children }: any) => {
  const [color, setColor] = useState(false);

  const changeHeaderColor = () => {
    window.scrollY >= 10 ? setColor(true) : setColor(false);
  };

  window.addEventListener("scroll", changeHeaderColor);

  return (
    <header style={{ background: color ? "white" : "" }}>{children}</header>
  );
};

export default Header;
