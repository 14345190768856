import React from "react";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import ExpertsLandingPage from "../experts_landing_page/ExpertsLandingPage";
import ExpertPending from "./ExpertPending";

const ExpertStatus = () => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );

  const val = !currentUser?.approved ? (
    <ExpertPending />
  ) : (
    <ExpertsLandingPage />
  );
  return val;
};

export default ExpertStatus;
