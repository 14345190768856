import { useState } from "react";
import { parseTimePast } from "../../expert_tools";
import Avatar from "app/features/tools/Avatar";
import gray_clock from "../../../../../assets/images/experts/gray_clock.png";
import "./OverdueChallenge.scss";

import { RootState } from "app/store";
import { useSelector } from "react-redux";

const OverdueChallenge = ({
  v,
  setOpen,
  setMedia,
  setUpload,
  setType,
}: any) => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const [info, setInfo] = useState(false);

  const toggleInfoModal = () => setInfo((prev) => !prev);

  ///////////////////////////THIS MUST ME REMOVED//////////////////////////////////
  function random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  ///////////////////////////THIS MUST ME REMOVED//////////////////////////////////

  const toggleOpen = () => {
    setOpen(true);
    setMedia(v.media);
    setType(v.type);
    setUpload({
      submission_id: v.submission.id,
      user_id: currentUser?.id,
      criteria: v.criteria.map((criterion: any) => {
        return {
          submission_id: v.submission.id,
          user_id: currentUser?.id,
          criterium_id: criterion.id,
          value: random(3, 5),
        };
      }),
      challenge_id: v.challenge.id,
      type: "challenge submission",
    });
    //CHALLENGE VIDEO
    //This is the challenge video (v.challenge_media)
  };

  return (
    <div className="overdue_challenges_item">
      <div
        className="overdue_info_modal"
        style={{ display: info ? "block" : "none" }}
      >
        This challenge will be reassigned to another judge.
      </div>
      <div className="overdue_header">
        <Avatar v={v} />
        <div className="overdue_info">
          <p>{v.challenge.name}</p>
          <div>
            <span className="calendar_icon"></span>
            <p>-{parseTimePast(v.challenge.end_date)} day overdue</p>
          </div>
        </div>
        <span onClick={toggleInfoModal}></span>
      </div>
      <div className="overdue_body">
        <div className="overdue_body_info">
          <img src={gray_clock} />
          <p>Time left to judge challenge</p>
        </div>
        <div className="overdue_challenges_time">00d 00h 00m</div>
      </div>
      <div className="overdue_footer">
        <button onClick={toggleOpen}>Judge challenge now</button>
      </div>
    </div>
  );
};

export default OverdueChallenge;
