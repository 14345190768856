import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getRequestFeedbacks } from "../../../expertSlice";
import OverdueOneToOneFeedbackRequest from "app/features/experts/experts_landing_page/overdue_one_to_one_feedback_request/OverdueOneToOneFeedbackRequest";

const OverdueRequestTab = ({ setOpen, setVideo, setUpload }: any) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const overdue_requests = useSelector(
    (state: RootState) => state.expert.overdue_requests
  );

  async function response() {
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
  }

  useEffect(() => {
    response();
  }, []);

  return (
    <div className="items">
      {overdue_requests.map((v: any, i: any) => {
        return (
          <OverdueOneToOneFeedbackRequest
            v={v}
            key={i}
            setOpen={setOpen}
            setVideo={setVideo}
            setUpload={setUpload}
          />
        );
      })}
    </div>
  );
};

export default OverdueRequestTab;
