import { Routes, Route } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import Nav from "./nav/Nav";
import NavCreator from "./nav/nav_creator/NavCreator";
import NavExpert from "./nav/nav_expert/NavExpert";
import NavAdmin from "./nav/nav_admin/NavAdmin";

const HeaderRoutes = () => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );

  return (
    <Routes>
      <Route path="/creator/*" element={<NavCreator />} />
      <Route path="/expert/*" element={<NavExpert />} />
      <Route path="/admin/*" element={<NavAdmin />} />
      <Route path="*" element={<Nav />} />
    </Routes>
  );
};

export default HeaderRoutes;
