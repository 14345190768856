import { useState, useEffect } from "react";
import { parseTime } from "./expert_tools";

const ExpertTimeInterval = (time: any) => {
  const { days, hours, mins } = parseTime(time);
  const [d, setD] = useState(days);
  const [h, setH] = useState(hours);
  const [m, setM] = useState(mins);

  useEffect(() => {
    const interval = setInterval(() => {
      const { days, hours, mins } = parseTime(time);
      setD(days);
      setH(hours);
      setM(mins);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      {d}d {h}h {m}m
    </div>
  );
};

export default ExpertTimeInterval;
