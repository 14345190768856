import Avatar from "app/features/tools/Avatar";
import "./PreviousFeedback.scss";

const PreviousFeedback = ({ v }: any) => {
  return (
    <div className="previous_feedback_item">
      <div className="previous_feedback_header">
        <Avatar v={v} />
        <div className="previous_feedback_info">
          <p>{v.user.name}</p>
        </div>
      </div>
      <p className="previous_feedback_text">{v.feedback.content}</p>
    </div>
  );
};

export default PreviousFeedback;
