import { useState, useEffect } from "react";
import "./ExpertSubmissionViewer.scss";

const ExpertSubmissionViewer = ({
  setOpen,
  open,
  media,
  type,
  setShow,
  description,
}: any) => {
  const [paused, setPaused] = useState(true);
  const toggleOpen = (e: any) => {
    // document.body.style.overflow = "scroll";
    setOpen(false);
  };

  const handleVideoControls = (e: any) => {
    const video = e.target.previousSibling;
    video.paused ? video.play() : video.pause();
  };

  const openExpertMedia = (e: any) => {
    document.body.style.overflow = "hidden";
    setShow(true);
    setOpen(false);
  };

  return (
    <div
      className="expert_submission_viewer_container"
      style={{ display: open ? "flex" : "none" }}
    >
      <div className="submission_viewer_modal">
        <div className="submission_viewer_header">
          <h2>Expert submission</h2>
          <button onClick={toggleOpen}>x</button>
        </div>
        <div className="submission_viewer_body">
          {type?.includes("video") ? (
            <Video
              media={media}
              setPaused={setPaused}
              paused={paused}
              handleVideoControls={handleVideoControls}
            />
          ) : (
            <Image media={media} />
          )}
        </div>
        <div className="submission_view_desc">{description}</div>
        <div className="submission_viewer_footer">
          <button onClick={openExpertMedia}>Give feedback</button>
        </div>
      </div>
    </div>
  );
};

export default ExpertSubmissionViewer;

function Video({ media, setPaused, paused, handleVideoControls }: any) {
  return (
    <>
      <video
        src={media}
        onPlaying={(e: any) => {
          setPaused(false);
        }}
        onPause={(e: any) => setPaused(true)}
      ></video>
      <div
        className="creator_item_cover"
        onClick={handleVideoControls}
        style={{ opacity: paused ? ".9" : "0", zIndex: 1 }}
      >
        {/* <div
            className="overdue_info_modal"
            style={{ display: info ? "block" : "none" }}
          >
            Since the expert did not accept your request,{" "}
            <u>select another judge</u> or <u>get a refund.</u>
          </div>
          <span onClick={toggleInfoModal}></span> */}
      </div>
    </>
  );
}

function Image({ media }: any) {
  return <img src={media} />;
}
