import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "app/features/tools/Loading";
import "./Dashboard.scss";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="admin_dashboard">
      <div className="content_header">
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchIcon />
          </div>
          <div className="items">
            <div className="item"></div>
          </div>
        </div>
      </div>
      <section className="content_body">
        <div className="content_body_header">
          <h2>Dashboard</h2>
        </div>
        <div style={{ height: "100%", display: "grid", placeItems: "center" }}>
          {loading ? <Loading /> : <span>Dashboard coming soon</span>}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
