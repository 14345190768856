import React from "react";

const SubmissionRequestFeedback = () => {
  return (
    <div>
      SubmissionRequestFeedback
      <div>
        <button>Buy</button>
      </div>
    </div>
  );
};

export default SubmissionRequestFeedback;
