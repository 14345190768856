import { useNavigate, useLocation } from "react-router-dom";
import "./user.scss";
import { Button, FormControl, FormGroup } from "@mui/material";
import { useState } from "react";
import { RootState } from "../../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { updateUserRecord } from "../usersSlice";
import Submissions from "./submissions/Submissions";

export default function User() {
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [updated, setUpdated] = useState<boolean>(false);
  const user = location.state as any; //MIGHT WANT TO GET THE USER FROM THE USERS IN THE STORE

  interface Inputs {
    id: number | undefined;
    name: string;
    email: string;
    // username: string;
    role: string;
    country: string;
    charity: string;
    approved: boolean;
    price: string;
  }

  const initialState: Inputs = {
    id: user.id,
    name: user.name,
    email: user.email,
    // username: user.username,
    role: user.role,
    country: user.country,
    charity: user.charity,
    approved: user.approved,
    price: user.price,
  };

  const [inputs, setInputs] = useState<Inputs>(initialState);

  const dispatch = useDispatch();

  function back(event: any) {
    navigate(-1);
  }

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    const response = (await dispatch(
      updateUserRecord({ token: accessToken, user: inputs })
    )) as any;

    //GET THE STATUS FROM THE THUNK
    //HANDLE ERRORS AND LOADING?
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
  }

  return (
    <div>
      <div className="user_header">
        <button onClick={back}>Back</button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="user_info">
          <div className="user_info_name">
            <span>id:</span>
          </div>
          <div className="user_info_vlue">
            <input defaultValue={user.id} readOnly />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Name:</span>
          </div>
          <div className="user_info_vlue">
            <input value={inputs.name} name="name" onChange={handleChange} />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Price:</span>
          </div>
          <div className="user_info_vlue">
            <input value={inputs.price} name="price" onChange={handleChange} />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Email:</span>
          </div>
          <div className="user_info_vlue">
            <input value={inputs.email} name="email" onChange={handleChange} />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Role:</span>
          </div>
          <div className="user_info_vlue">
            <input value={inputs.role} name="role" onChange={handleChange} />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Country:</span>
          </div>
          <div className="user_info_vlue">
            <input
              value={inputs.country}
              name="country"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Charity:</span>
          </div>
          <div className="user_info_vlue">
            <input
              value={inputs.charity}
              name="charity"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Create At:</span>
          </div>
          <div className="user_info_vlue">
            <input defaultValue={user.created_at} readOnly />
          </div>
        </div>
        <div className="user_info">
          <div className="user_info_name">
            <span>Updated At:</span>
          </div>
          <div className="user_info_vlue">
            <input defaultValue={user.updated_at} readOnly />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormGroup
            row={true}
            id="submit-group"
            sx={{ marginTop: "1em", marginRight: "1em", width: "20%" }}
          >
            <FormControl fullWidth>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                id="submit-button"
                sx={{
                  background: "#008cba",
                  ":hover": {
                    color: "black",
                    bgcolor: "white",
                  },
                }}
              >
                Add
              </Button>
            </FormControl>
          </FormGroup>
        </div>
      </form>
      <div
        style={{
          display: updated ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          width: 300,
          height: 50,
          border: "thin solid #888",
          background: "#139854",
          borderRadius: 5,
          margin: "0 auto",
          color: "white",
        }}
      >
        <p>Record Updated</p>
      </div>
      <Submissions id={user.id} />
    </div>
  );
}
