import React from "react";
import Avatar from "app/features/tools/Avatar";
import "./PreviousChallenge.scss";

const PreviousChallenge = ({ v }: any) => {
  return (
    <div className="previous_challenge_item">
      <div className="previous_challenge_header">
        <Avatar v={v} />
        <div className="previous_challenge_info">
          <p>{v.user.name}</p>
        </div>
      </div>
    </div>
  );
};

export default PreviousChallenge;
