import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import Home from "../home/Home";
import Loading from "../tools/Loading";

function PrivateRoute({ children }: any) {
  const loading = useSelector((state: RootState) => state.session.loading);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const location = useLocation();
  const fromLocation = (location.state as any)?.from;
  const path = location.pathname === "/admin" ? "/login" : "/"; //fix this
  const previousLocation = fromLocation ? fromLocation : { pathname: path };

  if (accessToken) {
    return <>{children}</>;
  } else if (loading) {
    return <Loading />;
  } else if (!accessToken && !loading) {
    return path !== "/login" ? (
      <Home />
    ) : (
      <Navigate to={previousLocation} state={{ from: location }} replace />
    );
  } else {
    return <p>Something went wrong</p>;
  }
}

export default PrivateRoute;
