import { useState } from "react";
import { Link } from "react-router-dom";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { RiTwitterXLine } from "react-icons/ri";
import { submitNewletterSubscription } from "../sessions/sessionSlice";
import logo from "../../../assets/images/logo/light.png";
import fb from "../../../assets/images/icons/fb.png";
import tweeter from "../../../assets/images/icons/tweeter.png";
import insta from "../../../assets/images/icons/insta.png";
import "./Footer.scss";

const Footer = () => {
  interface Inputs {
    email: string;
  }

  const initialState: Inputs = {
    email: "",
  };

  const [inputs, setInputs] = useState<Inputs>(initialState);
  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );

  const dispatch = useDispatch();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    //TODO
    //send to backend
    const response = (await dispatch(
      submitNewletterSubscription(inputs)
    )) as any;

    if (errorMessages.length === 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // navigate();
    } else {
      // return setErrors(errorMessages);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
  };

  return (
    <footer className="footer">
      <div className="footer_wrapper">
        <FooterInfo />
        <FooterSubscription
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          value={inputs.email}
        />
        <FooterLinks />
      </div>
    </footer>
  );
};

export default Footer;

function FooterInfo() {
  return (
    <div className="footer_app_info_section">
      <div className="footer_info_logo">
        <img src={logo} />
      </div>
      <div className="footer_info_text">
        <p>
          Join Studious challenges to practice with your peers and get
          personalized feedback from the people and organizations that matter.
        </p>
      </div>
      <div className="footer_info_social_media">
        <a
          href="https://www.facebook.com/StudiousSocial"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fb} />
        </a>
        <a
          href="https://twitter.com/StudiousNet"
          target="_blank"
          rel="noreferrer"
        >
          {/* <img src={tweeter} /> */}
          <RiTwitterXLine
            style={{
              color: "white",
              height: "20px",
              width: "20px",
              marginTop: "3px",
            }}
          />
        </a>
        <a
          href="https://www.instagram.com/studious_net"
          target="_blank"
          rel="noreferrer"
        >
          <img src={insta} />
        </a>
      </div>
    </div>
  );
}

function FooterSubscription({ handleSubmit, handleChange, value }: any) {
  return (
    <div className="footer_subscribe_section">
      <div className="footer_subscribe_header">Subscribe to our newsletter</div>
      <div className="footer_subscribe_body">
        <div className="footer_newsletter_sign_up">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Your email address"
              value={value}
              onChange={handleChange}
            />
            <button>Subscribe</button>
          </form>
        </div>
      </div>
      <div className="footer_subscribe_footer">
        &copy; {new Date().getFullYear()}, Studious
      </div>
    </div>
  );
}

function FooterLinks() {
  return (
    <div className="footer_links_section">
      <ul className="footer_links">
        <li className="footer_link">
          <Link to="/">
            <button>Home</button>
          </Link>
        </li>
        <li className="footer_link">
          <Link to="/expert_signup_start">
            <button>Apply to judge</button>
          </Link>
        </li>
        {/* <li className="footer_link">
          <Link to="/challenges">
            <button>Challenges</button>
          </Link>
        </li> */}
        <li className="footer_link">
          <a
            href="https://aboutstudious.notion.site/aboutstudious/Studious-1d91b653b4484a8ab5176709d7fae811"
            target="_blank"
            rel="noreferrer"
          >
            <button>About</button>
          </a>
        </li>
        <li className="footer_link">
          <a
            href="https://studiousnet.squarespace.com/"
            target="_blank"
            rel="noreferrer"
          >
            <button>Blog</button>
          </a>
        </li>
        <li className="footer_link">
          <Link to="/signin">
            <button>Sign in</button>
          </Link>
        </li>
      </ul>
    </div>
  );
}
