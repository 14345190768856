import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../../../store";
import { resetErrorState, signUpUser } from "../../sessionSlice";
// import StarHalfIcon from "@mui/icons-material/StarHalf";
import Star from "app/features/tools/star/Star";
import { FaChevronLeft } from "react-icons/fa";
import { validate, validateErrors } from "../../../../tools";
import "./ExpertsSignupFinal.scss";
import ExpertsSignupSuccess from "./ExpertsSignupSuccess";

import axios from "app/api/axios";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

function ExpertSignupFinal() {
  const location = useLocation();
  const { state } = location as any;
  const [success, setSuccess] = useState(false);

  interface Inputs {
    name: string;
    email: string;
    password: string;
    username: string;
    official_title: string;
    specialization: string;
    personal_website: string;
    company_website: string;
    linkedin_url: string;
  }

  const initialState: Inputs = {
    name: state.name,
    email: state.email,
    password: state.password,
    username: "username",
    official_title: "",
    specialization: "",
    personal_website: "",
    company_website: "",
    linkedin_url: "",
  };

  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );
  const [errors, setErrors] = useState<Array<string>>([]);
  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [valid, setValid] = useState({
    official_title: "*",
    specialization: "*",
    personal_website: "*",
    company_website: "*",
    linkedin_url: "*",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate("/expert_signup_start", { state: state });
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    axios.post('/experts.json', { user: inputs, client_id: CLIENT_ID })
      .then(res =>
        setSuccess(true)
      )
      .catch(err =>
        setErrors(err.response.data.error)
      )
  }

  return (
    <section className="experts_signup_final">
      <div className="header">
        <div style={{ display: errors.length > 0 ? "block" : "none" }}>
          Errors: {errors.length}
        </div>
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
        <Star />
        <div className="title">
          <h3>Experts sign up</h3>
          <div>
            <span>2 of 2</span>
          </div>
        </div>
        <div>
          <p>
            A few simple steps and you&apos;ll get access to the Studious
            platform
          </p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="official_title">
                Your official title <sup>*</sup>
              </label>
              <input
                type="text"
                name="official_title"
                placeholder="official title"
                value={inputs.official_title}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="specialization">
                Specialization <sup>*</sup>
              </label>
              <input
                type="text"
                name="specialization"
                placeholder="specialization"
                value={inputs.specialization}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="personal_website">
                Personal website <sup>*</sup>
              </label>
              <input
                type="url"
                name="personal_website"
                placeholder="URL"
                value={inputs.personal_website}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="company_website">Company website</label>
              <input
                type="url"
                name="company_website"
                placeholder="URL"
                value={inputs.company_website}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="linkedin_url">LinkedIn</label>
              <input
                type="url"
                name="linkedin_url"
                placeholder="URL"
                value={inputs.linkedin_url}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type="submit" disabled={!validate(valid) ? true : false}>
            Sign Up
          </button>
          <p className="sign_in_option">
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </form>
      </div>
      <ExpertsSignupSuccess success={success} setSuccess={setSuccess} />
    </section>
  );
}

export default ExpertSignupFinal;
