import { Key, useEffect, useState } from "react";
import { RootState } from "../../../../../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubmissions,
  destroySubmission,
} from "app/features/submissions/submissionSlice";
import DeleteIcon from "@mui/icons-material/Delete";

const Submissions = ({ id }: any) => {
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [submissionId, setSubmissionId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  const handleDelete = async (e: any) => {
    setShow(true);
    setSubmissionId(e.target.id);
  };

  const destroy = async () => {
    setShow(false);
    const submission = { token: accessToken, submission: Number(submissionId) };
    const res = (await dispatch(destroySubmission(submission))) as any;
    if (res.payload.msg) {
      const newSub = submissions.filter((v) => {
        if (v.submission.id !== parseInt(submissionId)) return v;
      });
      setSubmissions(newSub);
    } else {
      console.log("what to do with the errors");
    }
  };

  const closeModal = () => {
    setShow(false);
  };
  async function response() {
    const submission = { token: accessToken, submission: id };
    const res = (await dispatch(getSubmissions(submission))) as any;
    setSubmissions(res.payload.submissions);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div
        className="submissions_modal_container"
        style={{ display: show ? "flex" : "none" }}
      >
        <div className="submissions_modal">
          <button className="close" onClick={closeModal}>
            ✖
          </button>
          <p>Are you sure you want to delete this submission</p>
          <button className="delete" onClick={destroy}>
            Delete
          </button>
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
      <h3>Submissions</h3>
      <div className="user_submissions_container">
        {submissions?.map((v) => {
          return (
            <div key={v.submission.id} style={{ border: "thin solid #888" }}>
              <div
                className="user_submissions_header"
                style={{
                  height: 40,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button style={{ position: "relative" }}>
                  <DeleteIcon />
                  <div
                    style={{
                      background: "transparent",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                    onClick={handleDelete}
                    id={v.submission.id}
                  ></div>
                </button>
              </div>
              <div>{v.submission.challenge_id}</div>
              <div>Challenge: {v.challenge.details}</div>
              <div style={{ display: "flex" }}>
                <div>
                  <video
                    src={v.media}
                    style={{ width: 150, height: 100 }}
                    controls
                  ></video>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Submissions;
