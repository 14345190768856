import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";
import "./ExpertSubmissionSuccess.scss";

const ExpertSubmissionSuccess = ({ success, setSuccess }: any) => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const closeModal = (e: any) => {
    document.body.style.overflow = "scroll";
    setSuccess(false);
  };

  // useEffect(() => {
  //   document.body.style.overflow = "hidden";
  // }, []);

  return (
    <div
      className="expert_submission_success"
      style={{ display: success ? "flex" : "none" }}
    >
      <div className="success_modal">
        <div className="success_modal_header"></div>
        <div className="success_modal_body">
          <div className="img"></div>
          <div className="text">
            The video and text response you uploaded were successfully sent to
            the creator!
          </div>
          <Link to={`/expert/${slug}`}>
            <button onClick={closeModal}>
              <BsFillHouseFill />
              back to home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExpertSubmissionSuccess;
