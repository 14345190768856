import { useNavigate } from "react-router-dom";
import "./PaymentProcessed.scss";

const PaymentProcessed = ({ processed }: any) => {
  const navigate = useNavigate();
  const closePaymentProcessed = () => {
    navigate("/experts");
  };
  return (
    <div
      className="payment_processed"
      style={{ display: processed ? "grid" : "none" }}
    >
      <div className="payment_processed_modal">
        <div className="payment_processed_header">
          <button onClick={closePaymentProcessed}>x</button>
        </div>
        <div className="payment_processed_image"></div>
        <p className="payment_processed_text">
          We are currently <span>processing your payment</span> and will notify
          you when the Judge makes a decisions.
        </p>
      </div>
    </div>
  );
};

export default PaymentProcessed;
