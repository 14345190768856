import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getExpertsPublic } from "app/features/experts/expertSlice";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Loading from "app/features/tools/Loading";
import { create_slug } from "app/tools";
import avatar from "assets/avatar.png";
import "./ExpertsPublic.scss";

const Experts = () => {
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const experts = useSelector((state: RootState) => state.expert.experts);
  const loading = useSelector((state: RootState) => state.users.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const handleNavigation = () => {
    navigate(-1);
  };

  const handleRequestFeedback = (e: any) => {
    navigate(`/signup`, {
      state: { new_user: true },
    });
  };

  async function response() {
    dispatch(getExpertsPublic(accessToken));
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="experts">
      {/* EXPERTS HEADER */}
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Experts</h3>
          <p>See a list of all the experts...</p>
        </div>
      </div>
      {/* EXPERTS SPECIALIZATION SORTING */}
      <div className="experts_specialization_sorting">
        <form>
          <label>
            <h5>Specialization</h5>
            <select>
              <option value="dancer">Dancer</option>
              <option value="musician">Musician</option>
              <option defaultValue="actor">Actor</option>
              <option value="writer">Writer</option>
            </select>
          </label>
          <label>
            <h5>Sort by</h5>
            <select>
              <option value="price">Price</option>
              <option value="age">Age</option>
              <option defaultValue="name">Name</option>
              <option value="country">Country</option>
            </select>
          </label>
        </form>
      </div>
      <div className="experts_list">
        {loading ? (
          <Loading />
        ) : (
          <>
            {experts?.map((v: any) => {
              return (
                <div key={v.id} className="card">
                  <div className="avatar_container">
                    <img src={!v.avatar ? avatar : `${URL}${v.avatar}`} />
                  </div>
                  <div className="info_container">
                    <h5>{v.name}</h5>
                    <p>{v.specialization}</p>
                    <p style={{ display: v.price ? "block" : "none" }}>
                      ${v.price}
                    </p>
                    <div
                      className="request_feedback"
                    >
                      <button
                        onClick={handleRequestFeedback}
                        data-expert={JSON.stringify(v)}
                      >
                        { v.price ? 'Request Feedback' : 'View Expert' } <FaChevronRight />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default Experts;
