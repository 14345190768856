import { Link } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";

import "./Uploaded.scss";
const Uploaded = ({ children }: any) => {
  return (
    <div className="upload_upload_later_modal_container">
      <div className="modal">
        <div className="img"></div>
        <div className="text">{children}</div>
        <Link to={`/creator`}>
          <button>
            <BsFillHouseFill />
            back to home
          </button>
        </Link>
      </div>
      {/* <h3>Success</h3>
      <div style={{ height: 300 }}>
        Files you uploaded were succesfully added to the challenge! Good Luck!
      </div>
      <Link to="/">
        <button
          style={{
            width: 200,
            height: 60,
            borderRadius: 5,
            background: "yellow",
          }}
        >
          Good
        </button>
      </Link> */}
    </div>
  );
};

export default Uploaded;
