import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaChevronLeft,
} from "react-icons/fa";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";
import avatar from "../../../../assets/avatar.png";
import { URL } from "../../../../index";
import "./ExpertsRequestFeedback.scss";

const ExpertsRequestFeedback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as any;
  const { expert } = state;
  const text = expert.bio;

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const handleNavigation = () => {
    navigate(-1);
  };
  console.log("ALTE", expert);
  return (
    <div className="expert_request_feedback">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
      </div>
      <div className="body">
        <div className="info_container">
          <div className="avatar_container">
            <img src={expert.avatar ? `${URL}${expert.avatar}` : avatar} />
          </div>
          <div className="data_container">
            <h1>{expert.name}</h1>
            <p>{expert.official_title}</p>
            <div className="social_container">
              {!expert.instagram_url ? (
                ""
              ) : (
                <a href={expert.instagram_url}>
                  <FaInstagram />
                </a>
              )}
              {!expert.twitter_url ? (
                ""
              ) : (
                <a href={expert.twitter_url}>
                  <FaTwitter />
                </a>
              )}
              {!expert.youtube_url ? (
                ""
              ) : (
                <a href={expert.youtube_url}>
                  <FaYoutube />
                </a>
              )}
            </div>
            <div className="price_container">{`$${expert.price}.00`}</div>
            <div className="action_btn">
              <Link
                to={`/creator/expert_request_feedback_form`}
                state={{
                  amount: expert.price,
                  id: expert.id,
                }}
              >
                <button>Request Feedback</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="about_me">
          <h2>About me</h2>
          <p>{expert.bio ? expert.bio : text}</p>
        </div>
        <hr />
        <div className="expertise">
          <h2>Expertise</h2>
          <div className="expertise_tags">
            <button>{expert.specialization}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertsRequestFeedback;
