import { useState, useRef, useEffect } from "react";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { updateUserRecord } from "app/features/admin/content/users/usersSlice";
import InputField from "./InputField";
import avatar_default from "assets/avatar.png";
import "./ProfileForm.scss";

/**
 *
 * TODO
 * 1. Change from 100 char to 100 words
 */

export default function ProfileForm({ toggleShow, show }: any) {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );

  const initialState = {
    name: "",
    official_title: "",
    personal_website: "",
    company_website: "",
    linkedin_url: "",
    instagram_url: "",
    twitter_url: "",
    youtube_url: "",
    bio: "",
    price: ""
  };

  const [inputs, setInputs] = useState<any>(initialState);
  const [avatar, setAvatar] = useState<string | undefined>(avatar_default);
  const [word, setWord] = useState(0);
  const refreshToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const wordLimit = 100;

  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement>(null);

  async function handleSubmit(e: any) {
    e.preventDefault();
    const formData = new FormData();

    for (const prop in inputs) {
      formData.append(`user[${prop}]`, (inputs as any)[prop]);
    }

    const response = (await dispatch(
      updateUserRecord({ token: refreshToken, user: formData })
    )) as any;

    console.log("THE RETURN", response);
    if (response.error) {
      console.log("ERROR", response.error);
    } else {
      toggleShow();
    }
  }

  function handleUpload(e: any) {
    const files = e.target.files;
    if (files.length === 0) return;
    if (files[0].type.split("/")[0] !== "image") return;

    const fileReader = new FileReader();
    fileReader.onload = (media: any) => {
      setAvatar(media.target.result);
    };
    fileReader.readAsDataURL(files[0]);
    setInputs((prevState: any) => ({ ...prevState, avatar: files[0] }));
  }

  function handleChange(e: any) {
    const { name, value } = e.target;
    const word_list = value.split(" ").filter((v: any, i: any) => v != false);
    if (word_list.length > wordLimit) return;
    word_list.length === wordLimit
      ? setInputs((prevState: any) => ({ ...prevState, [name]: value.trim() }))
      : setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    setWord(word_list.length);
  }

  function handleFile(e: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fileRef.current.value = "";
    fileRef?.current?.click();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    setAvatar(!currentUser?.avatar ? avatar : currentUser?.avatar);
    const clone = (({ avatar, created_at, ...o }: any) => o)(currentUser);
    setInputs(clone);
  }, [currentUser]);

  return (
    <div className="upload__modal" style={{ display: show ? "flex" : "none" }}>
      <div className="upload_form_header">
        <h1>Manage expert profile</h1>
        <h4>Manage your profile</h4>
        <button onClick={toggleShow}></button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="upload_form_avatar_container">
          <div className="avatar_container">
            <img
              src={!currentUser?.avatar ? avatar : currentUser?.avatar}
              alt="user's avatar image"
              className="upload_form_avatar_img"
            />
            <div></div>
          </div>
          <div className="profile_text">
            <span onClick={handleFile}>Upload new image</span>
          </div>
        </div>
        <InputField
          value={inputs?.name}
          name="name"
          title="Full name"
          type="text"
          ph={"enter name"}
          handleChange={handleChange}
          // req={true}
        />
        <InputField
          value={inputs?.official_title}
          name="official_title"
          title="Your official title"
          type="text"
          ph={"enter official title"}
          handleChange={handleChange}
          // req={true}
        />
        <InputField
          value={inputs?.price}
          name="price"
          title="Price"
          type="text"
          handleChange={handleChange}
        />
        <InputField
          value={inputs?.personal_website}
          name="personal_website"
          title="Personal website"
          type="url"
          ph={"enter personal website"}
          handleChange={handleChange}
          // req={true}
        />
        <InputField
          value={inputs?.company_website}
          name="company_website"
          title="Company website"
          type="url"
          ph={"enter company website"}
          handleChange={handleChange}
        />
        <InputField
          value={inputs?.linkedin_url}
          name="linkedin_url"
          title="Linked In"
          type="url"
          ph={"enter linked in url"}
          handleChange={handleChange}
        />
        <InputField
          value={inputs?.instagram_url || ""}
          name="instagram_url"
          title="Instagram"
          type="url"
          ph={"enter instagram url"}
          handleChange={handleChange}
        />
        <InputField
          value={inputs?.twitter_url || ""}
          name="twitter_url"
          title="Twitter"
          type="url"
          ph={"enter twitter url"}
          handleChange={handleChange}
        />
        <InputField
          value={inputs?.youtube_url || ""}
          name="youtube_url"
          title="YouTube"
          type="url"
          ph={"enter youtube url"}
          handleChange={handleChange}
        />
        <div style={{ position: "absolute", left: -10000 }}>
          <input
            type="file"
            name="avatar"
            className="form-control"
            onChange={handleUpload}
            ref={fileRef}
          />
        </div>
        <div className="input_field">
          <div className="input_field_name">
            <span>About You:</span>
          </div>
          <div className="input_field_value">
            <textarea
              value={inputs?.bio}
              name="bio"
              onChange={handleChange}
              placeholder="update bio info."
            ></textarea>
            <span>{word}/100</span>
          </div>
        </div>
        <div className="profile_submit_btn">
          <button>Save</button>
        </div>
      </form>
    </div>
  );
}
