import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { RiTrophyLine } from "react-icons/ri";
import { BsFillPlayFill } from "react-icons/bs";
// import { RiMedalLine } from "react-icons/ri";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getChallenge } from "../../challengeSlice";
import { Link } from "react-router-dom";
import { create_slug } from "app/tools";
import "./ChallengeInformation.scss";

const ChallengeInformation = ({ id }: any) => {
  const [challenge, setChallenge] = useState<any>({});
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [time, setTime] = useState("");
  const [started, setStarted] = useState(false);
  const [timeBeforeStart, setTimeBeforeStart] = useState("");
  const [play, setPlay] = useState(false);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);
  const dispatch = useDispatch();

  const handleVideo = (e: any) => {
    if (videoRef.current?.paused) {
      videoRef.current.play();
      setPlay(true);
    } else {
      videoRef.current?.pause();
      setPlay(false);
    }
  };

  const handleEnd = (e: any) => {
    e.target.currentTime = 0;
    setPlay(false);
  };

  function dhm(t: any) {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;

    let d = Math.floor(t / cd);
    let h = Math.floor((t - d * cd) / ch);
    let m = Math.round((t - d * cd - h * ch) / 60000);
    const pad = function (n: any) {
      return n < 10 ? "0" + n : n;
    };
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    return [`${d}d`, `${pad(h)}h`, `${pad(m)}m`].join(" ");
  }

  const giveTime = () => {
    if (!endDate) return;
    const diffTime = Math.abs(endDate.getTime() - new Date().getTime());
    setTime(dhm(diffTime));
    if (!startDate) return;
    const start = startDate.getTime();
    const now = new Date().getTime();
    const started = start < now;
    if (!started) {
      setStarted(false);
      const timeUntil = Math.abs(startDate.getTime() - new Date().getTime());
      setTimeBeforeStart(dhm(timeUntil));
    } else {
      setStarted(true);
    }
  };

  async function response() {
    const challenge = { token: accessToken, id: id };
    const res = (await dispatch(getChallenge(challenge))) as any;
    //UPDATE THIS TO USE THE STORE
    // console.log("UPDATE THIS TO USE THE STORE", res.payload.challenge.thumb);
    // console.log("UPDATE THIS TO USE THE STORE", res.payload.challenge.media);
    console.log(
      "UPDATE THIS TO USE THE STORE",
      res.payload.challenge.challenge.end_date
    );
    setChallenge(res.payload.challenge);
    setStartDate(new Date(res.payload.challenge.challenge.start_date));
    setEndDate(new Date(res.payload.challenge.challenge.end_date));
  }

  useEffect(function () {
    response();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      giveTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endDate]);

  return (
    <div className="challenge_information">
      <div className="challenge_information_modal">
        <div className="challenge_information_details">
          <div className="challenge_information_details_header">
            <h2>{challenge?.challenge?.name}</h2>
          </div>
          <div className="challenge_information_details_time_and_pot">
            <div className="challenge_information_details_time">
              <div
                className="challenge_information_details_time_header"
                style={{ display: started ? "block" : "none" }}
              >
                <AiOutlineClockCircle /> <span>Time Left</span>
              </div>
              <h2>{time}</h2>
            </div>

            <div
              className="challenge_information_details_time"
              style={{ display: !started ? "block" : "none" }}
            >
              <div className="challenge_information_details_time_header">
                <AiOutlineClockCircle /> <span>Time Before Start</span>
              </div>
              <h2>{timeBeforeStart}</h2>
            </div>

            <div className="challenge_information_details_pot">
              <div className="challenge_information_details_pot_header">
                <RiTrophyLine /> <span>Pot size</span>
              </div>
              <h2>$ 3 210</h2>
            </div>
          </div>
          <div className="challenge_information_details_summary">
            {challenge?.challenge?.details}
          </div>
          <Link
            to={`/creator/${slug}/challenges/${id}/payment_method`}
            state={{
              challenge_id: id,
              challenge_name: challenge?.challenge?.name,
            }}
          >
            <div className="challenge_information_details_accept">
              Accept Challenge
            </div>
          </Link>
        </div>
        <div className="challenge_information_video">
          <div
            className="challenge_information_video_wrapper"
            onClick={handleVideo}
          >
            <video
              src={challenge?.media}
              ref={videoRef}
              onEnded={handleEnd}
            ></video>
            <div
              className="challenge_information_video_play_btn"
              style={{ display: !play ? "grid" : "none" }}
            >
              <BsFillPlayFill />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeInformation;
