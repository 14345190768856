import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import "./Challenges.scss";
import { Link } from "react-router-dom";
import { getChallenges } from "./challengeSlice";
import Loading from "../tools/Loading";
import { FaChevronLeft } from "react-icons/fa";
import { AiOutlineTrophy } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { create_slug } from "app/tools";

function Challenges() {
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const data = useSelector((state: RootState) => state.challenge.challenges);
  const loading = useSelector((state: RootState) => state.challenge.loading);
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(-1);
  };

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const dispatch = useDispatch();

  useEffect(function () {
    async function response() {
      await dispatch(getChallenges(accessToken));
    }
    response();
  }, []);

  return (
    <section className="challenges">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Challenges</h3>
          <p>Accept challenge and win a jackpot :)</p>
        </div>
      </div>
      <div className="challenges_specialization_sorting">
        <form>
          <label>
            <h5>Specialization</h5>
            <select>
              <option value="dancer">Dancer</option>
              <option value="musician">Musician</option>
              <option defaultValue="actor">Actor</option>
              <option value="writer">Writer</option>
            </select>
          </label>
          <label>
            <h5>Sort by</h5>
            <select>
              <option value="price">Newest</option>
              <option value="age">Age</option>
              <option defaultValue="name">Name</option>
              <option value="country">Country</option>
            </select>
          </label>
        </form>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="challenges_list">
          {data?.map((v: any) => {
            return (
              <Link
                to={`/creator/${slug}/challenges/${v.challenge.id}`}
                key={v.challenge.id}
              >
                <div className="card">
                  <div className="card_img">
                    <video
                      src={v.media}
                      style={{ width: "100%", height: "100%" }}
                    ></video>
                  </div>
                  <p>{v.challenge.details}</p>
                  {/* <div>{v.challenge.name}</div> */}
                  <div className="prize">
                    <AiOutlineTrophy />
                    {"$10,000"}
                  </div>
                  <div className="time">
                    <HiOutlineClock />
                    {"2d 14h 32m left"}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </section>
  );
}

export default Challenges;
