import { useState, useRef, useEffect } from "react";
import { RootState } from "../../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getApprovedJudges } from "../../../../sessions/sessionSlice";
import {
  submitChallenge,
  Challenge,
} from "../../../../challenges/challengeSlice";
import { MdImageSearch } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Container, TextField, IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormControl, FormGroup } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import Loading from "app/features/tools/Loading";
import "./ChallengeForm.scss";
import "./Duration.scss";
import avatar from "../../../../../../assets/avatar.png";
import { create_slug } from "app/tools";

const ChallengeForm = () => {
  const initialState: Challenge = {
    name: "",
    details: "",
    participation: "",
    instructions: "",
    start_date: null,
    end_date: null,
  };

  const [inputs, setInputs] = useState<Challenge>(initialState);
  const [media, setMedia] = useState("");
  const [instruction, setInstruction] = useState("");
  const [paused, setPaused] = useState(true);
  const fileRef = useRef<HTMLInputElement>(null);
  const fileRef2 = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [days, setDays] = useState<any>(0);
  const [hours, setHours] = useState<any>(0);
  const [minutes, setMinutes] = useState<any>(0);
  const [endDate, setEndDate] = useState<any>(null);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [valid, setValid] = useState(true);

  const [judgesList, setJudgesList] = useState([]);
  const [assigned, setAssigned] = useState([]);

  const [criteria, setCriteria] = useState<
    Array<{ name: string; description: string }>
  >([]);

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const navigate = useNavigate();

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  function handleFile() {
    fileRef?.current?.click();
  }

  function handleFileInstruction() {
    fileRef2?.current?.click();
  }

  function handleEnded() {
    setPaused(true);
  }

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
  }

  // function handleUpload(e: React.FormEvent<HTMLInputElement>) {
  //   const files = e.currentTarget.files;
  function handleUpload(e: any) {
    const files = e.target.files;
    if (files.length === 0) return;

    const fileReader = new FileReader();
    fileReader.onload = (media: any) => {
      setMedia(media.target.result);
    };
    fileReader.readAsDataURL(files[0]);
    setInputs((prevState) => ({ ...prevState, media: files[0] }));
  }

  function handleInstructionUpload(e: any) {
    const files = e.target.files;
    if (files.length === 0) return;

    const fileReader = new FileReader();
    fileReader.onload = (media: any) => {
      setInstruction(media.target.result);
    };
    fileReader.readAsDataURL(files[0]);
    setInputs((prevState) => ({ ...prevState, instruction: files[0] }));
  }

  const formatDate = (date: any, hour: any, minute: any) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return new Date(
      year,
      month,
      day,
      parseInt(hour),
      parseInt(minute)
    ).getTime();
  };

  const isValid = () => {
    const val = formatDate(selectedDate, startHour, startMinute);
    if (!val) return;
    setValid(val > new Date().getTime());
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setStartDate(date);
    if (endDate) {
      if (endDate.getTime() < date.getTime()) {
        setSelectedEndDate(null);
      }
    }
  };

  const handleEndDateChange = (date: any) => {
    setSelectedEndDate(date);
    setEndDate(date);
  };

  const handleStartHour = (e: any) => {
    setStartHour(e.target.value);
  };

  const handleStartMinute = (e: any) => {
    setStartMinute(e.target.value);
  };

  const handleEndHour = (e: any) => {
    setEndHour(e.target.value);
  };

  const handleEndMinute = (e: any) => {
    setEndMinute(e.target.value);
  };

  const formatTime = (value: any) => {
    return parseInt(value) < 10 ? `0${value}` : value;
  };

  const handleRemoveField = (i: number) => {
    const values = [...criteria];
    values.splice(i, 1);
    setCriteria(values);
  };

  const handleAddCriteria = () => {
    handleAddField();
  };

  const dateHelper = (date: any, hour: any, minute: any) => {
    if (!date) return;
    return `${date.toDateString()}, ${formatTime(hour)}:${formatTime(minute)}`;
  };

  const Days = () => {
    if (selectedDate && selectedEndDate) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const diffTime = Math.abs(selectedEndDate - selectedDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDays(diffDays);
    }
  };

  const handleChangeInput = (i: number, e: any) => {
    const values = [...criteria];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    values[i][e.target.name] = e.target.value;
    setCriteria(values);
  };

  const handleAddField = () => {
    setCriteria([...criteria, { name: "", description: "" }]);
  };

  async function handleSubmit(event: any) {
    event.preventDefault();
    const formData = new FormData();
    inputs.start_date = new Date(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formatDate(selectedDate, startHour, startMinute)
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputs.end_date = new Date(formatDate(selectedEndDate, endHour, endMinute));

    for (const prop in inputs) {
      formData.append(`challenge[${prop}]`, (inputs as any)[prop]);
    }

    formData.append("challenge[criteria]", JSON.stringify(criteria));
    assigned?.map((v: any) =>
      formData.append("challenge[judge_ids][]", v.judge.id)
    );

    //formData.has("challenge[name]");

    //CALL ERRORS FROM THE STORE AND HANDLE ROUTE BASED ON THE ERROR
    //if(errors.length > 0) do something like stay on the page and show error message
    //else - go back to /admin/challenges and show success modal

    const response = (await dispatch(
      submitChallenge({ token: accessToken, challenge: formData })
    )) as any;

    navigate(`/admin/${slug}/challenges`);
  }

  function back() {
    navigate(-1);
  }

  useEffect(() => {
    Days();
    isValid();
  }, [selectedDate, selectedEndDate]);

  useEffect(() => {
    isValid();
  }, [startHour, startMinute]);

  const handleAdd = (e: any) => {
    if (assigned.length === 3) return;
    const id = e.target.id;
    const judgeData = judgesList[id] as any;
    const judge = judgeData.judge;
    const avatar = judgeData.avatar;
    const newArray = [...assigned];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newArray.push({ id, judge, avatar });
    e.target.setAttribute("data-assigned", id);
    e.target.parentNode.parentNode.classList.add("chosen_judge");
    setAssigned(newArray);
  };
  async function response() {
    const res = (await dispatch(getApprovedJudges(accessToken))) as any;
    setJudgesList(res.payload.judges);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="create_challenge">
      <div className="create_challenge_header">
        <button onClick={back}>Back</button>
      </div>
      <div className="create_challenge_body">
        {/* START */}
        <div className="create_challenge_body_header">
          <h2>New Challenge</h2>
        </div>
        <div className="create_challenge_form">
          <div className="create_challenge_form_header">
            <div className="create_challenge_title">Upload Challenge Video</div>
            {/* CHALLENGE INPUT */}
            <div
              style={{
                width: 300,
                height: 200,
                marginBottom: 10,
                margin: "auto",
                border: "thin solid #888",
              }}
            >
              <video
                ref={videoRef}
                onEnded={handleEnded}
                src={media}
                style={{
                  width: "100%",
                  height: "100%",

                  display: media ? "block" : "none",
                }}
                controls
              ></video>
              <div
                className="input-group mb-3"
                style={{ position: "absolute", left: -10000 }}
              >
                <input
                  type="file"
                  name="media"
                  className="form-control"
                  onChange={handleUpload}
                  ref={fileRef}
                />
              </div>
            </div>
            <button onClick={handleFile} id="upload">
              <MdImageSearch style={{ fontSize: "1.6em" }} />
            </button>
          </div>
          {/* INSTRUCTION INPURT */}
          <div className="create_challenge_instruction">
            <div className="create_challenge_title">
              Upload Instructional Video
            </div>
            <div
              style={{
                width: 300,
                height: 200,
                marginBottom: 10,
                margin: "auto",
                border: "thin solid #888",
              }}
            >
              <video
                ref={videoRef2}
                onEnded={handleEnded}
                src={instruction}
                style={{
                  width: "100%",
                  height: "100%",

                  display: instruction ? "block" : "none",
                }}
                controls
              ></video>
              <div
                className="input-group mb-3"
                style={{ position: "absolute", left: -10000 }}
              >
                <input
                  type="file"
                  name="media"
                  className="form-control"
                  onChange={handleInstructionUpload}
                  ref={fileRef2}
                />
              </div>
            </div>
            <button onClick={handleFileInstruction}>
              <MdImageSearch style={{ fontSize: "1.6em" }} />
            </button>
          </div>
          {/* DURATION START */}
          <div className="challenge_duration_container">
            <div className="challenge_duration_header">
              <h3>Duration:</h3>
            </div>
            <div className="datepicker_container">
              <div className="datepicker_start_container">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  isClearable
                  showYearDropdown
                  scrollableMonthYearDropdown
                />
                <div className="datepicker_output">
                  <div className="date_header">
                    <h4>Start:</h4>
                  </div>
                  <div className="date_body">
                    <span style={{ color: valid ? "black" : "red" }}>
                      {dateHelper(selectedDate, startHour, startMinute)}
                    </span>
                  </div>
                  <div className="date_footer">
                    <div className="date_hours">
                      <label htmlFor="hours">Hours</label>
                      <input
                        type="number"
                        id="hours"
                        name="hours"
                        min="0"
                        max="23"
                        value={startHour}
                        onChange={handleStartHour}
                      />
                    </div>
                    <div>
                      <label htmlFor="minutes">Minutes</label>
                      <input
                        type="number"
                        id="minutes"
                        name="minutes"
                        min="0"
                        max="59"
                        value={startMinute}
                        onChange={handleStartMinute}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="datepicker_end_container">
                <DatePicker
                  selected={selectedEndDate}
                  onChange={handleEndDateChange}
                  minDate={selectedDate}
                  isClearable
                  showYearDropdown
                  scrollableMonthYearDropdown
                />
                <div className="datepicker_output">
                  <div className="date_header">
                    <h4>End:</h4>
                  </div>
                  <div className="date_body">
                    <span>
                      {dateHelper(selectedEndDate, endHour, endMinute)}
                    </span>
                  </div>
                  <div className="date_footer">
                    <div className="date_hours">
                      <label htmlFor="hours">Hours</label>
                      <input
                        type="number"
                        id="hours"
                        name="hours"
                        min="0"
                        max="23"
                        value={endHour}
                        onChange={handleEndHour}
                      />
                    </div>
                    <div>
                      <label htmlFor="minutes">Minutes</label>
                      <input
                        type="number"
                        id="minutes"
                        name="minutes"
                        min="0"
                        max="59"
                        value={endMinute}
                        onChange={handleEndMinute}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="time_container">
              <label htmlFor="hours">Hours</label>
              <input
                type="number"
                id="hours"
                name="hours"
                min="1"
                max="23"
                onChange={handleHours}
              />
              <label htmlFor="minutes">Minutes</label>
              <input
                type="number"
                id="minutes"
                name="minutes"
                min="1"
                max="59"
                onChange={handleMinutes}
              />
            </div> */}
          </div>
          {/* DURATION END */}
          <form onSubmit={handleSubmit}>
            <div className="user_info">
              <div className="user_info_name">
                <span>Name:</span>
              </div>
              <div className="user_info_vlue">
                <input
                  value={inputs.name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="user_info">
              <div className="user_info_name">
                <span>Details:</span>
              </div>
              <div style={{ minHeight: 40, flex: "3 1" }}>
                <textarea
                  value={inputs.details}
                  name="details"
                  onChange={handleChange}
                  style={{ width: "90%", height: 150, padding: 5 }}
                  placeholder="enter detals ..."
                ></textarea>
              </div>
            </div>
            <div className="user_info">
              <div className="user_info_name">
                <span>Participation:</span>
              </div>
              <div style={{ minHeight: 40, flex: "3 1" }}>
                <textarea
                  value={inputs.participation}
                  name="participation"
                  onChange={handleChange}
                  style={{ width: "90%", height: 150, padding: 5 }}
                  placeholder="enter participation ..."
                ></textarea>
              </div>
            </div>
            <div className="user_info">
              <div className="user_info_name">
                <span>Instructions:</span>
              </div>
              <div style={{ minHeight: 40, flex: "3 1" }}>
                <textarea
                  value={inputs.instructions}
                  name="instructions"
                  onChange={handleChange}
                  style={{ width: "90%", height: 150, padding: 5 }}
                  placeholder="enter instructions ..."
                ></textarea>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <FormGroup
                row={true}
                id="submit-group"
                sx={{ marginTop: "1em", marginRight: "1em", width: "20%" }}
              >
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="submit-button"
                    sx={{
                      background: "#008cba",
                      ":hover": {
                        color: "black",
                        bgcolor: "white",
                      },
                    }}
                  >
                    {/* USE THE PROXY COMPONENT TO MOVE THE BUTTON OUT OF FORM */}
                    Submit
                  </Button>
                </FormControl>
              </FormGroup>
            </div>
          </form>
        </div>
        {/* END */}
        <Container className="criteria_creation_container">
          <div className="criteria_creation_header">
            <div className="criteria_creation_title">
              <h3>Criteria</h3>
            </div>
            <div className="criteria_creation_icons">
              <AddCircleIcon onClick={handleAddCriteria} />
            </div>
            {/* <button onClick={handleAddCriteria} disabled={criteria.length > 0}>
              Add Criteria
            </button> */}
          </div>
          <div className="criteria_creation_body" style={{ marginBottom: 20 }}>
            {criteria.map((v, i) => {
              return (
                <div key={i}>
                  <TextField
                    name="name"
                    label="name"
                    variant="filled"
                    value={v.name}
                    onChange={(e: any) => handleChangeInput(i, e)}
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                  <TextField
                    name="description"
                    label="description"
                    variant="filled"
                    value={v.description}
                    onChange={(e: any) => handleChangeInput(i, e)}
                  />
                  <IconButton onClick={() => handleRemoveField(i)}>
                    <RemoveIcon />
                  </IconButton>
                  <IconButton onClick={() => handleAddField()}>
                    <AddIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </Container>
        {judgesList.length === 0 ? (
          <Loading />
        ) : (
          <JudgeSection
            assigned={assigned}
            setAssigned={setAssigned}
            judgesList={judgesList}
            handleAdd={handleAdd}
          />
        )}
      </div>
    </div>
  );
};

export default ChallengeForm;

function JudgeSection({ assigned, setAssigned, judgesList, handleAdd }: any) {
  return (
    <div className="challenge_judge_container">
      <div className="challenge_judge_container_header">
        <h3>Judges</h3>
      </div>
      <div className="judges_assigned">
        {assigned.length > 0 ? (
          <AssignedJudges
            assigned={assigned}
            setAssigned={setAssigned}
            avatar={avatar}
          />
        ) : (
          <div className="judges_empty">Add a Judge</div>
        )}
      </div>
      <Judges judgesList={judgesList} handleAdd={handleAdd} avatar={avatar} />
    </div>
  );
}

function Judges({ judgesList, handleAdd, avatar }: any) {
  return (
    <div className="all_judges">
      {judgesList.map((v: any, i: any) => {
        return (
          <div key={v.judge.id} className="judge_card">
            <div className="judge_card_header">
              <div className="judge_card_title">{v.judge.name}</div>
            </div>
            <div className="judge_card_body">
              <img src={v.avatar ? v.avatar : avatar} />
            </div>
            <div className="judge_card_footer">
              <button className="approve" id={i} onClick={handleAdd}>
                ADD
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function AssignedJudges({ assigned, setAssigned, avatar }: any) {
  const handleRemove = (e: any) => {
    const id = e.target.dataset.element;
    const element = document.querySelector(`[data-assigned="${id}"]`);
    const newArray = [...assigned];
    const filteredArray = newArray.filter((v, i) => v.id !== id);
    setAssigned(filteredArray);
    element?.setAttribute("data-assigned", "");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    element?.parentNode?.parentNode.classList.remove("chosen_judge");
  };

  return (
    <div className="assigned_judges">
      {assigned.map((v: any, i: any) => {
        return (
          <div key={i} className="assigned_judge">
            <div className="assigned_judge_header">
              <button
                className="assigned_judge_remove"
                id={i}
                data-element={v.id}
                onClick={handleRemove}
              >
                ✖
              </button>
            </div>
            <div className="assigned_judge_body">
              <img src={v.avatar ? v.avatar : avatar} />
            </div>
            <div className="assigned_judge_footer">
              <div className="assigned_judge_footer_title">{v.judge.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
