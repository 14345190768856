import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import HardwareIcon from "@mui/icons-material/Hardware";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import GavelIcon from "@mui/icons-material/Gavel";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";
import { Link } from "react-router-dom";
import "./Sidebar.scss";

export default function Sidebar() {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  return (
    <div className="admin_sidebar">
      <SideBarHeader />
      <SideBarBody slug={slug} />
      <SideBarFooter />
    </div>
  );
}

function SideBarHeader() {
  return (
    <div className="admin_sidebar_header">
      <span className="admin_sidebar_logo">Admin</span>
    </div>
  );
}

function SideBarBody({ slug }: any) {
  return (
    <div className="admin_sidebar_body">
      <ul>
        {/* MAIN */}
        <p className="title">MAIN</p>
        <Link to={`/admin`}>
          <li className="">
            <DashboardIcon className="icons" />
            <span>Dashboard</span>
          </li>
        </Link>
        {/* LIST */}
        <p className="title">LIST</p>
        <Link to={`/admin/users`}>
          <li className="">
            <PersonIcon className="icons" />
            <span>Users</span>
          </li>
        </Link>
        <Link to={`/admin/challenges`}>
          <li className="">
            <EmojiEventsIcon className="icons" />
            <span>Challenges</span>
          </li>
        </Link>
        <Link to={`/admin/experts`}>
          <li className="">
            <GavelIcon className="icons" />
            <span>Experts</span>
          </li>
        </Link>
        <li className="">
          <ChatBubbleIcon className="icons" />
          <span>Posts</span>
        </li>
        {/* USEFUL */}
        <p className="title">USEFUL</p>
        <li className="">
          <NotificationsIcon className="icons" />
          <span>Notification</span>
        </li>
        <li className="">
          <SettingsIcon className="icons" />
          <span>Settings</span>
        </li>
        <li className="">
          <AccountCircleIcon className="icons" />
          <span>Profile</span>
        </li>
      </ul>
    </div>
  );
}

function SideBarFooter() {
  return (
    <div className="admin_sidebar_footer">
      <div className="color_option"></div>
      <div className="color_option"></div>
    </div>
  );
}
