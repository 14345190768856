import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../../sessions/sessionSlice";
import {
  readUsers,
  createUser,
  deleteUsers,
  updateUser,
  updateExpert,
} from "../../../../api/userAPI";

interface UsersState {
  loading: boolean;
  error: boolean;
  errorMessages: string[];
  users?: Array<User>;
}

interface UsersData {
  token: string | undefined;
  role: string | undefined;
}

interface userData {
  token: string | undefined;
  user: User | any;
}

interface destroyData {
  token: string | undefined;
  ids: Array<any>;
}

const initialState: UsersState = {
  loading: false,
  error: false,
  errorMessages: [],
  users: [],
};

export const getUsers = createAsyncThunk(
  "session/getUsers",
  async (payload: UsersData, { rejectWithValue }) => {
    const response = await readUsers(payload.token, payload.role);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const submitUser = createAsyncThunk(
  "session/submitUser",
  async (payload: userData, { rejectWithValue }) => {
    const response = await createUser(payload.token, payload.user);

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const updateUserRecord = createAsyncThunk(
  "session/updateUserRecord",
  async (payload: userData, { rejectWithValue }) => {
    const response = await updateUser(payload.token, payload.user);

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const updateExpertStatus = createAsyncThunk(
  "session/updateExpertStatus",
  async (payload: userData, { rejectWithValue }) => {
    const response = await updateExpert(payload.token, payload.user);

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const destroyUsers = createAsyncThunk(
  "session/removeUsers",
  async (payload: destroyData, { rejectWithValue }) => {
    const response = await deleteUsers(payload.token, payload.ids); //errors can go on state obj

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder //Looking into the default reducer so I don't have to keep writing the same addCase
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getUsers.fulfilled, (state, action: any) => {
        state.users = action.payload.users;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getUsers.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(submitUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitUser.fulfilled, (state, action: any) => {
        state.users?.push(action.payload.user);
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(updateUserRecord.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateUserRecord.fulfilled, (state, action: any) => {
        state.users = action.payload.users;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateUserRecord.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(updateExpertStatus.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateExpertStatus.fulfilled, (state, action: any) => {
        state.users = action.payload.users;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(updateExpertStatus.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(destroyUsers.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(destroyUsers.fulfilled, (state, action: any) => {
        state.users = action.payload.users;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(destroyUsers.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      });
  },
});

// export const { setUsers } = usersSlice.actions;

export default usersSlice.reducer;
