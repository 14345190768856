import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { submitForgotPassword } from "../../../sessionSlice";
import "./EmailSent.scss";

function EmailSent() {
  const location = useLocation();
  const { email } = location.state as any;

  const dispatch = useDispatch();

  async function handleResend(event: any) {
    event.preventDefault();
    const inputs = { email };

    (await dispatch(submitForgotPassword(inputs))) as any;
  }

  return (
    <section className="email_sent">
      <div className="email_sent_modal">
        <div className="check_mark"></div>

        <div className="title">
          <h3>Email Sent</h3>
        </div>
        <div>
          <p>
            A request has been made to reset your account. Please check your
            email and reset your password.
          </p>
        </div>
      </div>
      <p className="resend_option">
        Didn’t receive the email?{" "}
        <a href="" onClick={handleResend}>
          Resend it
        </a>
      </p>
    </section>
  );
}

export default EmailSent;
