import { useState, useEffect } from "react";
import { ImArrowLeft } from "react-icons/im";
// import avatar from "../../../../assets/avatar.png"; //NEEDS TO BE THE AVATAR OF THE INDIVIDUAL WHO CREATED THE CHALLENGE
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getChallenge } from "../challengeSlice";
import { Link, useParams, useNavigate } from "react-router-dom";
import ChallengeInformation from "./challenge_information/ChallengeInformation";
import ChallengeInstructions from "./challenge_instructions/ChallengeInstructions";
import Leaderboard from "./leaderboard/Leaderboard";
import JudgesList from "./judges_list/JudgesList";
import { FaChevronLeft } from "react-icons/fa";

import "./Challenge.scss";
/**
 *
 * TODO
 * Fix this component. It should not use useState to set a challenge
 * get the challenge from the store. Also add loading and handle the date and money
 */

const Challenge = () => {
  const [show, setshow] = useState<boolean>(true);

  const navigate = useNavigate();

  function back(event: any) {
    navigate(-1);
  }

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  const { id } = useParams();

  const handleNavigation = () => {
    navigate(-1);
  };

  return (
    <div className="challenge_container">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
      </div>
      <ChallengeInformation id={id} />
      <ChallengeInstructions id={id} />
      <hr />
      <div className="challenge_leaderboard_judges">
        <div className="challenge_leaderboard">
          <Leaderboard />
        </div>
        <div className="challenge_judges">
          <JudgesList id={id} />
        </div>
      </div>
    </div>
  );
};
export default Challenge;
