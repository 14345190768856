import axios from "axios";

const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_PRODUCTION_URL;
const instance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
    withCredentials: true,
  },
});

export default instance;
