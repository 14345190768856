import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; //step 1
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  createUser,
  readCurrentUser,
  loginUser,
  logoutUserWithToken,
  requestAccessTokenWithRefreshToken,
  stripePaymentIntent,
  readApprovedJudges,
  readNotifications,
  readJudgeNotifications,
  readUserNotifications,
  createScore,
  creasteNewletterSubscription,
  resetPassword,
  forgotPassword,
  // stripePaymentSetup,
} from "../../api/sessionAPI";

//step 2 - interface
export interface User {
  //why 2 interfaces with the same attributes????????
  id?: number;
  email?: string;
  role?: string;
  createdAt?: string;
  avatar?: string;
  name?: string;
  username?: string;
  country?: string;
  charity?: string;
  bio?: string;
  approved?: boolean;
  denied?: boolean;
  official_title?: string;
  personal_website?: string;
  company_website?: string;
  linkedin_url?: string;
}

export interface Charge {
  amount: number;
  currency: string;
}

export interface UserSignUpData {
  name: string;
  email: string;
  username: string;
  password: string;
  country: string;
  charity: string;
  bio: string;
  official_title: string;
  specialization: string;
  personal_website: string;
  company_website: string;
  linkedin_url: string;
}

export interface UserLoginData {
  email: string;
  password: string;
}

export interface newsLetterData {
  email: string;
}

export interface resetData {
  email: string;
  password: string;
  token: string;
}

export interface forgotData {
  email: string;
}

interface AuthState {
  currentUser?: User;
  loading: boolean;
  error: boolean;
  errorMessages: string[];
  accessToken?: string;
  refreshToken?: string | null;
  expiresIn?: number;
  tokenType?: string;
  currentRoute?: string;
  leaders?: any;
  msg?: string;
  forgot?: boolean;
}

interface chargeData {
  token: string | undefined;
  charge: Charge | any;
}

//step 3 - initialState
const initialState: AuthState = {
  currentUser: {
    id: undefined,
    email: "",
    role: "",
    createdAt: "",
    name: "",
    username: "",
    country: "",
    charity: "",
    avatar: "",
    bio: "",
    approved: false,
    official_title: "",
    personal_website: "",
    company_website: "",
    linkedin_url: "",
  },
  loading: true,
  error: false,
  errorMessages: [],
  accessToken: undefined,
  refreshToken: getRefreshToken(),
  expiresIn: undefined,
  tokenType: undefined,
  leaders: [],
  msg: "",
  forgot: true,
};

//step 4 - thunks
export const signUpUser = createAsyncThunk(
  "session/signUpUser",
  async (payload: UserSignUpData, { rejectWithValue }) => {
    const response = await createUser(payload);

    if (response.errors) {
      return rejectWithValue(response);
    }
    return response;
  }
);

export const signInUser = createAsyncThunk(
  "session/signInUser",
  async (payload: UserLoginData, { rejectWithValue }) => {
    const loginResponse = await loginUser(payload.email, payload.password);

    if (loginResponse.error) {
      return rejectWithValue(loginResponse);
    }

    const userResponse = await readCurrentUser(loginResponse.access_token);
    if (userResponse.error) {
      return rejectWithValue(userResponse.data);
    }

    const response = {
      ...loginResponse,
      ...userResponse,
    };

    return response;
  }
);

export const logoutUser = createAsyncThunk(
  "session/logoutUser",
  async (payload: string, { rejectWithValue }) => {
    const response = await logoutUserWithToken(payload);

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const refreshAccessToken = createAsyncThunk(
  "session/refreshAccessToken",
  async (refreshToken: string | undefined | null, { rejectWithValue }) => {
    if (!refreshToken) {
      return rejectWithValue("No refresh token");
    }
    const refreshResponse = await requestAccessTokenWithRefreshToken(
      refreshToken
    );
    if (refreshResponse.error) {
      return rejectWithValue(refreshResponse.data);
    }

    const userResponse = await readCurrentUser(refreshResponse.access_token);

    if (userResponse.error) {
      return rejectWithValue(userResponse.data);
    }

    const response = {
      ...refreshResponse,
      ...userResponse,
    };

    return response;
  }
);

///END OF Step 4 for sessionSlice

export const getApprovedJudges = createAsyncThunk(
  "session/adminUser",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readApprovedJudges(accessToken);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getNotifications = createAsyncThunk(
  "session/getNotifications",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readNotifications(accessToken); //This should be on state -- readUsers
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getJudgeNotifications = createAsyncThunk(
  "session/getJudgeNotifications",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readJudgeNotifications(accessToken); //This should be on state -- readUsers
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getUserNotifications = createAsyncThunk(
  "session/getUserNotifications",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readUserNotifications(accessToken); //This should be on state -- readUsers
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const StripePayment = createAsyncThunk(
  "session/StripePayment",
  async (payload: chargeData, { rejectWithValue }) => {
    const response = await stripePaymentIntent(payload.token, payload.charge);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const submitNewletterSubscription = createAsyncThunk(
  "session/submitNewletterSubscription",
  async (payload: newsLetterData, { rejectWithValue }) => {
    const response = await creasteNewletterSubscription(payload);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export interface Score {
  content: string;
  criteria: any;
  user_id: number | undefined;
  submission_id: number;
}

interface scoreData {
  token: string | undefined;
  score: Score;
}

export const submitScore = createAsyncThunk(
  "session/submitScore",
  async (payload: scoreData, { rejectWithValue }) => {
    const response = await createScore(payload.token, payload.score);

    if (response.errors) {
      return rejectWithValue(response);
    }
    return response;
  }
);

export const submitResetPassword = createAsyncThunk(
  "session/submitResetPassword",
  async (payload: resetData, { rejectWithValue }) => {
    const response = await resetPassword(payload);

    if (response.errors) {
      return rejectWithValue(response);
    }
    return response;
  }
);

export const submitForgotPassword = createAsyncThunk(
  "session/submitForgotPassword",
  async (payload: forgotData, { rejectWithValue }) => {
    const response = await forgotPassword(payload);

    if (response.errors) {
      return rejectWithValue(response);
    }
    return response;
  }
);

// export const StripeSetupPayment = createAsyncThunk(
//   "session/StripeSetupPayment",
//   async (accessToken: string | undefined, { rejectWithValue }) => {
//     const response = await stripePaymentSetup(accessToken, "payload.charge");
//     if (response.error) {
//       // The value we return becomes the `rejected` action payload
//       return rejectWithValue(response.data);
//     }
//     // The value we return becomes the `fulfilled` action payload
//     return response;
//   }
// );

//step 5 - slice

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    resetErrorState: (state) => {
      state.error = false;
      state.errorMessages = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(signUpUser.fulfilled, (state, action: any) => {
        state.accessToken = action.payload.user.access_token;
        state.refreshToken = action.payload.user.refresh_token;
        state.expiresIn = action.payload.user.expires_in;
        state.tokenType = action.payload.user.token_type;
        state.currentUser = action.payload.user;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        storeRefreshToken(action.payload.refresh_token);
      })
      .addCase(signUpUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(signInUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(signInUser.fulfilled, (state, action: any) => {
        state.accessToken = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.expiresIn = action.payload.expires_in;
        state.currentUser = action.payload.user;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        storeRefreshToken(action.payload.refresh_token);
      })
      .addCase(signInUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [
          "Invalid credentials. Did you enter them correctly?",
        ];
      })
      .addCase(refreshAccessToken.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(refreshAccessToken.fulfilled, (state, action: any) => {
        state.accessToken = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        state.expiresIn = action.payload.expires_in;
        state.currentUser = action.payload.user;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        storeRefreshToken(action.payload.refresh_token);
      })
      .addCase(refreshAccessToken.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.currentUser = initialState.currentUser;
        state.accessToken = undefined;
        state.refreshToken = undefined;
        state.expiresIn = undefined;
        state.tokenType = undefined;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        removeRefreshToken();
      })
      .addCase(logoutUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })
      .addCase(submitResetPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitResetPassword.fulfilled, (state, action: any) => {
        state.msg = action.payload.msg;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        removeRefreshToken();
      })
      .addCase(submitResetPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      })
      .addCase(submitForgotPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitForgotPassword.fulfilled, (state, action: any) => {
        state.forgot = action.payload.forgot;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
        removeRefreshToken();
      })
      .addCase(submitForgotPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = [action.payload.error];
      });
  },
});

// step 6
export const { resetErrorState } = sessionSlice.actions;

//step 7
export default sessionSlice.reducer;

function storeRefreshToken(token: string) {
  localStorage.setItem("refreshToken", token);
}

function removeRefreshToken() {
  localStorage.removeItem("refreshToken");
}

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}
