import { useState, useRef, useEffect } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import "./ChallengeInstructions.scss";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getChallenge } from "../../challengeSlice";

// TODO
// Create obj for instruction video and message

const ChallengeInstructions = ({ id }: any) => {
  const [play, setPlay] = useState(false);
  const videoRef = useRef<any>();

  const handleVideo = (e: any) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlay(true);
    } else {
      videoRef.current.pause();
      setPlay(false);
    }
  };

  const handleEnd = (e: any) => {
    e.target.currentTime = 0;
    setPlay(false);
  };

  const [challenge, setChallenge] = useState<any>({});

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  async function response() {
    const challenge = { token: accessToken, id: id };
    const res = (await dispatch(getChallenge(challenge))) as any;
    setChallenge(res.payload.challenge);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="challenge_instructions">
      <div className="challenge_instructions_modal">
        <div className="challenge_instructions_video">
          <div
            className="challenge_instructions_video_wrapper"
            onClick={handleVideo}
          >
            <video
              src={challenge?.instruction}
              ref={videoRef}
              onEnded={handleEnd}
            ></video>
            <div
              className="challenge_instructions_video_play_btn"
              style={{ display: !play ? "grid" : "none" }}
            >
              <BsFillPlayFill />
            </div>
          </div>
        </div>
        <div className="challenge_instructions_details">
          <h2>How to complete this challenge?</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            rerum maiores beatae eos officia neque est quo corporis mollitia
            doloribus nam architecto sit, aperiam ipsa fugit nostrum autem hic?
            Dolore!
          </p>
          <div className="challenge_instructions_pattern"></div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeInstructions;
