import { useState } from "react";
import Header from "../../Header";
import Logo from "../../logo/Logo";
import {
  LogoutBtn,
  NotificationBtn,
  MobileNotificationBtn,
  SettingsBtn,
  Avatar,
} from "../nav_item/NavItem";
import HamburgerMenu from "../../hamburger_menu/HamburgerMenu";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";

/**
 *
 * NOTE
 * You can add a property url to NavCreator and use it for Creator and Expert
 */

const NavAdmin = () => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow((prev) => !prev);
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  return (
    <Header>
      <nav>
        <Logo path={`/admin`} />
        <div className="menu_desktop">
          <ul className="list">
            <LogoutBtn />
            <NotificationBtn />
            <Avatar url={`/admin/${slug}`} />
          </ul>
        </div>
        <HamburgerMenu handleToggle={handleToggle} />
        <MobileNotificationBtn />
        <SettingsBtn />
        <MobileMenu show={show} setShow={setShow} />
      </nav>
    </Header>
  );
};

export default NavAdmin;

function MobileMenu({ show, setShow }: any) {
  const closeMenu = () => setShow(false);

  return (
    <div
      className={`menu_mobile${show ? " menu_open" : ""}`}
      onClick={closeMenu}
    >
      <ul>
        <LogoutBtn />
      </ul>
    </div>
  );
}
