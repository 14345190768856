import React, { useState, useEffect } from "react";
import { RootState } from "../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { getChallenge } from "../../challengeSlice";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import "./JudgesList.scss";
import avatar from "../../../../../assets/avatar.png";

const JudgesList = ({ id }: any) => {
  const [challenge, setChallenge] = useState<any>({});
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const navigate = useNavigate();

  // const handleRequestFeedback = (e: any) => {

  //   navigate("/judge_request_feedback", {
  //     state: { judge: JSON.parse(e.target.dataset.judge) },
  //   });
  // };

  const handleShowAll = () =>
    navigate("judges_list", { state: JSON.stringify(challenge?.judges) });

  async function response() {
    const challenge = { token: accessToken, id: id };
    const res = (await dispatch(getChallenge(challenge))) as any;
    setChallenge(res.payload.challenge);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="judges_list">
      <div className="judges_list_header">
        <h1>Judges</h1>
        <button onClick={handleShowAll}>
          Show All <FiChevronRight />
        </button>
      </div>
      <div className="judges_list_body">
        {challenge?.judges?.map((v: any, i: any) => {
          return (
            <div key={i} className="judge_list_item">
              <div className="judge_list_item_avatar_container">
                <img src={v.avatar ? v.avatar : avatar} />
              </div>
              <div className="judge_list_item_name_request_feedback">
                <h3>{v.name}</h3>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default JudgesList;
