import { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Star from "app/features/tools/star/Star";
import { FaChevronLeft, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { validate, validateErrors } from "app/tools";
import "./CreatorSignUp.scss";

function SignUp() {
  interface Inputs {
    name: string;
    email: string;
    password: string;
  }

  const location = useLocation();
  const { state } = location as any;

  const initialState: Inputs = {
    name: "",
    email: state?.email,
    password: "",
  };

  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [slide, setSlide] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(true);
  const [errors, setErrors] = useState<Inputs>({
    email: `${state?.email ? "" : "*"}`,
    name: `${"*"}`,
    password: `${"*"}`,
  });

  const navigate = useNavigate();

  const handleNavigation = () => {
    setSlide(false);
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(errors, name, value);
    setErrors(errors);
  }

  const togglePassword = (e: any) => {
    setToggle((prev) => !prev);
  };

  async function handleSubmit(event: any) {
    event.preventDefault();
    navigate("/signup_final", {
      state: { ...inputs },
    });
  }

  return (
    <section className="creator_sign_up">
      <div className="header">
        <div
          className="navigation"
          style={{ display: slide ? "flex" : "none" }}
        >
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
        <Star />
        <div className="title">
          <h3>Creator sign up</h3>
          <div>
            <span>1 of 2</span>
          </div>
        </div>
        <div>
          <p>
            A few simple steps and you&apos;ll get access to the Studious
            platform
          </p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="phone">
                Full name <sup>*</sup>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Full name"
                value={inputs.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="email">
                Email <sup>*</sup>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={inputs.email || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form_item_container">
            <label htmlFor="password">
              Create password <sup>*</sup>
            </label>
            <input
              type={toggle ? "password" : "text"}
              name="password"
              placeholder="Password"
              value={inputs.password}
              onChange={handleChange}
              required
            />
            <div className="password_reveal" onClick={togglePassword}>
              {toggle ? <FaRegEye /> : <FaRegEyeSlash />}
            </div>
          </div>
          <button type="submit" disabled={!validate(errors) ? true : false}>
            Next Step
          </button>
          <p className="sign_in_option">
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </form>
      </div>
    </section>
  );
}

export default SignUp;
