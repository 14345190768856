import { useEffect, useState } from "react";
import { getSubmissions } from "../../../submissions/submissionSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import CreatorItem from "../../creator_item/CreatorItem";
import "./ChallengesTab.scss";

const ChallengesTab = () => {
  const [submissions, setSubmissions] = useState<any[]>([]);
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const dispatch = useDispatch();

  async function response() {
    const submission = { token: accessToken, submission: currentUser?.id };
    const res = (await dispatch(getSubmissions(submission))) as any;
    const submissions_sliced = res.payload.submissions.slice(0, 4);

    setSubmissions(submissions_sliced);
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="challenges_container">
      {submissions.map((v: any, i: any) => {
        return (
          <CreatorItem
            key={i}
            media={v.media}
            description={v.submission.description}
            type={v.type}
          />
        );
      })}
    </div>
  );
};

export default ChallengesTab;
