import Sidebar from "./sidebar/Sidebar";
import Content from "./content/Content";
import "./admin.scss";

export default function Admin() {
  return (
    <div className="admin">
      <Sidebar />
      <Content />
    </div>
  );
}
