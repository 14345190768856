import axios from "./axios";
import {
  OneToOneFeedbackSubmission,
  ChallengeSubmissionFeedback,
} from "../features/experts/expertSlice";

const SUBMISSIONS_URL = "/users";
const CREATE_URL = "/submissions.json";
const DELETE_URL = "/submissions";
const READ_URL = "/submissions";
const CHALLENGE_SUBMISSIONS_URL = "/challenge_submissions.json";
const REQUEST_FEEDBACK_URL = "/request_feedbacks.json";
const ONE_TO_ONE_FEEDBACK_SUBMISSION_URL =
  "/one_to_one_feedback_submission.json";
const REQUESTS_FOR_EXPERT = "/requests_for_expert";
const INDIVIDUAL_REQUEST_FEEDBACK = "/individual_request_feedback";
const ACCEPT_REQUEST = "/accept_request";
const DECLINE_REQUEST = "/decline_request";
const FEEDBACK_URL = "/feedbacks.json";

export async function createSubmission(
  accessToken: string | undefined,
  submission: any
) {
  const data = submission;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(CREATE_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readSubmissions(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${SUBMISSIONS_URL}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readSubmission(
  accessToken: string | undefined,
  data: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  let url = "";
  const { submission, notification } = data;
  url = notification
    ? `${READ_URL}/${submission}.json?notification=${notification}`
    : `${READ_URL}/${submission}.json`;

  return axios
    .get(url, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readChallengeSubmissions(
  accessToken: string | undefined
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${CHALLENGE_SUBMISSIONS_URL}`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readSubmissionFeedback(payload: {
  token: string | undefined;
  id: number;
}) {
  const config = {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  };

  return axios
    .get(`/feedbacks/${payload.id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function deleteSubmission(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .delete(`${DELETE_URL}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

// **************************
/** */
export async function createRequestFeedback(
  accessToken: string | undefined,
  request_feedback: any
) {
  const data = request_feedback;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(REQUEST_FEEDBACK_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function createOneToOneFeedbackSubmission(
  accessToken: string | undefined,
  one_to_one_feedback_submission: OneToOneFeedbackSubmission
) {
  const data = one_to_one_feedback_submission;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(ONE_TO_ONE_FEEDBACK_SUBMISSION_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function createChallengeSubmissionFeedback(
  accessToken: string | undefined,
  challenge_submission_feedback: ChallengeSubmissionFeedback
) {
  const data = challenge_submission_feedback;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(FEEDBACK_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
//Here
export async function readRequestFeedbacks(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${REQUESTS_FOR_EXPERT}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readIndividualFeedbackRequests(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${INDIVIDUAL_REQUEST_FEEDBACK}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function acceptSubmissionRequest(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${ACCEPT_REQUEST}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function declineSubmissionRequest(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${DECLINE_REQUEST}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

// export async function readUserRequestFeedback(payload: {
//   token: string | undefined;
//   id: number;
// }) {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${payload.token}`,
//     },
//   };

//   return axios
//     .get(`/request_feedbacks/${payload.id}.json`, config)
//     .then((response: any) => {
//       return response.data;
//     })
//     .catch((error: any) => {
//       return error.response.data;
//     });
// }
