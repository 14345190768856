import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getRequestFeedbacks } from "app/features/experts/expertSlice";
import PreviousFeedback from "app/features/profile/profle_content/previous_feedback/PreviousFeedback";

const PreviousFeedbackTab = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const judged = useSelector((state: RootState) => state.expert.judged);

  async function response() {
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
  }

  useEffect(() => {
    response();
  }, []);

  return (
    <div className="items">
      {" "}
      {judged.map((v: any, i: any) => {
        return <PreviousFeedback v={v} key={i} />;
      })}
    </div>
  );
};

export default PreviousFeedbackTab;
