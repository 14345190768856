import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../../../store";
import { submitForgotPassword } from "../../sessionSlice";
// import StarHalfIcon from "@mui/icons-material/StarHalf";
import Star from "app/features/tools/star/Star";
import { FaChevronLeft } from "react-icons/fa";
import { validate, validateErrors } from "../../../../tools";
import "./ForgotPassword.scss";

function ForgotPassword() {
  interface Inputs {
    email: string;
  }

  const initialState: Inputs = {
    email: "",
  };

  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );

  const forgot = useSelector((state: RootState) => state.session.forgot);

  const [errors, setErrors] = useState<Array<string>>([]);
  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [valid, setValid] = useState({
    email: "*",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate("/signin");
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    const res = (await dispatch(submitForgotPassword(inputs))) as any;
    console.log("Why you not showing", res);
    if (res.payload.forgot) {
      navigate("/email_sent", { state: inputs });
    }
  }

  return (
    <section className="forgot_password">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
        <Star />
        <div className="title">
          <h3>Forgot your password?</h3>
        </div>
        <div>
          <p>
            Enter your email. If it exists in our system we will send a password
            reset to your email.
          </p>
        </div>
        <div style={{ display: !forgot ? "block" : "none" }}>
          <p>Email not found</p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                value={inputs.email}
                onChange={handleChange}
                required
              />
              <div className="valid_check"></div>
            </div>
          </div>
          <button type="submit" disabled={!validate(valid) ? true : false}>
            Send link to email
          </button>
        </form>
      </div>
    </section>
  );
}

export default ForgotPassword;
