import axios from "./axios";
import {
  UserSignUpData,
} from "../features/sessions/sessionSlice";

const CREATE_URL = "experts.json";
const READ_URL = "/users/experts.json";
const READ_PUBLIC_URL = "/experts_public.json";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export async function createUser(payload: UserSignUpData) {
  const data = {
    ...payload,
    client_id: CLIENT_ID,
  };

  return axios
    .post(CREATE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function readExperts(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(READ_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

// export async function readExpertsPublic(accessToken: string | undefined) {
//   return axios
//     .get(READ_PUBLIC_URL)
//     .then((response: any) => {
//       return response.data;
//     })
//     .catch((error: any) => {
//       return error.response.data;
//     });
// }

// export async function readExpertsPublic() {
//   const data = {
//     client_id: CLIENT_ID,
//   };

//   return axios
//     .post(READ_PUBLIC_URL, data)
//     .then((response: any) => {
//       return response.data;
//     })
//     .catch((error: any) => {
//       return error.response.data;
//     });
// }

export async function readExpertsPublic() {
  return axios
    .get(READ_PUBLIC_URL, {
      params: {
        client_id: CLIENT_ID,
      },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
