import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { getUserNotifications } from "../sessions/sessionSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import avatar from "assets/avatar.png";
import {
  getSubmissions,
  getChallengeSubmissions,
} from "../submissions/submissionSlice";
import CreatorContent from "./creator_content/CreatorContent";
import CreatorProfileForm from "./creator_profile_form/CreatorProfileForm";
import { create_slug } from "app/tools";
import "./Creator.scss";

const Creator = () => {
  const [showModal, setShowModal] = useState(false);
  // const [submissions, setSubmissions] = useState<any[]>([]);
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const slug = create_slug(currentUser?.name);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function toggleShow() {
    setShowModal((prev) => {
      document.body.style.overflow = !prev ? "hidden" : "scroll";
      return !prev;
    });
  }

  const location = useLocation();
  const { state } = location as any;

  async function response() {
    // await dispatch(getJudgeNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    // await dispatch(getNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    // await dispatch(
    //   getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    // );
    const res = await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
    if (state?.new_user) {
      navigate(`/creator/${slug}/experts_list`);
    }
  }, []);

  return (
    <section className="creator">
      <div className="header"></div>
      <div className="body">
        <div className="data_area">
          <div className="avatar_container">
            <div className="avatar_wrapper">
              <img src={!currentUser?.avatar ? avatar : currentUser?.avatar} />
              <div></div>
            </div>
          </div>
          <div className="data_container">
            <h1>{currentUser?.name}</h1>
            <h4>{currentUser?.official_title}</h4>
            <button>
              <div></div> Creator
            </button>
          </div>
          <div className="edit_container">
            <button onClick={toggleShow}>
              Edit <div></div>
            </button>
          </div>
        </div>
        <div className="text_area">
          <h3>About me</h3>
          <p>{currentUser?.bio}</p>
        </div>
        <div className="profile_body">
          <CreatorContent />
        </div>
      </div>
      <CreatorProfileForm toggleShow={toggleShow} show={showModal} />
    </section>
  );
};

export default Creator;
