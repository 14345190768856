import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";

import { getChallenges } from "../../../challenges/challengeSlice";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import SearchIcon from "@mui/icons-material/Search";

import { Link } from "react-router-dom";
import Loading from "../../../tools/Loading";
import { create_slug } from "app/tools";
import "./Challenges.scss";

const Challenges = () => {
  const challenges = useSelector(
    (state: RootState) => state.challenge.challenges
  );
  const loading = useSelector((state: RootState) => state.challenge.loading);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const dispatch = useDispatch();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  async function response() {
    await dispatch(getChallenges(accessToken));
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="admin_challenges">
      <div className="content_header">
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchIcon />
          </div>
          <div className="items">
            <div className="item">
              <Link to={"challenge_form"}>
                <AddModeratorIcon className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section className="content_body">
        <div className="content_body_header">
          <h2>Challenges</h2>
        </div>

        <div>
          {loading ? (
            <Loading />
          ) : (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {challenges?.map((v: any) => {
                return (
                  <Grid item xs={2} sm={4} md={4} key={v.challenge.id}>
                    <Link to={`/admin/${slug}/challenge/${v.challenge.id}`}>
                      <Item>
                        <div>{v.challenge.name}</div>
                        <div style={{ height: 300, border: "thin solid #999" }}>
                          <video
                            src={v.media}
                            style={{ width: "100%", height: "100%" }}
                          ></video>
                        </div>
                        <div>{v.challenge.details}</div>
                      </Item>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          )}

          <div
            style={{
              display: "none",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, .4)",
            }}
          >
            <div style={{ height: 30 }}></div>
            <div
              style={{
                position: "fixed",
                width: "90%",
                height: "80vh",
                left: "5%",
                background: "white",
              }}
            ></div>
          </div>
        </div>
        <div style={{ display: "flex" }}></div>
        <Stack spacing={2} className="pagination">
          <Pagination count={10} variant="outlined" shape="rounded" />
        </Stack>
      </section>
    </div>
  );
};

export default Challenges;
