import { useRef, useState, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import "./VideoRecorder.scss";

import Timer from "./Timer";
import { convertToDate } from "app/tools";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isMobile = /Android|iPhone/i.test(navigator.userAgent);

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

const VideoRecorder = ({ setInputs, handleRecorder }: any) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<Webcam>(null);
  const timeRef = useRef();
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [url, setUrl] = useState<any>(null);
  const [fileOfBlob, setFileOfBlob] = useState();
  const [saved, setSaved] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const handleStartCapture = useCallback(() => {
    setCapturing(true);
    setRecordedChunks([]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCapture = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (mediaRecorderRef.current?.state === "inactive") return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mediaRecorderRef.current.stop();

    setCapturing(false);
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setUrl(url);
      setInputs((prevState: any) => ({ ...prevState, medias: blob }));
    }
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const a = document.createElement("a");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      a.href = url;
      a.download = `${convertToDate()}.webm`;
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks, url]);

  const handleRemoveBlob = (e: any) => {
    setRecordedChunks([]);
    setUrl("");
  };

  useEffect(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setUrl(url);
      const fileOfBlob = new File([blob], `${convertToDate()}.webm`, {
        type: "video/webm",
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setFileOfBlob(fileOfBlob);
    }
  }, [recordedChunks]);

  return (
    <div className="video_recorder_wrapper">
      <div className="video_recorder_modal">
        <div className={`video_recorder_header ${saved ? "hide_header" : ""}`}>
          <h3>Record a video</h3>
          <p onClick={handleRecorder}>X</p>
        </div>
        <div className="video_recorder_body">
          <VideoPreview
            webcamRef={webcamRef}
            videoConstraints={videoConstraints}
            facingMode={facingMode}
            url={url}
            handleStartCapture={handleStartCapture}
            handleStopCapture={handleStopCapture}
            capturing={capturing}
            setCapturing={setCapturing}
            timeRef={timeRef}
            handleClick={handleClick}
            isMobile={isMobile}
          />
          <VideoDisplay
            url={url}
            setUrl={setUrl}
            handleRecorder={handleRecorder}
            setInputs={setInputs}
            fileOfBlob={fileOfBlob}
            timeRef={timeRef}
            saved={saved}
            setSaved={setSaved}
          />
          {/* <button onClick={handleClick}>Switch</button>
          {recordedChunks.length > 0 && (
            <button onClick={handleDownload}>Download</button>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default VideoRecorder;

function VideoPreview({
  webcamRef,
  videoConstraints,
  facingMode,
  url,
  handleStartCapture,
  handleStopCapture,
  capturing,
  setCapturing,
  timeRef,
  handleClick,
  isMobile,
}: any) {
  return (
    <div
      className="webcam_container"
      style={{ display: !url ? "block" : "none" }}
    >
      <div className="webcam_video_timer">
        {capturing && (
          <Timer
            setCapturing={setCapturing}
            handleStopCaptureClick={handleStopCapture}
            timeRef={timeRef}
          />
        )}
      </div>
      <div className="webcam_video_before_capture">
        <Webcam
          ref={webcamRef}
          videoConstraints={{
            ...videoConstraints,
            facingMode,
          }}
          audio={true}
          muted={true}
        />
      </div>
      <div
        className="webcam_video_before_capture_controls"
        style={{ display: !capturing ? "flex" : "none" }}
      >
        <div className="media_choices">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <button className="webcam_btn" onClick={handleStartCapture}>
          <div></div>
        </button>
        <button
          className="switch_btn"
          onClick={handleClick}
          style={{ display: !isMobile ? "none" : "block" }}
        ></button>
      </div>
      <div
        className="webcam_video_before_capture_controls"
        style={{ display: capturing ? "flex" : "none" }}
      >
        <button className="webcam_stop_btn" onClick={handleStopCapture}>
          <div></div>
        </button>
      </div>
    </div>
  );
}

function VideoDisplay({
  url,
  setUrl,
  handleRecorder,
  setInputs,
  fileOfBlob,
  timeRef,
  saved,
  setSaved,
}: any) {
  const [paused, setPaused] = useState(true);
  const [currentTime, setCurrentTime] = useState("00:00");
  // const barRef = useRef<any>();
  // const bar2Ref = useRef<any>();

  const handleVideoControls = (e: any) => {
    const video = e.target.previousSibling;
    video.paused ? video.play() : video.pause();
  };

  return (
    <div
      className="webcam_container"
      style={{ display: url ? "block" : "none" }}
    >
      <div className="webcam_video_display">
        <video
          src={url}
          onPlaying={(e: any) => {
            setPaused(false);
          }}
          onPause={(e: any) => setPaused(true)}
          onTimeUpdate={(e: any) => {
            const currentTime = e.target.currentTime;
            const time = Math.trunc(currentTime);
            time === 60
              ? setCurrentTime("01:00")
              : setCurrentTime(`00:${time < 10 ? `0${time}` : time}`);
            // const duration = 10 - timeRef.current;
            // const width = (barRef.current.clientWidth * currentTime) / duration;
            // bar2Ref.current.style.width = `${width}px`;
          }}
        ></video>
        <div
          onClick={handleVideoControls}
          style={{ opacity: paused && !saved ? ".9" : "0", zIndex: 1 }}
        ></div>
        <div style={{ opacity: saved ? ".9" : "0" }}></div>
      </div>
      <div
        className="video_duration"
        style={{ height: paused || saved ? 0 : 35 }}
      >
        {/* <div ref={barRef}>
          <div ref={bar2Ref}></div>
        </div> */}
        <div className="video_duration_time">
          <span>00:00</span>
          <span>{currentTime}</span>
        </div>
      </div>
      <div className={`webcam_video_display_controls ${saved ? "hide" : ""}`}>
        <button
          onClick={(e) => {
            e.preventDefault();
            setSaved(true);
            setPaused(true);
            setTimeout(() => {
              handleRecorder();
              setInputs((prevState: any) => ({
                ...prevState,
                medias: fileOfBlob,
              }));
            }, 1000);
          }}
        >
          Save video
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setUrl("");
          }}
        >
          Retake video
        </button>
      </div>
    </div>
  );
}
