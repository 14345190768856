import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getChallengeSubmissions } from "../../../expertSlice";
import OverdueChallenge from "app/features/experts/experts_landing_page/overdue_challenge/OverdueChallenge";

const OverdueChallengesTab = ({ setOpen, setVideo, setUpload }: any) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const overdue_challenges = useSelector(
    (state: RootState) => state.expert.overdue_challenges
  );

  async function response() {
    await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
  }, []);

  return (
    <div className="items">
      {overdue_challenges.map((v: any, i: any) => {
        return (
          <OverdueChallenge
            v={v}
            key={i}
            setOpen={setOpen}
            setVideo={setVideo}
            setUpload={setUpload}
          />
        );
      })}
    </div>
  );
};

export default OverdueChallengesTab;
