import { useState, useRef, useEffect } from "react";
import "./Timer.scss";

const Timer = ({ setCapturing, handleStopCaptureClick, timeRef }: any) => {
  // const Ref = useRef(null);
  // const time = 10; //should be 60
  // const [timer, setTimer] = useState("0");

  // const getTimeRemaining = (e: any) => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   const total = Date.parse(e) - Date.parse(new Date());
  //   const seconds = Math.floor((total / 1000) % 60);
  //   return { total, seconds };
  // };

  // const startTimer = (e: any) => {
  //   const { total, seconds } = getTimeRemaining(e);
  //   if (total >= 0) {
  //     setTimer("" + (seconds > 9 ? seconds : seconds));
  //     timeRef.current = seconds;
  //   } else {
  //     setCapturing(false);
  //     handleStopCaptureClick();
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     if (Ref.current) clearInterval(Ref.current);
  //   }
  // };

  // const clearTimer = (e: any) => {
  //   setTimer(time.toString());
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   if (Ref.current) clearInterval(Ref.current);
  //   const id = setInterval(() => {
  //     startTimer(e);
  //   }, 1000);
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   Ref.current = id;
  // };

  // const getDeadTime = () => {
  //   const deadline = new Date();
  //   deadline.setSeconds(deadline.getSeconds() + time);
  //   return deadline;
  // };

  // useEffect(() => {
  //   clearTimer(getDeadTime());
  //   return () => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     if (Ref?.current) clearInterval(Ref?.current);
  //   };
  // }, []);

  // return (
  //   <div className="video_timer">
  //     <h2>{timer}</h2>
  //     <span> second{`${timer === "1" ? "" : "s"}`}</span>
  //   </div>
  // );

  const limit = 120;
  const [timer, setTimer] = useState(0);

  const timeParser = (time: any) => {
    const totalSeconds = time;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    const padTo2Digits = (num: any) => {
      return num.toString().padStart(2, "0");
    };

    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  };

  useEffect(() => {
    if (timer < limit) {
      setTimeout(() => setTimer(timer + 1), 1000);
    } else {
      handleStopCaptureClick();
    }
  }, [timer]);

  return (
    <div className="video_timer">
      <h2>{timeParser(timer)}</h2>
    </div>
  );
};

export default Timer;
