import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IoIosNotificationsOutline } from "react-icons/io";
import avatar from "../../../../../assets/avatar.png";
import "./NavItem.scss";

const NavItem = ({ url, children }: any) => {
  return (
    <li>
      <Link to={url}>
        <button className={url === "/signin" ? "session_btn" : ""}>
          {children}
        </button>
      </Link>
    </li>
  );
};

export default NavItem;

export function LogoutBtn() {
  const navigate = useNavigate();

  function handleLogout() {
    navigate("/logout");
  }

  return (
    <li>
      <button className="session_btn" onClick={handleLogout}>
        Logout
      </button>
    </li>
  );
}

export function NotificationBtn() {
  return (
    <div className="notification_bell">
      <IoIosNotificationsOutline />
      <div className="dot"></div>
    </div>
  );
}

export function MobileNotificationBtn() {
  return (
    <div className="mobile_notification_bell">
      <IoIosNotificationsOutline />
      <div className="dot"></div>
    </div>
  );
}

export function SettingsBtn() {
  return <div className="settings_btn"></div>;
}

export const Avatar = ({ url }: any) => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  return (
    <Link to={url}>
      <div className="nav_avatar" title="Open Profile">
        <img src={!currentUser?.avatar ? avatar : currentUser?.avatar} />
      </div>
    </Link>
  );
};
