import React from "react";
import { TextField } from "@mui/material";

const Feedback = ({ inputs, handleChange }: any) => {
  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="enter feedback"
        multiline
        rows={5}
        sx={{ width: "50%" }}
        name="content"
        value={inputs.content}
        onChange={handleChange}
      />
    </div>
  );
};

export default Feedback;
