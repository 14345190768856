import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { RootState } from "../../../store";
import { refreshAccessToken } from "../sessionSlice";
import Loading from "../../tools/Loading";

function PersistLogin() {
  const loading = useSelector((state: RootState) => state.session.loading);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const refreshToken = useSelector(
    (state: RootState) => state.session.refreshToken
  );
  const dispatch = useDispatch();

  useEffect(() => {
    function verifyRefreshToken() {
      try {
        dispatch(refreshAccessToken(refreshToken));
      } catch (error) {
        console.log(error);
      }
    }
    if (!accessToken) {
      verifyRefreshToken();
    }
  }, [accessToken, refreshToken]);

  return <>{loading ? <Loading /> : <Outlet />}</>;
}

export default PersistLogin;
