import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { readExperts, readExpertsPublic } from "../../api/expertAPI";
//Can I move this to expertAPI
import {
  createRequestFeedback,
  readRequestFeedbacks,
  readIndividualFeedbackRequests,
  acceptSubmissionRequest,
  declineSubmissionRequest,
  createOneToOneFeedbackSubmission,
  createChallengeSubmissionFeedback,
  readChallengeSubmissions,
} from "../../api/submissionAPI";
//Can I move this to expertAPI
import {
  requestAnsweredPartition,
  requestPartition,
  partition,
  challengePartition,
} from "./expert_tools";

interface ExpertState {
  loading: boolean;
  error: boolean;
  errorMessages: string[];
  experts?: any;
  one_to_one_requests?: any;
  pending?: any;
  answered?: any;
  ongoing_requests?: any;
  overdue_requests?: any;
  challenge_submissions?: any;
  unjudged?: any;
  judged?: any;
  ongoing_challenges?: any;
  overdue_challenges?: any;
  accepted?: boolean;
}

export interface ChallengeSubmissionFeedback {
  content: string;
  vidoe: any;
  submission_id: number;
  user_id: any;
  expert_id: any;
  criteria: any;
}

interface challengeSubmissionFeedbackData {
  token: string | undefined;
  challengeSubmissionFeedback: ChallengeSubmissionFeedback | any;
}

export interface OneToOneFeedbackSubmission {
  description: string;
  medias: any;
  id: number;
  submission_id?: number;
}

interface oneToOneFeedbackSubmissionData {
  token: string | undefined;
  oneToOneFeedbackSubmission: OneToOneFeedbackSubmission | any;
}

interface requestFeedbackData {
  token: string | undefined;
  request_feedback: any;
}

interface requestsData {
  token: string | undefined;
  id: any;
}

const initialState: ExpertState = {
  loading: false,
  error: false,
  errorMessages: [],
  one_to_one_requests: [],
  pending: [],
  answered: [],
  ongoing_requests: [],
  overdue_requests: [],
  challenge_submissions: [],
  unjudged: [],
  judged: [],
  ongoing_challenges: [],
  overdue_challenges: [],
};

export const getExperts = createAsyncThunk(
  "session/getExperts",
  async (payload: string | undefined, { rejectWithValue }) => {
    const response = await readExperts(payload);

    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getExpertsPublic = createAsyncThunk(
  "session/getExpertsPublic",
  async (payload: string | undefined, { rejectWithValue }) => {
    const response = await readExpertsPublic();

    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getRequestFeedbacks = createAsyncThunk(
  "session/getRequestFeedbacks",
  async (payload: requestsData, { rejectWithValue }) => {
    const response = await readRequestFeedbacks(payload.token, payload.id);

    if (response.error) {
      return rejectWithValue(response.data);
    }
    return response;
  }
);

export const getIndividualFeedbackRequests = createAsyncThunk(
  "session/getIndividualFeedbackRequests",
  async (payload: requestsData, { rejectWithValue }) => {
    const response = await readIndividualFeedbackRequests(
      payload.token,
      payload.id
    );

    if (response.error) {
      return rejectWithValue(response.data);
    }
    return response;
  }
);

export const acceptRequest = createAsyncThunk(
  "session/acceptRequest",
  async (payload: requestsData, { rejectWithValue }) => {
    const response = await acceptSubmissionRequest(payload.token, payload.id);

    if (response.error) {
      return rejectWithValue(response.data);
    }
    return response;
  }
);

export const declineRequest = createAsyncThunk(
  "session/declineRequest",
  async (payload: requestsData, { rejectWithValue }) => {
    const response = await declineSubmissionRequest(payload.token, payload.id);

    if (response.error) {
      return rejectWithValue(response.data);
    }
    return response;
  }
);

export const submitRequestFeedback = createAsyncThunk(
  "session/submitRequestFeedback",
  async (payload: requestFeedbackData, { rejectWithValue }) => {
    const response = await createRequestFeedback(
      payload.token,
      payload.request_feedback
    );

    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const submitOneToOneFeedback = createAsyncThunk(
  "session/submitOneToOneFeedback",
  async (payload: oneToOneFeedbackSubmissionData, { rejectWithValue }) => {
    const response = await createOneToOneFeedbackSubmission(
      payload.token,
      payload.oneToOneFeedbackSubmission
    );

    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const submitChallengeSubmissionFeedback = createAsyncThunk(
  "session/submitChallengeSubmissionFeedback",
  async (payload: challengeSubmissionFeedbackData, { rejectWithValue }) => {
    const response = await createChallengeSubmissionFeedback(
      payload.token,
      payload.challengeSubmissionFeedback
    );

    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getChallengeSubmissions = createAsyncThunk(
  "session/getChallengeSubmissions",
  async (token: string | undefined, { rejectWithValue }) => {
    const response = await readChallengeSubmissions(token);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExperts.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getExperts.fulfilled, (state, action: any) => {
        state.experts = action.payload;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getExperts.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(getExpertsPublic.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getExpertsPublic.fulfilled, (state, action: any) => {
        state.experts = action.payload;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getExpertsPublic.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(getRequestFeedbacks.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getRequestFeedbacks.fulfilled, (state, action: any) => {
        const [pending, answered] = requestAnsweredPartition(
          action.payload.requests
        );
        const [ongingRequests, overdueRequests] = requestPartition(pending);

        state.one_to_one_requests = action.payload.requests;
        state.pending = pending;
        state.answered = answered;
        state.ongoing_requests = ongingRequests;
        state.overdue_requests = overdueRequests;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getRequestFeedbacks.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(getIndividualFeedbackRequests.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(
        getIndividualFeedbackRequests.fulfilled,
        (state, action: any) => {
          const [pending, answered] = requestAnsweredPartition(
            action.payload.requests
          );
          const [ongingRequests, overdueRequests] = requestPartition(pending);

          state.one_to_one_requests = action.payload.requests;
          state.pending = pending;
          state.answered = answered;
          state.ongoing_requests = ongingRequests;
          state.overdue_requests = overdueRequests;
          state.loading = false;
          state.error = false;
          state.errorMessages = [];
        }
      )
      .addCase(getIndividualFeedbackRequests.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(acceptRequest.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(acceptRequest.fulfilled, (state, action: any) => {
        state.accepted = action.payload.accepted;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(acceptRequest.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(declineRequest.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(declineRequest.fulfilled, (state, action: any) => {
        state.one_to_one_requests = action.payload.requests;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(declineRequest.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(submitOneToOneFeedback.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitOneToOneFeedback.fulfilled, (state, action: any) => {
        const [pending, answered] = requestAnsweredPartition(
          action.payload.requests
        );
        const [ongingRequests, overdueRequests] = requestPartition(pending);
        state.one_to_one_requests = action.payload.requests;
        state.pending = pending;
        state.answered = answered;
        state.ongoing_requests = ongingRequests;
        state.overdue_requests = overdueRequests;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(submitOneToOneFeedback.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(submitChallengeSubmissionFeedback.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(
        submitChallengeSubmissionFeedback.fulfilled,
        (state, action: any) => {
          const submissions = action.payload.submissions.flat();
          const [unjudged, judged] = partition(submissions);
          const [ongoingChallenges, overdueChallenges] =
            challengePartition(unjudged);

          state.challenge_submissions = submissions;
          state.unjudged = unjudged;
          state.judged = judged;
          state.ongoing_challenges = ongoingChallenges;
          state.overdue_challenges = overdueChallenges;
          state.loading = false;
          state.error = false;
          state.errorMessages = [];
        }
      )
      .addCase(
        submitChallengeSubmissionFeedback.rejected,
        (state, action: any) => {
          state.loading = false;
          state.error = true;
          state.errorMessages = action.payload.errors;
        }
      )
      .addCase(getChallengeSubmissions.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallengeSubmissions.fulfilled, (state, action: any) => {
        const submissions = action.payload.submissions.flat();
        const [unjudged, judged] = partition(submissions);
        const [ongoingChallenges, overdueChallenges] =
          challengePartition(unjudged);

        state.challenge_submissions = submissions;
        state.unjudged = unjudged;
        state.judged = judged;
        state.ongoing_challenges = ongoingChallenges;
        state.overdue_challenges = overdueChallenges;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallengeSubmissions.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      });
  },
});

//
// export const { setJudges } = judgeSlice.actions;

//
export default expertSlice.reducer;
