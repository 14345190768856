import { useState } from "react";
import "./CreatorItem.scss";

const CreatorItem = ({ media, description, children, type }: any) => {
  const [paused, setPaused] = useState(true);
  const [info, setInfo] = useState(false);

  const handleVideoControls = (e: any) => {
    const video = e.target.previousSibling;
    video.paused ? video.play() : video.pause();
  };

  // IMPORTANT
  // THIS IS FOR THE OVERDUE INFO MODAL.  I SHUT IT OFF FOR NOW
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  const toggleInfoModal = (e: any) => {
    setInfo((prev) => !prev);
  };
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////

  function start_and_end(str: any) {
    if (str.length > 20) {
      return (
        str.substr(0, 10) + "..." + str.substr(str.length - 10, str.length)
      );
    }
    return str;
  }

  return (
    <div className="creator_item">
      <div className="creator_item_video">
        {children}
        {type?.includes("video") ? (
          <Video
            media={media}
            setPaused={setPaused}
            paused={paused}
            handleVideoControls={handleVideoControls}
          />
        ) : (
          <Image media={media} />
        )}
      </div>
      <p className="creator_item_title">{start_and_end(description)}</p>
    </div>
  );
};

export default CreatorItem;

function Video({ media, setPaused, paused, handleVideoControls }: any) {
  return (
    <>
      <video
        src={media}
        onPlaying={(e: any) => {
          setPaused(false);
        }}
        onPause={(e: any) => setPaused(true)}
      ></video>
      <div
        className="creator_item_cover"
        onClick={handleVideoControls}
        style={{ opacity: paused ? ".9" : "0", zIndex: 1 }}
      >
        {/* <div
            className="overdue_info_modal"
            style={{ display: info ? "block" : "none" }}
          >
            Since the expert did not accept your request,{" "}
            <u>select another judge</u> or <u>get a refund.</u>
          </div>
          <span onClick={toggleInfoModal}></span> */}
      </div>
    </>
  );
}

function Image({ media }: any) {
  return <img src={media} />;
}
