import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import challengeReducer from "./features/challenges/challengeSlice";
import profileReducer from "./features/profile/profileSlice";
import sessionReducer from "./features/sessions/sessionSlice";
import usersReducer from "./features/admin/content/users/usersSlice";
import submissionSlice from "./features/submissions/submissionSlice";
import expertSlice from "./features/experts/expertSlice";

export const store = configureStore({
  reducer: {
    challenge: challengeReducer,
    profile: profileReducer,
    session: sessionReducer,
    users: usersReducer,
    submission: submissionSlice,
    expert: expertSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
