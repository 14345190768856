import { Routes, Route } from "react-router-dom";
import PrivateRoute from "app/features/routes/PrivateRoute";
import PublicOnlyRoute from "app/features/routes/PublicOnlyRoute";
import JudgeForm from "app/features/experts/approved/judge_from/JudgeForm";
import JudgeOneToOne from "app/features/experts/approved/judge_one_to_one/JudgeOneToOne";
import ExpertsTabs from "app/features/experts/approved/experts_tabs/ExpertsTabs";
import Profile from "app/features/profile/Profile";

import ProfileExpertTab from "../profile/profle_content/profile_expert_tab/ProfileExpertTab";
import ExpertStatus from "./expert_status/ExpertStatus";

const ExpertsRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <PrivateRoute>
            <ExpertStatus />
          </PrivateRoute>
        }
      />
      <Route
        path={"judging"} //MAY NOT BE USING THIS ROUTE ANYMORE
        element={
          <PrivateRoute>
            <JudgeForm />
          </PrivateRoute>
        }
      />
      <Route
        path={"judge_one_to_one"}
        element={
          <PrivateRoute>
            <JudgeOneToOne />
          </PrivateRoute>
        }
      />
      {/* <Route
        path={"experts_list"}
        element={
          <PrivateRoute>
            <Experts />
          </PrivateRoute>
        }
      /> */}
      <Route
        path={"experts_tabs/*"}
        element={
          <PrivateRoute>
            <ExpertsTabs />
          </PrivateRoute>
        }
      />
      {/* <Route
        path={"experts_request_feedback"}
        element={
          <PrivateRoute>
            <ExpertsRequestFeedback />
          </PrivateRoute>
        }
      /> */}
      {/* <Route
        path={"expert_pay"}
        element={
          <PrivateRoute>
            <ExpertsPayment />
          </PrivateRoute>
        }
      /> */}
      <Route
        path={"expert_profile"}
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path={"experts_profile_tab/*"}
        element={
          <PrivateRoute>
            <ProfileExpertTab />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ExpertsRoutes;
