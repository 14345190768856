import "./GeneralChallenges.scss";

const GeneralChallenges = () => {
  return (
    <div className="general_challenges">
      <h1>GeneralChallenges</h1>
    </div>
  );
};

export default GeneralChallenges;
