import Rate from "./Rate";
import Feedback from "./Feedback";
import { RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { Key, useEffect, useState } from "react";
import { submitScore, Score } from "../sessions/sessionSlice";
import { useNavigate } from "react-router-dom";

const Criteria = ({ criteria, id, feedback }: any) => {
  // const [score, setScore] = useState<Array<number>>([]);
  // const [total, setTotal] = useState(0);
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const reducer = (accumulator: any, curr: any) => accumulator + curr;
  const obj = {};
  criteria.map((v: { name: string; id: number }, i: any) => {
    return ((obj as any)[v.name] = {
      criterium_id: v.id,
      submission_id: id,
      user_id: currentUser?.id,
      value: 0,
    });
  });
  const [criteriaObj, setCriteriaObj] = useState(obj);

  const initialState: Score = {
    content: "",
    criteria: criteriaObj,
    user_id: currentUser?.id,
    submission_id: id,
  };

  const handleList = (i: any, value: any, name: string) => {
    // const values = [...score];
    // values[i] = value;
    setCriteriaObj((prev) => {
      const criterium = { ...(prev as any)[name], value: value };
      return { ...prev, [name]: criterium };
    });
    // setScore(values);
  };

  const [inputs, setInputs] = useState<Score>(initialState);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let flag = true;
    const criteria = Object.values(criteriaObj);
    criteria.forEach((v: any, i: any) => {
      if (v.value === 0) flag = false;
    });
    inputs.criteria = criteria;
    if (!flag || inputs.content === "") return; //need to set errors to let user know a field need to be filled
    const res = await dispatch(
      submitScore({ token: accessToken, score: inputs })
    );

    //TODO
    //Route to judge landing page -- set up error check before routing
    // navigate("/experts", { replace: true });
  };

  //WILL NOT NEED THIS
  // useEffect(() => {
  //   if (score.length > 0) setTotal(score.reduce(reducer));
  // }, [score]);

  return (
    <div>
      <h3>Criteria</h3>
      {/* <div>
        Total: {total}
      </div> */}
      {criteria?.map((v: { name: string }, i: Key | null | undefined) => {
        return (
          <div key={i}>
            <Rate name={v.name} handleList={handleList} i={i} />
          </div>
        );
      })}
      <form onSubmit={handleSubmit}>
        <Feedback inputs={inputs} handleChange={handleChange} />
        <button>Submit</button>
      </form>
    </div>
  );
};

export default Criteria;
