import "./InputField.scss";

const InputField = ({
  value,
  name,
  title,
  type,
  ph,
  handleChange,
  req,
}: any) => {
  // const capitalize = (word: string) => {
  //   return word[0].toUpperCase() + word.slice(1);
  // };
  return (
    <div className="input_field">
      <div className="input_field_name">
        <span>
          {title} {req && <sup>*</sup>}
        </span>
      </div>
      <div className="input_field_value">
        <input
          type={type}
          value={value}
          name={name}
          placeholder={ph}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default InputField;
