import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ScrollToTop from "app/features/tools/ScrolTolTop";
import PrivateRoute from "app/features/routes/PrivateRoute";
import PublicOnlyRoute from "app/features/routes/PublicOnlyRoute";
import SignIn from "app/features/sessions/sign_in/SignIn";
import Logout from "app/features/sessions/logout/Logout";
import PersistSignIn from "app/features/sessions/sign_in/PersistSignIn";
import ForgotPassword from "app/features/sessions/sign_in/forgot_password/ForgotPassword";
import EmailSent from "app/features/sessions/sign_in/forgot_password/email_sent/EmailSent";
// import ResetPassword from "app/features/sessions/sign_in/forgot_password/reset_password/ResetPassword";
import ResetPassword from "app/features/sessions/sign_in/reset_password/ResetPassword";
import ResetPasswordSuccess from "app/features/sessions/sign_in/forgot_password/reset_password_success/ResetPasswordSuccess";
import CreatorSignUp from "app/features/sessions/sign_up/creator_sign_up/CreatorSignUp";
import CreatorSignUpFinal from "app/features/sessions/sign_up/creator_sign_up/CreatorSignUpFinal";
import Admin from "app/features/admin/Admin";
import Submission from "./app/features/submissions/Submission";
import ChallengesRoutes from "app/features/challenges/ChallengesRoutes";
import ExpertsRoutes from "app/features/experts/ExpertsRoutes";
import HeaderRoutes from "app/features/header/HeaderRoutes";

import CreatorRoutes from "app/features/creator/CreatorRoutes";
import Home from "app/features/home/Home";
import ExpertsSignup from "app/features/sessions/sign_up/expert_sign_up/ExpertsSignup";
import ExpertsSignupFinal from "app/features/sessions/sign_up/expert_sign_up/ExpertsSignupFinal";
import GeneralChallenges from "app/features/challenges/general_challenges/GeneralChallenges";

import Footer from "./app/features/footer/Footer";

import "./App.scss";
import ExpertsPublic from "app/features/experts/experts_public/ExpertsPublic";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <HeaderRoutes />
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route element={<PersistSignIn />}>
              {/* REFACTOR ADMIN TO LOOK THE SAME WAY AS CREATOR AND EXPERTS */}
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute>
                    <Admin />
                  </PrivateRoute>
                }
              />
              <Route path="/creator/*" element={<CreatorRoutes />} />
              <Route path="/expert/:slug/*" element={<ExpertsRoutes />} />
              {/* NEED TO PUT :slug FOR CHALLENGES ROUTE */}
              <Route path="/challenges/*" element={<ChallengesRoutes />} />
              <Route
                path="/submission/*"
                element={
                  <PrivateRoute>
                    <Submission />
                  </PrivateRoute>
                }
              />
              <Route path="/challenges" element={<GeneralChallenges />} />
              <Route
                path="/logout"
                element={
                  <PrivateRoute>
                    <Logout />
                  </PrivateRoute>
                }
              />
              <Route
                path="/experts"
                element={
                  <PublicOnlyRoute>
                    <ExpertsPublic />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path={"/expert_signup_start"}
                element={
                  <PublicOnlyRoute>
                    <ExpertsSignup />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path={"/expert_signup_final"}
                element={
                  <PublicOnlyRoute>
                    <ExpertsSignupFinal />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/signin"
                element={
                  <PublicOnlyRoute>
                    <SignIn />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <PublicOnlyRoute>
                    <CreatorSignUp />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/signup_final"
                element={
                  <PublicOnlyRoute>
                    <CreatorSignUpFinal />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/forgot_password"
                element={
                  <PublicOnlyRoute>
                    <ForgotPassword />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/reset_password/:token"
                element={
                  <PublicOnlyRoute>
                    <ResetPassword />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/email_sent"
                element={
                  <PublicOnlyRoute>
                    <EmailSent />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/reset_password"
                element={
                  <PublicOnlyRoute>
                    <ResetPassword />
                  </PublicOnlyRoute>
                }
              />
              <Route
                path="/reset_password_success"
                element={
                  <PublicOnlyRoute>
                    <ResetPasswordSuccess />
                  </PublicOnlyRoute>
                }
              />
            </Route>
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
