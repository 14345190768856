import { useState } from "react";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { destroySubmission } from "../submissionSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";
import "./SubmissionView.scss";

const SubmissionView = () => {
  const [id, setId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const location = useLocation();
  const { state } = location as any;

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = async (e: any) => {
    setId(e.target.id);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
  };

  const destroy = async () => {
    setShow(false);
    const submission = { token: accessToken, submission: Number(id) };
    const res = (await dispatch(destroySubmission(submission))) as any;
    if (res.payload.msg) {
      navigate("/feed");
    } else {
      console.log("what to do with the errors");
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="submission_view_container">
      <div className="submission_view">
        <div className="submission_view_header">
          <button onClick={goBack}>Back</button>
          <button
            style={{ position: "relative" }}
            className="submssion_view_btn"
          >
            <DeleteIcon />
            <div
              className="submssion_view_btn_cover"
              onClick={handleDelete}
              id={state.submission.id}
            ></div>
          </button>
        </div>
        <div className="submission_view_body">
          <video src={state.media} controls></video>
        </div>
        <div className="submissions_view_footer">
          {state.submission.description}
        </div>
      </div>
      <div
        className="submission_modal_container"
        style={{ display: show ? "flex" : "none" }}
      >
        <div className="submission_modal">
          <button className="close" onClick={closeModal}>
            ✖
          </button>
          <p>Are you sure you want to delete this submission</p>
          <button className="delete" onClick={destroy}>
            Delete
          </button>
          <button className="cancel" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmissionView;
