export function convertToDate() {
  const date = new Date();
  return `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
}

export function create_slug(name) {
  return name
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

/******************* FORM VALITATION CODE *****************/
/**********************************************************/

export const validate = (errors) => {
  let pass = true;
  Object.values(errors).forEach((value) => {
    value.length > 0 && (pass = false);
  });

  return pass;
};

export const validateErrors = (errors, name, value, confirm) => {
  const reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
  const PASSWORD_MSG =
    "Must be at least 8 characters, have at least 1 lowercase, uppercase, and special character";

  switch (name) {
    case "age":
      if (value === "") {
        errors.age = "*";
      } else if (isNaN(Number(value))) {
        errors.age = "*";
      } else {
        errors.age = "";
      }
      break;

    case "email":
      if (value === "") {
        errors.email = "*";
      } else if (!/^\S+@\S+\.[a-zA-Z]{2,4}$/.test(value)) {
        errors.email = "*";
      } else {
        errors.email = "";
      }
      break;

    case "interests":
      if (value === "") {
        errors.interests = "*";
      } else {
        errors.interests = "";
      }
      break;

    case "password":
      if (value === "") {
        errors.password = "*";
      } else {
        errors.password = "";
      }
      break;

    case "confirm_password":
      if (value === "") {
        errors.confirm_password = "*";
      } else if (value === confirm) {
        errors.confirm_password = "";
      } else {
        errors.confirm_password = "*";
      }
      break;

    //   else if (!reg.test(value)) {
    //     errors.password = PASSWORD_MSG;
    //   }

    case "name":
      if (value === "") {
        errors.name = "*";
      } else {
        errors.name = "";
      }
      break;

    case "official_title":
      if (value === "") {
        errors.official_title = "*";
      } else {
        errors.official_title = "";
      }
      break;

    case "specialization":
      if (value === "") {
        errors.specialization = "*";
      } else {
        errors.specialization = "";
      }
      break;

    case "personal_website":
      if (value === "") {
        errors.personal_website = "*";
      } else {
        errors.personal_website = "";
      }
      break;

    case "company_website":
      if (value === "") {
        errors.company_website = "*";
      } else {
        errors.company_website = "";
      }
      break;

    case "linkedin_url":
      if (value === "") {
        errors.linkedin_url = "*";
      } else {
        errors.linkedin_url = "";
      }
      break;

    default:
      break;
  }
};

/**********************************************************/
/******************* FORM VALITATION CODE *****************/
