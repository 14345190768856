import "./ExpertPending.scss";

const ExpertPending = () => {
  return (
    <div className="expert_pending">
      <div className="header"></div>
      <div className="body">
        <h1>
          Your request to became an expert for Studious is currently pending.
          You will be notified if you are approved.
        </h1>
      </div>
    </div>
  );
};

export default ExpertPending;
