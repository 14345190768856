import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import "./ExpertsTabs.scss";

import CurrentChallengesTab from "./expert_tab/CurrentChallengesTab";
import CurrentRequestTab from "./expert_tab/CurrentRequestTab";
import OverdueChallengesTab from "./expert_tab/OverdueChallengesTab";
import OverdueRequestTab from "./expert_tab/OverdueRequestTab";

import ExpertOneToOneFeedback from "../expert_one_to_one_feedback/ExpertOneToOneFeedback";
import ExpertSubmissionViewer from "../../expert_submission_viewer/ExpertSubmissionViewer";
import ExpertSubmissionSuccess from "../../experts_landing_page/expert_submission_success/ExpertSubmissionSuccess";

import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";

const ExpertsTabs = () => {
  const [title, setTitle] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [video, setVideo] = useState();
  const [upload, setUpload] = useState({});
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const { pathname } = location as any;
  const name = pathname.slice(pathname.lastIndexOf("/") + 1);

  const tab = {
    overdue_request: "Overdue 1-1 feedback requests",
    overdue_challenges: "Overdue challenges",
    current_request: "Current 1-1 feedback requests",
    current_challenges: "Current challenges",
  } as any;

  const paths = {
    "Overdue 1-1 feedback requests": "overdue_request",
    "Overdue challenges": "overdue_challenges",
    "Current 1-1 feedback requests": "current_request",
    "Current challenges": "current_challenges",
  } as any;

  const navigate = useNavigate();

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const handleNavigation = () => {
    navigate(`/expert/${slug}`);
  };

  const handleToggle = (e: any) => {
    if (!e.target.id) return;
    setTitle(e.target.id);
    navigate(`${paths[e.target.id]}`);
  };

  useEffect(() => setTitle(tab[name]), []);

  return (
    <div className="experts_tabs">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>{title}</h3>
          <p>See a dashboard of all your requests and challenges</p>
        </div>
      </div>
      <div className="body">
        <TabContainer title={title} handleToggle={handleToggle} />
        <Routes>
          <Route
            path={"overdue_request"}
            element={
              <OverdueRequestTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
          <Route
            path={"overdue_challenges"}
            element={
              <OverdueChallengesTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
          <Route
            path={"current_request"}
            element={
              <CurrentRequestTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
          <Route
            path={"current_challenges"}
            element={
              <CurrentChallengesTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
        </Routes>
      </div>
      <div
        className="expert_media_container"
        style={{ display: open ? "flex" : "none" }}
      >
        <div className="modal_container">
          <ExpertSubmissionViewer
            open={open}
            media={video}
            setOpen={setOpen}
            setShow={setShow}
          />
        </div>
      </div>
      <div
        className="expert_media_container"
        style={{ display: show ? "flex" : "none" }}
      >
        <div className="modal_container">
          <ExpertOneToOneFeedback
            upload={upload}
            setOpen={setOpen}
            setShow={setShow}
            setSuccess={setSuccess}
          />
        </div>
      </div>
      <ExpertSubmissionSuccess success={success} setSuccess={setSuccess} />
    </div>
  );
};

export default ExpertsTabs;

function TabContainer({ title, handleToggle }: any) {
  return (
    <ul onClick={handleToggle}>
      <Tab title={title} name={"Overdue 1-1 feedback requests"} />
      {/* <Tab title={title} name={"Overdue challenges"} /> */}
      <Tab title={title} name={"Current 1-1 feedback requests"} />
      {/* <Tab title={title} name={"Current challenges"} /> */}
    </ul>
  );
}

function Tab({ title, name }: any) {
  const checkTitle = (title: any, selection: any) => {
    return title === selection;
  };

  return (
    <li id={name} className={checkTitle(title, name) ? "selected" : ""}>
      {name}
    </li>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
