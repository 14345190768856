import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FileUpload from "app/features/media/file_upload/FileUpload";
import VideoRecorder from "app/features/media/recorder/VideoRecorder";
import { FileTable } from "../../expert_request_feedback_form/ExpertRequestFeedbackForm";
import browse from "assets/images/experts/browse.png";
import video_icon from "assets/images/experts/video.png";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  OneToOneFeedbackSubmission,
  submitOneToOneFeedback,
  submitChallengeSubmissionFeedback,
} from "../../expertSlice";
import "./ExpertOneToOneFeedback.scss";
import Loading from "app/features/tools/Loading";
import { BiLoader } from "react-icons/bi";

const ExpertOneToOneFeedback = ({ setSuccess, setShow, upload }: any) => {
  // const initialState: OneToOneFeedbackSubmission = {
  //   description: "",
  //   medias: "",
  //   id: Number(upload.submission_id),
  // };
  const initialState = {
    description: "",
    medias: "",
    id: upload.submission_id,
  };

  const [word, setWord] = useState(0);
  const [inputs, setInputs] = useState(initialState);
  // const [inputs, setInputs] =
  //   useState<OneToOneFeedbackSubmission>(initialState);
  const [showRecorder, setShowRecorder] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!inputs.medias) return;
    setLoading(true);
    const formData = new FormData();
    if (upload.type === "one_to_one feedback") {
      inputs.id = Number(upload.submission_id);
      for (const prop in inputs) {
        if (prop === "medias") continue;
        formData.append(`one_to_one_feedback[${prop}]`, (inputs as any)[prop]);
      }

      formData.append("one_to_one_feedback[medias][]", inputs.medias);

      const response = (await dispatch(
        submitOneToOneFeedback({
          token: accessToken,
          oneToOneFeedbackSubmission: formData,
        })
      )) as any;
    } else {
      const feedbackInputs = {} as any;
      feedbackInputs["submission_id"] = upload.submission_id;
      feedbackInputs["user_id"] = upload.user_id;
      feedbackInputs["content"] = inputs.description;

      for (const prop in feedbackInputs) {
        formData.append(
          `challenge_submission_feedback[${prop}]`,
          (feedbackInputs as any)[prop]
        );
      }

      formData.append("challenge_submission_feedback[video]", inputs.medias);
      upload.criteria?.map((criterion: any) =>
        formData.append("challenge_submission_feedback[criteria][]", criterion)
      );

      const response = (await dispatch(
        submitChallengeSubmissionFeedback({
          token: accessToken,
          challengeSubmissionFeedback: formData,
        })
      )) as any;
    }

    setShow(false);
    setSuccess(true);
  };

  const handleRemoveFile = (e: any) => {
    setInputs((prevState: any) => ({ ...prevState, medias: "" }));
  };

  const handleRecorder = () => {
    setShowRecorder((prev) => {
      return !prev;
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    const word_list = value.split(" ").filter((v: any, i: any) => v != false);
    setWord(word_list.length);
  };

  const toggleOpen = (e: any) => {
    document.body.style.overflow = "scroll";
    setShow(false);
  };

  return (
    <>
      <div className="expert_one_to_one_feedback">
        <div className="expert_one_to_one_header">
          <h2>Submit feedback</h2>
          <button onClick={toggleOpen}>x</button>
        </div>
        <div className="expert_one_to_one_body">
          {showRecorder && (
            <VideoRecorder
              setInputs={setInputs}
              handleRecorder={handleRecorder}
            />
          )}
          <form onSubmit={handleSubmit}>
            <div
              className="upload_selection"
              style={{ display: !inputs.medias ? "flex" : "none" }}
            >
              <div>
                <img src={browse} />
                <p>Upload a file</p>
                <FileUpload setInputs={setInputs} />
              </div>
              <button>or</button>
              <div>
                <img src={video_icon} />
                <p>
                  You&apos;ll have <b>120 seconds</b> to record your video.
                </p>
                <p onClick={handleRecorder}>Record video</p>
              </div>
            </div>
            <FileTable inputs={inputs} handleRemoveFile={handleRemoveFile} />
            <div className="description_section">
              <span>Description:</span>
              <textarea
                value={inputs.description}
                name="description"
                onChange={handleChange}
                placeholder="Write here"
              ></textarea>
              <span>{word}/1000</span>
            </div>
            <button type="submit" disabled={!inputs.medias ? true : false}>
              {loading ? <BiLoader /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
      {loading && <Loading />}
    </>
  );
};

export default ExpertOneToOneFeedback;
