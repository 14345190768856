import { useEffect, useState } from "react";
import { getIndividualFeedbackRequests } from "../../../experts/expertSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import CreatorItem from "../../creator_item/CreatorItem";
import "./FeedbackTab.scss";

const FeedbackTab = () => {
  const [feedbacks, setFeedbacks] = useState<any[]>([]);
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const dispatch = useDispatch();

  async function response() {
    const request = { token: accessToken, id: currentUser?.id };
    const feedback = (await dispatch(
      getIndividualFeedbackRequests(request)
    )) as any;

    setFeedbacks(feedback.payload.requests);
  }

  useEffect(function () {
    response();
  }, []);
  return (
    <div className="feedback_request_container">
      {feedbacks.map((v: any, i: any) => {
        return (
          <CreatorItem
            key={i}
            media={!v.request_media ? "" : v.request_media}
            description={v.request.description}
            type={v.type}
          />
        );
      })}
    </div>
  );
};

export default FeedbackTab;
