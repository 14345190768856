import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "app/features/routes/PrivateRoute";
import PublicOnlyRoute from "app/features/routes/PublicOnlyRoute";
import Creator from "./Creator";
import Challenges from "app/features/challenges/Challenges";
import Challenge from "app/features/challenges/challenge/Challenge";
import ChallengePaymentMethod from "app/features/challenges/challenge_payment_method/ChallengePaymentMethod";
import ChallengeMediaUpload from "../challenges/challenge_media_upload/ChallengeMediaUpload";
import Pay from "app/features/challenges/challenge_payment_method/pay/Pay";
import Experts from "app/features/experts/Experts";
import ExpertsRequestFeedback from "app/features/experts/experts_request_feedback/ExpertsRequestFeedback";
import ExpertsPayment from "app/features/experts/experts_payment/ExpertsPayment";
import ExpertMediaUpload from "app/features/experts/expert_media_upload/ExpertMediaUpload";
import CreatorProfile from "./creator_profile/CreatorProfile";
import CreatorTabs from "./creator_tabs/CreatorTabs";
import Uploaded from "app/features/submissions/Uploaded";
import { RootState } from "app/store";
import { useSelector } from "react-redux";

const CreatorRoutes = () => {
  const location = useLocation();
  const { search } = location as any;
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );

  return (
    <Routes>
      <Route
        index
        element={
          <PrivateRoute>
            <Creator />
          </PrivateRoute>
        }
      />
      {/* CHALLENGE ROUTES */}
      <Route
        path={"challenges"}
        element={
          <PrivateRoute>
            <Challenges />
          </PrivateRoute>
        }
      />
      <Route
        path={"challenges/:id"}
        element={
          <PrivateRoute>
            <Challenge />
          </PrivateRoute>
        }
      />
      <Route
        path="challenges/:id/payment_method"
        element={
          <PrivateRoute>
            <ChallengePaymentMethod />
          </PrivateRoute>
        }
      />
      {/* THIS WILL BE MOVED - IT IS NOT NEED ANYMORE. SHOULD BE PLACED IN ChallengPaymentMethod*/}
      {/* <Route
        path="challenges/:id/payment_method/pay"
        element={
          <PrivateRoute>
            <Pay />
          </PrivateRoute>
        }
      /> */}
      {/* THIS WILL BE MOVED - IT IS NOT NEED ANYMORE */}
      <Route
        path="challenges/paid/:id"
        element={
          <PrivateRoute>
            <ChallengeMediaUpload />
          </PrivateRoute>
        }
      />
      {/* THIS CAN PROBABLY BE BROKEN UP. CREATOR HAS ITS OWN RESPONSE AND EXPERT HAS ITS OWN */}
      <Route
        path="challenges/uploaded"
        element={
          <PrivateRoute>
            <Uploaded>
              {!search ? (
                <SubmissionSuccess name={currentUser?.username} />
              ) : (
                <OneToOneSuccess />
              )}
            </Uploaded>
          </PrivateRoute>
        }
      />
      {/* CHALLENGE ROUTES */}
      {/* EXPERT ROUTES */}
      <Route
        path={"experts_list"}
        element={
          <PrivateRoute>
            <Experts />
          </PrivateRoute>
        }
      />
      <Route
        path={"experts_request_feedback"}
        element={
          <PrivateRoute>
            <ExpertsRequestFeedback />
          </PrivateRoute>
        }
      />
      <Route
        path={"expert_request_feedback_form"}
        element={
          <PrivateRoute>
            <ExpertMediaUpload />
          </PrivateRoute>
        }
      />
      <Route
        path={"expert_pay"}
        element={
          <PrivateRoute>
            <ExpertsPayment />
          </PrivateRoute>
        }
      />
      {/* EXPERT ROUTES */}
      <Route
        path={"creator_profile"}
        element={
          <PrivateRoute>
            <CreatorProfile />
          </PrivateRoute>
        }
      />
      <Route
        path={"creator_tabs/*"}
        element={
          <PrivateRoute>
            <CreatorTabs />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default CreatorRoutes;

// function OneToOneSuccess() {
//   return (
//     <>
//       <p>The video and text response you uploaded were</p>
//       <p>successfully sent to the expert!</p>
//     </>
//   );
// }

function OneToOneSuccess() {
  return (
    <>
      <p className="upload_msg">
        We are currently <b>processing your payment</b> and will notify you when
        the Expert makes a decision.
      </p>
      <p className="upload_msg">
        <b>Note:</b> If the judge does not accept your request you will be
        refunded or credited to choose a new Expert.
      </p>
    </>
  );
}

function SubmissionSuccess({ name }: any) {
  return (
    <>
      <p>
        The files you uploaded were <b>successfully added!</b>
      </p>
      <p>Good Luck, {name}!</p>
    </>
  );
}
