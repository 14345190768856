import axios from "./axios";

const ADMIN_CHALLENGES_URL = "/admin/challenges";
const CHALLENGES_URL = "/challenges";

const LEADERBOARD_URL = "/users/leaderboard.json";

// ADMIN CHALLENGES

export async function createChallenge(
  accessToken: string | undefined,
  challenge: any
) {
  const data = challenge;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(`${ADMIN_CHALLENGES_URL}.json`, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function deleteChallenge(
  accessToken: string | undefined,
  id: any
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .delete(`${ADMIN_CHALLENGES_URL}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

/// USER CHALLENGES
export async function readChallenges(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${CHALLENGES_URL}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readChallenge(accessToken: string | undefined, id: any) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(`${CHALLENGES_URL}/${id}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readLeaders(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(LEADERBOARD_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
