import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { ActionCableConsumer } from "react-actioncable-provider";
import challenges from "../../../../assets/images/experts/challenges.png";
import challenges_judged from "../../../../assets/images/experts/challenges_judged.png";
import pending_requests_icon from "../../../../assets/images/experts/pending_requests.png";
import requests_answered from "../../../../assets/images/experts/requests_answered.png";
import "./ExpertsLandingPage.scss";

import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import { getRequestFeedbacks, getChallengeSubmissions } from "../expertSlice";
import ExpertOneToOneFeedback from "../approved/expert_one_to_one_feedback/ExpertOneToOneFeedback";
import ExpertSubmissionViewer from "../expert_submission_viewer/ExpertSubmissionViewer";
import OverdueOneToOneFeedbackRequest from "./overdue_one_to_one_feedback_request/OverdueOneToOneFeedbackRequest";
import CurrentOneToOneFeedbackRequest from "./current_one_to_one_feedback_request/CurrentOneToOneFeedbackRequest";
import CurrentChallenge from "./current_challenge/CurrentChallenge";
import OverdueChallenge from "./overdue_challenge/OverdueChallenge";
import ExpertSubmissionSuccess from "./expert_submission_success/ExpertSubmissionSuccess";

import { create_slug } from "app/tools";

// import ActionCable from "actioncable";

const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_WEBSOCKET_DEV_URL
    : process.env.REACT_APP_WEBSOCKET_PRODUCTION_URL;

const ExpertsLandingPage = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [media, setMedia] = useState();
  const [type, setType] = useState();
  const [success, setSuccess] = useState(false);
  const [upload, setUpload] = useState({});
  const [description, setDescription] = useState("");
  const [data, setData] = useState<any>([]);

  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const slug = create_slug(currentUser?.name);

  //CHALLENGE SUBMISSIONS
  const challenge_submissions = useSelector(
    (state: RootState) => state.expert.challenge_submissions
  );
  const judged = useSelector((state: RootState) => state.expert.judged);
  const unjudged = useSelector((state: RootState) => state.expert.unjudged);
  const ongoing_challenges = useSelector(
    (state: RootState) => state.expert.ongoing_challenges
  );
  const overdue_challenges = useSelector(
    (state: RootState) => state.expert.overdue_challenges
  );

  const ongoing_challenges_sliced = ongoing_challenges.slice(0, 3);
  const overdue_challenges_sliced = overdue_challenges.slice(0, 3);
  //CHALLENGE SUBMISSIONS

  //ONE TO ONE REQUESTS
  const one_to_one_requests = useSelector(
    (state: RootState) => state.expert.one_to_one_requests
  );
  const pending = useSelector((state: RootState) => state.expert.pending);
  const answered = useSelector((state: RootState) => state.expert.answered);

  const ongoing_requests = useSelector(
    (state: RootState) => state.expert.ongoing_requests
  );
  const overdue_requests = useSelector(
    (state: RootState) => state.expert.overdue_requests
  );

  const current_requests_sliced = ongoing_requests.slice(0, 3);
  const overdue_requests_sliced = overdue_requests.slice(0, 3);
  //ONE TO ONE REQUESTS

  // const handleInitialized = (data: any) => {
  //   console.log("going out", data);
  // };

  // const handleConnected = (data: any) => {
  //   console.log("What do we have here", data);
  // };

  // const handleReceivedData = (data: any) => {
  //   console.log("this the data", data);
  //   setData(data);
  // };

  async function response() {
    // await dispatch(getJudgeNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    // await dispatch(getNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
    await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
  }, []);

  // const cable = ActionCable.createConsumer(`${url}${accessToken}`);

  // const createSubscription = () => {
  //   cable.subscriptions.create(
  //     { channel: "UserChannel" },
  //     { received: (data) => handleReceivedMessage(data) }
  //   );
  // };

  const handleReceivedMessage = (data: any) => {
    console.log("WWWW", data);
  };

  // useEffect(() => {
  //   createSubscription();
  // }, []);

  return (
    <div className="experts_landing_page">
      {/* <ActionCableConsumer
        channel={{ channel: "UserChannel" }}
        onReceived={handleReceivedData}
        onConnected={handleConnected}
        onInitialized={handleInitialized}
      > */}
      <ExpertsLandingPageHeader
        currentUser={currentUser}
        ongoing_challenges={ongoing_challenges}
        pending={pending}
        judged={judged}
        answered={answered}
      />
      <div className="body">
        <hr />
        <ExpertItem
          expert={"overdue_request"}
          title={"Overdue 1-1 feedback requests"}
          slug={slug}
        >
          {overdue_requests_sliced.map((v: any, i: any) => {
            return (
              <OverdueOneToOneFeedbackRequest
                v={v}
                key={i}
                setOpen={setOpen}
                setMedia={setMedia}
                setUpload={setUpload}
                setDescription={setDescription}
                setType={setType}
              />
            );
          })}
        </ExpertItem>
        {/* <ExpertItem
          expert={"overdue_challenges"}
          title={"Overdue challenges"}
          slug={slug}
        >
          {overdue_challenges_sliced.map((v: any, i: any) => {
            return (
              <OverdueChallenge
                v={v}
                key={i}
                setOpen={setOpen}
                setMedia={setMedia}
                setUpload={setUpload}
                setType={setType}
              />
            );
          })}
        </ExpertItem> */}
        <ExpertItem
          expert={"current_request"}
          title={"Current 1-1 feedback requests"}
          slug={slug}
        >
          {current_requests_sliced.map((v: any, i: any) => {
            return (
              <CurrentOneToOneFeedbackRequest
                v={v}
                key={i}
                setOpen={setOpen}
                setMedia={setMedia}
                setUpload={setUpload}
                setType={setType}
              />
            );
          })}
        </ExpertItem>
        {/* <ExpertItem
          expert={"current_challenges"}
          title={"Current challenges"}
          slug={slug}
        >
          {ongoing_challenges_sliced.map((v: any, i: any) => {
            return (
              <CurrentChallenge
                v={v}
                key={i}
                setOpen={setOpen}
                setMedia={setMedia}
                setUpload={setUpload}
                setType={setType}
              />
            );
          })}
        </ExpertItem> */}
      </div>
      {open && (
        <Displayer>
          {" "}
          <ExpertSubmissionViewer
            open={open}
            media={media}
            type={type}
            description={description}
            setOpen={setOpen}
            setShow={setShow}
          />
        </Displayer>
      )}
      {show && (
        <UploadContainer>
          {" "}
          <ExpertOneToOneFeedback
            upload={upload}
            setOpen={setOpen}
            setShow={setShow}
            setSuccess={setSuccess}
          />
        </UploadContainer>
      )}
      <ExpertSubmissionSuccess success={success} setSuccess={setSuccess} />
      {/* </ActionCableConsumer> */}
    </div>
  );
};

export default ExpertsLandingPage;

function ExpertsLandingPageHeader({
  currentUser,
  ongoing_challenges,
  pending,
  judged,
  answered,
}: any) {
  return (
    <div className="header">
      <div className="title">
        <h3>Good morning {currentUser?.name}!</h3>
        <p>Here’s your daily rundown</p>
      </div>
      <TaskSummary>
        <TaskSummaryItem src={challenges} number={ongoing_challenges.length}>
          Ongoing challenges
        </TaskSummaryItem>
        <TaskSummaryItem src={pending_requests_icon} number={pending.length}>
          Pending requests
        </TaskSummaryItem>
        <TaskSummaryItem src={challenges_judged} number={judged.length}>
          Challenges judged
        </TaskSummaryItem>
        <TaskSummaryItem src={requests_answered} number={answered.length}>
          Requests answered
        </TaskSummaryItem>
      </TaskSummary>
    </div>
  );
}

function TaskSummary({ children }: any) {
  return (
    <div className="task_summary">
      <p>Task Summary</p>
      <div className="task_summary_items">{children}</div>
    </div>
  );
}

function TaskSummaryItem({ src, number, children }: any) {
  return (
    <div className="task_summary_item">
      <img src={src} />
      <span>{children}</span>
      <p>{number}</p>
    </div>
  );
}

function ExpertItem({ expert, title, children, slug }: any) {
  const navigate = useNavigate();
  const handleShowAll = (e: any) => {
    const { name } = e.target.dataset;
    navigate(`/expert/${slug}/experts_tabs/${expert}`, { state: { name } });
  };

  return (
    <div className={`expert_item ${expert}`}>
      <div className="expert_items_header">
        <p>{title}</p>
        <span onClick={handleShowAll} data-name={title}>
          Show all&nbsp; &gt;
        </span>
      </div>
      <div className="expert_item_list">{children}</div>
    </div>
  );
}

export function UploadContainer({ children }: any) {
  return (
    <div className="expert_media_container">
      <div className="modal_container">{children}</div>
    </div>
  );
}

export function Displayer({ children }: any) {
  return (
    <div className="expert_media_container">
      <div className="modal_container">{children}</div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
