import { useState } from "react";
import Header from "../../Header";
import Logo from "../../logo/Logo";
import NavItem from "../nav_item/NavItem";
import {
  LogoutBtn,
  NotificationBtn,
  MobileNotificationBtn,
  SettingsBtn,
  Avatar,
} from "../nav_item/NavItem";
import HamburgerMenu from "../../hamburger_menu/HamburgerMenu";
import { Link } from "react-router-dom";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";

/**
 *
 * NOTE
 * You can add a property url to NavCreator and use it for Creator and Expert
 */

const NavExpert = () => {
  //FOR MOBILE MENU
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow((prev) => !prev);
  //FOR MOBILE MENU

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  return (
    <Header>
      <nav>
        <Logo path={`/expert/${slug}`} />
        <div className="menu_desktop">
          <ul className="list">
            {/* <NavItem url="/experts/challenges">Challenges</NavItem>
            <NavItem url="/experts/experts/experts_list">Experts</NavItem> */}
            {/* <NavItem url={`/expert/${slug}/about`}>About</NavItem>
            <NavItem url={`/expert/${slug}/blog`}>Blog</NavItem> */}
            <li>
              <a
                href="https://aboutstudious.notion.site/aboutstudious/Studious-1d91b653b4484a8ab5176709d7fae811"
                target="_blank"
                rel="noreferrer"
              >
                <button>About</button>
              </a>
            </li>
            <li>
              <a
                href="https://studiousnet.squarespace.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button>Blog</button>
              </a>
            </li>
            <LogoutBtn />
            <NotificationBtn />
            <Avatar url={`/expert/${slug}/expert_profile`} />
          </ul>
        </div>
        <HamburgerMenu handleToggle={handleToggle} />
        <MobileNotificationBtn />
        <SettingsBtn />
        <MobileMenu show={show} setShow={setShow} slug={slug} />
      </nav>
    </Header>
  );
};

export default NavExpert;

function MobileMenu({ show, setShow, slug }: any) {
  const closeMenu = () => setShow(false);

  return (
    <div
      className={`menu_mobile${show ? " menu_open" : ""}`}
      onClick={closeMenu}
    >
      <ul>
        <li>
          <Link to={`/expert/${slug}/about`}>About</Link>
        </li>
        <li>
          <Link to={`/expert/${slug}/blog`}>Blog</Link>
        </li>

        <LogoutBtn />
      </ul>
    </div>
  );
}
