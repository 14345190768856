import "./Content.scss";
import Dashboard from "./dashboard/Dashboard";
import Users from "./users/Users";
import User from "./users/user/User";
import Challenges from "./challenges/Challenges";
import Challenge from "./challenges/challenge/Challenge";
import ChallengeForm from "./challenges/challenge_form/ChallengeForm";
import Experts from "./experts/Experts";
import { Routes, Route } from "react-router-dom";

export default function Content() {
  return (
    <div className="admin_content">
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="challenges" element={<Challenges />} />
        <Route path="challenges/challenge_form" element={<ChallengeForm />} />
        <Route path="challenge/:id" element={<Challenge />} />
        <Route path="users" element={<Users />} />
        {/* THIS NEEDS TO BE FIXED */}
        <Route path="user" element={<User />} />
        <Route path="experts" element={<Experts />} />
      </Routes>
    </div>
  );
}
