import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { validate, validateErrors, create_slug } from "app/tools";
import { RootState } from "app/store";
import { useDispatch, useSelector } from "react-redux";
import { signInUser, resetErrorState } from "../sessionSlice";
import "./SignIn.scss";

const paths = {
  admin: "/admin",
  expert: "/expert",
  creator: "/creator",
};

const SignIn = () => {
  interface Inputs {
    email: string;
    password: string;
    checked: boolean;
  }

  const initialState: Inputs = {
    email: "",
    password: "",
    checked: false,
  };

  const location = useLocation();
  const { state } = location as any;
  const [inputs, setInputs] = useState<Inputs>(state ? state : initialState);
  const [toggle, setToggle] = useState<boolean>(true);
  // const [errors, setErrors] = useState<Array<string>>([]);
  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );
  const [valid, setValid] = useState({
    email: `${state ? "" : "*"}`,
    password: `${state ? "" : "*"}`,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
  }

  const togglePassword = (e: any) => {
    setToggle((prev) => !prev);
  };

  async function handleSubmit(event: any) {
    event.preventDefault();

    const response = (await dispatch(signInUser(inputs))) as any;

    if (!response.payload.error) {
      const name = response.payload.user.name;
      const slug = create_slug(name);
      const role = response.payload.user.role;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path =
        role != "expert"
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paths[response.payload.user.role]
          : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            `${paths[response.payload.user.role]}/${slug}`;
      // navigate(paths[response.payload.user.role] || "/login");
      navigate(path || "/login");
    } else {
      // return setErrors(errorMessages);
    }
  }

  useEffect(() => {
    if (errorMessages.length > 0) {
      // setErrors(errorMessages);
      //dispatch(resetErrorState());
    }
  }, []);

  return (
    <div className="sign_in">
      <div className="header">
        <div className="half_star_container">
          <div></div>
        </div>
        <div className="title">
          <h3>Sign in</h3>
        </div>
        <div className="text">
          <p>Sign in to get access into your Studious account</p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div
              className="warning_modal"
              style={{ display: errorMessages.length ? "block" : "none" }}
            >
              Your email address or password was typed incorrectly.
            </div>
            <div className="form_item">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={inputs.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="password">Password</label>
              <input
                type={toggle ? "password" : "text"}
                name="password"
                placeholder="password"
                value={inputs.password}
                onChange={handleChange}
                required
              />
              <div className="password_reveal" onClick={togglePassword}>
                {toggle ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
            </div>
            <div className="forgot_password_btn">
              <Link to="/forgot_password">Forgot password?</Link>
            </div>
          </div>
          <button type="submit" disabled={!validate(valid) ? true : false}>
            Sign in
          </button>
          <p className="sign_up_option">
            Don’t have an account? <Link to="/signup">Sign up</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
