import { Routes, Route } from "react-router-dom";
import SubmissionFeedback from "./submission/SubmissionFeedback";
import SubmissionRequestFeedback from "./submission/SubmissionRequestFeedback";
import SubmissionView from "./submission/SubmissionView";

const Submission = () => {
  return (
    <div>
      <Routes>
        <Route path="view" element={<SubmissionView />} />
        <Route path="feedback" element={<SubmissionFeedback />} />
        <Route
          path="request_feedback"
          element={<SubmissionRequestFeedback />}
        />
      </Routes>
    </div>
  );
};

export default Submission;
