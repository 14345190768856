import { useEffect } from "react";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  getChallengeSubmissions,
  getRequestFeedbacks,
} from "app/features/experts/expertSlice";
import PreviousChallenge from "../../previous_challenge/PreviousChallenge";

const PreviousChallengeTab = () => {
  // ////////////////REMOVE THIS ///////////////////////////
  // ////////////////REMOVE THIS ///////////////////////////
  // ////////////////REMOVE THIS ///////////////////////////
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const judged = useSelector((state: RootState) => state.expert.judged);
  // ////////////////REMOVE THIS ///////////////////////////
  // ////////////////REMOVE THIS ///////////////////////////
  // ////////////////REMOVE THIS ///////////////////////////

  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  async function response() {
    // await dispatch(getChallengeSubmissions(accessToken));
    // ////////////////REMOVE THIS ///////////////////////////
    // ////////////////REMOVE THIS ///////////////////////////
    // ////////////////REMOVE THIS ///////////////////////////
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
    // ////////////////REMOVE THIS ///////////////////////////
    // ////////////////REMOVE THIS ///////////////////////////
    // ////////////////REMOVE THIS ///////////////////////////
  }

  useEffect(() => {
    response();
  }, []);
  return (
    <div className="items">
      {" "}
      {judged.map((v: any, i: any) => {
        return <PreviousChallenge v={v} key={i} />;
      })}
    </div>
  );
};

export default PreviousChallengeTab;
