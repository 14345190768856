import React from "react";
import ReactDom from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import "./assets/fonts/Gilmer/OTF/Gilmer-Heavy.otf";
import "./assets/fonts/Gilmer/OTF/Gilmer-Bold.otf";

const rootElement = document.getElementById("root");
const root = ReactDom.createRoot(rootElement as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//REACT_APP_BACKEND_URL="http://localhost:3000/api/v1"

export const URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_IMG_URL
    : process.env.REACT_APP_PRODUCTION_IMG_URL;
