import React, { useEffect } from "react";
import "./PrizeContainer.scss";

function PrizeContainer({ amount, setAmount }: any) {
  const barRef = React.createRef<HTMLDivElement>();
  const ballRef = React.createRef<HTMLDivElement>();
  const fiveRef = React.createRef<HTMLButtonElement>();

  const handleClick = (e: any) => {
    if (!e.target.id) return;
    setAmount(e.target.id);
    const margin = e.target.parentNode.parentNode.offsetLeft;
    const width = e.target.clientWidth / 2 + e.target.offsetLeft - margin;
    const bar = barRef.current as any;
    const ball = ballRef.current as any;
    bar.style.width = width + (ball.clientWidth / 2 + 2) + "px";
    ball.style.left = width - ball.clientWidth / 2 + "px";
    (document.querySelector(".highlight") as any).classList.remove("highlight");
    e.target.classList.add("highlight");
  };

  useEffect(() => {
    const five = fiveRef.current as any;
    const margin = five.parentNode.parentNode.offsetLeft;
    const width = five?.clientWidth / 2 + five.offsetLeft - margin;
    const bar = barRef.current as any;
    const ball = ballRef.current as any;
    bar.style.width = width + (ball.clientWidth / 2 + 2) + "px";
    ball.style.left = width - ball.clientWidth / 2 + "px";
  }, []);
  return (
    <div className="prize_increase_container">
      <div onClick={handleClick} className="prize_money_container">
        <div className="pot_value">
          <button id="5" ref={fiveRef} className="highlight">
            $5
          </button>
        </div>
        <div className="pot_value">
          <button id="15">$15</button>
        </div>
        <div className="pot_value">
          <button id="25">$25</button>
        </div>
        <div className="pot_value">
          <button id="50">$50</button>
        </div>
        <div className="pot_value">
          <button id="100">$100</button>
        </div>
        <div className="pot_value">
          <button id="200">$200</button>
        </div>
        <div className="pot_value">
          <button id="500">$500</button>
        </div>
      </div>
      <div className="separator_container">
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
        <div id="" className="separator">
          <button>|</button>
        </div>
      </div>
      <div className="value_bar_container">
        <div ref={barRef} className="value_bar">
          <div className="ball" ref={ballRef}></div>
        </div>
      </div>
    </div>
  );
}

export default PrizeContainer;
