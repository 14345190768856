import { useState, useRef, useEffect } from "react";
import { submitUser } from "../usersSlice";
import "./UsersForm.scss";
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { CountryDropdown } from "react-country-region-selector";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../store";

export default function UsersForm({ show, setShow }: any) {
  interface Inputs {
    name: string;
    email: string;
    // username: string;
    password: string;
  }
  const initialState: Inputs = {
    name: "",
    email: "",
    // username: "",
    password: "",
  };
  const nameRef = useRef<HTMLInputElement>();
  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [country, setCountry] = useState<string>("Canada");
  const [role, setRole] = useState<string>("creator");
  const [charity, setCharity] = useState<string>("");
  const refreshToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  useEffect(() => {
    nameRef?.current?.focus();
    setInputs(initialState);
    setCountry("Canada");
  }, [show]);

  function handleCharity(event: React.ChangeEvent<HTMLInputElement>) {
    setCharity(event.target.value);
  }

  function handleRole(event: React.ChangeEvent<HTMLInputElement>) {
    setRole(event.target.value);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const payload = {
      ...inputs,
      country,
      role,
      charity,
    };

    const response = (await dispatch(
      submitUser({ token: refreshToken, user: payload })
    )) as any;

    //WE NEED RESPONSE FOR ERROR HANDLING
    setShow(false);
  }

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
  }

  const closeModal = () => {
    setShow(false);
    setRole("creator");
  };

  return (
    <div
      className="admin_create_user_container"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="adduser">
        <section>
          <Container maxWidth="md">
            <Card sx={{ maxWidth: "md", boxShadow: "none" }}>
              <CardContent>
                <div className="admin_create_user_modal_header">
                  <button className="close" onClick={closeModal}>
                    ✖
                  </button>
                </div>
                <Container maxWidth="sm">
                  <Typography variant="h4" color="text.primary" gutterBottom>
                    Add User
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <FormGroup row={true} id="name-group">
                      <FormControl fullWidth>
                        <InputLabel required htmlFor="name" id="name-label">
                          Name
                        </InputLabel>
                        <Input
                          id="name"
                          type="text"
                          name="name"
                          value={inputs.name}
                          inputRef={nameRef}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </FormGroup>

                    <FormGroup row={true} id="email-group">
                      <FormControl fullWidth>
                        <InputLabel required htmlFor="email" id="email-label">
                          Email Address
                        </InputLabel>
                        <Input
                          id="email"
                          type="email"
                          name="email"
                          value={inputs.email}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </FormGroup>
                    {/* <FormGroup row={true} id="username-group">
                      <FormControl fullWidth>
                        <InputLabel
                          required
                          htmlFor="username"
                          id="username-label"
                        >
                          Username
                        </InputLabel>
                        <Input
                          id="username"
                          type="text"
                          name="username"
                          value={inputs.username}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </FormGroup> */}
                    <FormGroup row={true} id="password-group">
                      <FormControl fullWidth>
                        <InputLabel
                          required
                          htmlFor="password"
                          id="password-label"
                        >
                          Password
                        </InputLabel>
                        <Input
                          id="password"
                          type="text"
                          name="password"
                          value={inputs.password}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </FormGroup>
                    <FormControl
                      fullWidth
                      sx={{ marginTop: "15px", height: "50px" }}
                    >
                      <InputLabel
                        required
                        htmlFor="country"
                        id="country-label"
                        sx={{ top: "-25px", fontSize: "12px" }}
                      >
                        Country
                      </InputLabel>

                      <CountryDropdown
                        value={country}
                        classes="country__dropdown"
                        onChange={(val) => setCountry(val)}
                      />
                    </FormControl>
                    <FormGroup
                      row={true}
                      id="country-group"
                      sx={{ marginTop: "1em" }}
                    ></FormGroup>
                    <FormControl sx={{ marginRight: "80px" }}>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Role
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="creator"
                        name="radio-buttons-group"
                        value={role}
                        onChange={handleRole}
                      >
                        <FormControlLabel
                          value="creator"
                          control={<Radio />}
                          label="creator"
                          sx={{ height: "25px" }}
                        />
                        <FormControlLabel
                          value="judge"
                          control={<Radio />}
                          label="judge"
                          sx={{ height: "25px" }}
                        />
                        <FormControlLabel
                          value="admin"
                          control={<Radio />}
                          label="admin"
                          sx={{ height: "25px" }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Charity
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={handleCharity}
                      >
                        <FormControlLabel
                          value="charity_water"
                          control={<Radio />}
                          label="Charity Water"
                          sx={{ height: "25px" }}
                        />
                        <FormControlLabel
                          value="unity_charity"
                          control={<Radio />}
                          label="Unity Charity"
                          sx={{ height: "25px" }}
                        />
                        <FormControlLabel
                          value="free_the_children"
                          control={<Radio />}
                          label="Free the Children"
                          sx={{ height: "25px" }}
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormGroup
                      row={true}
                      id="submit-group"
                      sx={{ marginTop: "1em" }}
                    >
                      <FormControl fullWidth>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          id="submit-button"
                          sx={{
                            background: "#008cba",
                            ":hover": {
                              color: "black",
                              bgcolor: "white",
                            },
                          }}
                        >
                          Add
                        </Button>
                      </FormControl>
                    </FormGroup>
                  </form>
                </Container>
              </CardContent>
            </Card>
          </Container>
        </section>
      </div>
    </div>
  );
}
