import { useRef } from "react";

const FileUpload = ({ setInputs }: any) => {
  const fileRef = useRef<HTMLInputElement>(null);

  function handleUpload(e: any) {
    const files = e.target.files;
    if (files.length === 0) return;
    setInputs((prevState: any) => ({ ...prevState, medias: files[0] }));
  }

  function handleFile(e: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fileRef.current.value = "";
    fileRef?.current?.click();
  }

  return (
    <>
      <input
        type="file"
        name="media"
        onChange={handleUpload}
        ref={fileRef}
        multiple
        accept="video/mp4, video/webm, image/png, image/jpg"
        style={{ position: "absolute", left: -10000 }}
      />
      <p onClick={handleFile} id="upload">
        Browse
      </p>
    </>
  );
};

export default FileUpload;
