import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ChallengePaymentMethod.scss";
import PrizeContainer from "./prize_container/PrizeContainer";
import visa from "../../../../assets/images/challenges/visa.png";
import cup from "../../../../assets/images/challenges/cup.png";
import card from "../../../../assets/images/challenges/card.png";
import PaymentStripe from "./payment_stripe/PaymentStripe";
import { BsCreditCardFill } from "react-icons/bs";
import { FaChevronLeft } from "react-icons/fa";
// import { useSelector, useDispatch } from "react-redux";
// import { RootState } from "../../../store";
// import { StripeSetupPayment } from "../../sessions/sessionSlice";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import PaymentForm from "app/features/challenges/challenge_payment_method/pay/payment_form/PaymentForm";
// import { useSelector, useDispatch } from "react-redux";
// import { RootState } from "app/store";
// import { StripePayment } from "app/features/sessions/sessionSlice";

const ChallengePaymentMethod = () => {
  const [amount, setAmount] = useState<number>(5);
  const navigate = useNavigate();
  const location = useLocation() as any;
  // const accessToken = useSelector(
  //   (state: RootState) => state.session.accessToken
  // );
  //const dispatch = useDispatch();
  const handleNavigation = () => {
    navigate(-1);
  };

  // const goToPay = (e: any) => {
  //   navigate("pay", {
  //     state: { amount: amount, challenge_id: location?.state?.challenge_id },
  //   });
  // };

  const handleAddCard = async () => {
    //const res = (await dispatch(StripeSetupPayment(accessToken))) as any;
    // console.log("happening", res);
  };

  return (
    <div className="payment">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Sweeten the pot</h3>
          <p>
            The winners of this challenge will be awarded the pot as well as
            increased Studious score
          </p>
        </div>
      </div>
      <div className="body">
        <div className="payment_set_prize">
          <h4>1. Increase the prize winnings amount</h4>
          <PrizeContainer amount={amount} setAmount={setAmount} />
          <p>
            You’ve added <span>${amount}.00</span> to the pot
          </p>
        </div>
        <hr />
        <div className="payment_method">
          <h4>2. Select payment method</h4>
          <div className="payment_choice">
            <div>
              <section>
                <input type="radio" />
              </section>
              <img src={visa} />
              <div>
                <p>Credit or debit card</p>
                <p>**** 1234 10/22</p>
              </div>
            </div>
            <div>
              <section>
                <input type="radio" />
              </section>
              <img src={cup} />
              <div>
                <p>My Win Balance</p>
                <p>$21,102</p>
              </div>
            </div>
            <div>
              <section>
                <input type="radio" />
              </section>
              <img src={card} />
              <div>
                <p>Add payment card</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          width: 500,
          height: 70,
          margin: "0 auto",
          marginTop: 5,
        }}
      >
        <BsCreditCardFill /> <p>Add payment card</p>
        <button onClick={handleAddCard}>Add</button>
      </div> */}
      <PaymentStripe
        amount={amount}
        challenge_id={location?.state?.challenge_id}
        challenge_name={location?.state?.challenge_name}
      />
    </div>
  );
};

export default ChallengePaymentMethod;

// const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as any;
// const stripeTestPromise = loadStripe(PUBLIC_KEY);

// const Pay = ({ amount, challenge_id }: any) => {
//   const [clientSecret, setClientSecret] = useState("");
//   const accessToken = useSelector(
//     (state: RootState) => state.session.accessToken
//   );
//   const dispatch = useDispatch();

//   async function response(obj: any) {
//     const res = (await dispatch(StripePayment(obj))) as any;
//     setClientSecret(res.payload.clientSecret);
//   }

//   useEffect(() => {
//     const obj = {
//       token: accessToken,
//       charge: {
//         amount: parseInt(`${amount}00`),
//         currency: "usd",
//       },
//     };

//     response(obj);
//   }, []);

//   const appearance = {
//     theme: "stripe",
//   };

//   const options = {
//     clientSecret,
//     appearance,
//   };

//   return (
//     <section style={{ paddingTop: "13vh" }} className="stripe_container">
//       <div>
//         {clientSecret ? (
//           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//           // @ts-ignore
//           <Elements options={options} stripe={stripeTestPromise}>
//             <PaymentForm challenge_id={challenge_id} />
//           </Elements>
//         ) : (
//           <div>Loading</div>
//         )}
//       </div>
//     </section>
//   );
// };
