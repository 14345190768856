import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { submitSubmission } from "app/features/submissions/submissionSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import "./ChallengeMediaUpload.scss";
import MediaUpload from "app/features/media/media_upload/MediaUpload";

const ChallengeMediaUpload = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");

  interface Submission {
    challenge_id: any;
    description: string;
    medias: any;
  }

  const initialState: Submission = {
    challenge_id: id,
    description: "",
    medias: "",
  };

  const [inputs, setInputs] = useState<Submission>(initialState);

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };

  const updateTitle = (title: any) => {
    return `Select files and upload it to have a chance to win in ${title} challenge. PS. You can upload files later on your profile page`;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    console.log("THIS IS SHUT OFF FOR CHALLENGES");
    // if (!inputs.medias) return;
    // const formData = new FormData();

    // for (const prop in inputs) {
    //   formData.append(`submission[${prop}]`, (inputs as any)[prop]);
    // }

    // const response = (await dispatch(
    //   submitSubmission({ token: accessToken, submission: formData })
    // )) as any;

    // if (!response.payload.err) {
    //   navigate("/creator/challenges/uploaded");
    // } else {
    //   console.log("The error is", response.payload.err);
    // }
  }

  return (
    <MediaUpload
      inputs={inputs}
      setInputs={setInputs}
      handleSubmit={handleSubmit}
      handleNavigation={handleNavigation}
      title={updateTitle(name)}
    >
      <>
        <button type="submit" disabled={!inputs.medias ? true : false}>
          Submit
        </button>
        <button>Upload later</button>
      </>
    </MediaUpload>
  );
};

export default ChallengeMediaUpload;
