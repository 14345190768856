import axios from "./axios";
import { User } from "../features/sessions/sessionSlice";

const CREATE_URL = "/admin/create.json";
const READ_URL = "/admin/index";
const EXPERT_URL = "/admin/expert_update/";
const UPDATE_URL = "/admin/update.json";
const DELETE_URL = "/admin/delete.json";

export async function readUsers(
  accessToken: string | undefined,
  role: string | undefined
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const path = role ? "expert" : "creator";

  return axios
    .get(`${READ_URL}/${path}.json`, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function createUser(accessToken: string | undefined, users: User) {
  const data = {
    users: users,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(CREATE_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateUser(
  accessToken: string | undefined,
  user: User | any
) {
  const data = user.id
    ? {
        user: user,
      }
    : user;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(UPDATE_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateExpert(
  accessToken: string | undefined,
  user: User | any
) {
  const data = (({ id, ...o }: any) => o)(user);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(`${EXPERT_URL}${user.id}.json`, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function deleteUsers(
  accessToken: string | undefined,
  ids: Array<number>
) {
  const data = {
    ids: ids,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(DELETE_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
