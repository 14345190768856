import "./ConfirmationModal.scss";

export default function ConfirmationModal({
  confirmation,
  closeModal,
  sendData,
}: any) {
  return (
    <div
      className="confirmation_container"
      style={{
        display: confirmation ? "flex" : "none",
      }}
    >
      <div className="confirmation_modal">
        <button className="close" onClick={closeModal}>
          ✖
        </button>
        <p>Pressing continue will delete these records!</p>
        <button className="continue" onClick={sendData}>
          Delete
        </button>
        <button className="cancel" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>
  );
}
