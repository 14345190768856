import { useState, useEffect, useRef } from "react";
import { RootState } from "../../../../../store";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { BsFillPlayFill } from "react-icons/bs";
import {
  getChallenge,
  removeChallenge,
} from "../../../../challenges/challengeSlice";
import Loading from "app/features/tools/Loading";
import "./Challenge.scss";

const Challenge = () => {
  const [play, setPlay] = useState(false);
  const videoRef = useRef<any>(null);

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const data = useSelector((state: RootState) => state.challenge.data);
  const loading = useSelector((state: RootState) => state.challenge.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const back = () => {
    navigate(-1);
  };

  const handleVideo = (e: any) => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlay(true);
    } else {
      videoRef.current.pause();
      setPlay(false);
    }
  };

  const handleEnd = (e: any) => {
    e.target.currentTime = 0;
    setPlay(false);
  };

  const handleDelete = async () => {
    const challenge = { token: accessToken, id: id };
    const res = (await dispatch(removeChallenge(challenge))) as any;
    navigate("/admin/challenges");
  };

  async function response() {
    const challenge = { token: accessToken, id: id };
    await dispatch(getChallenge(challenge));
  }

  useEffect(function () {
    response();
  }, []);

  return (
    <div className="admin_challenge">
      <div className="admin_challenge_header">
        <button onClick={back}>Back</button>
      </div>
      <div className="admin_challenge_body">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="admin_challenge_body_header">
              <h2>Challenge</h2>
            </div>
            <div className="challenge">
              <div className="challenge_header">
                <img src={""} />
                <h3>{data?.challenge?.name}</h3>
              </div>
              <div className="challenge_body">
                <div className="challenge_body_video">
                  <div
                    className="challenge_body_video_wrapper"
                    onClick={handleVideo}
                  >
                    <video
                      src={data?.media}
                      ref={videoRef}
                      onEnded={handleEnd}
                    ></video>
                    <div
                      className="challenge_body_video_play_btn"
                      style={{ display: !play ? "grid" : "none" }}
                    >
                      <BsFillPlayFill />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    height: 200,
                    width: 600,
                    margin: "0 auto",
                    marginTop: 5,
                  }}
                >
                  {data?.challenge?.details}
                </p>
              </div>
            </div>
            <button onClick={handleDelete}>Delete</button>
            <div>
              <div>Judges</div>
              <div>
                <div style={{ display: "flex" }}>
                  {data?.judges?.map((v: any, i: any) => {
                    return (
                      <div
                        key={i}
                        className="udge"
                        style={{
                          height: 200,
                          width: 150,
                          margin: 2,
                          padding: 2,
                        }}
                      >
                        <div style={{ height: 150, border: "thin solid #999" }}>
                          <img style={{ width: "100%", height: "100%" }} />
                        </div>
                        <div
                          style={{
                            borderLeft: "thin solid #999",
                            borderRight: "thin solid #999",
                          }}
                        >
                          {v.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Challenge;
