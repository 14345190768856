import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./PaymentStripeForm.scss";

export default function PaymentStripeForm({
  challenge_id,
  challenge_name,
}: any) {
  const URL = `${window.location.origin}/creator/challenges/paid/${challenge_id}?name=${challenge_name}`;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: URL },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsLoading(false);
  };

  return (
    <form id="payment_form" onSubmit={handleSubmit}>
      <PaymentElement id="payment_element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="sweeten_pot_btn"
      >
        <span id="button_text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              Loading
            </div>
          ) : (
            "SWEETEN THE POT"
          )}
        </span>
      </button>
      {message && <div id="payment_message">{message}</div>}
    </form>
  );
}
