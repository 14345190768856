//
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createChallenge,
  readChallenges,
  readChallenge,
  deleteChallenge,
  readLeaders,
} from "../../api/challengeAPI";

//
export interface Challenge {
  name: string;
  details: string | undefined;
  participation: string | undefined;
  instructions: string | undefined;
  start_date: Date | null | undefined;
  end_date: Date | null | undefined;
}

interface ChallengesState {
  loading: boolean;
  error: boolean;
  errorMessages: string[];
  challenges?: Array<Challenge>;
  data?: any;
  leaders?: any;
}

interface challengesData {
  token: string | undefined;
  challenge: Challenge | any;
}

interface challengeData {
  token: string | undefined;
  id: string | undefined;
}

//
const initialState: ChallengesState = {
  loading: false,
  error: false,
  errorMessages: [],
  challenges: [],
  data: {},
  leaders: [],
};

//
export const submitChallenge = createAsyncThunk(
  "session/submitChallenge",
  async (payload: challengesData, { rejectWithValue }) => {
    const response = await createChallenge(payload.token, payload.challenge);

    if (response.error) {
      return rejectWithValue(response);
    }

    return response;
  }
);

export const getChallenges = createAsyncThunk(
  "session/getChallenges",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readChallenges(accessToken);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getChallenge = createAsyncThunk(
  "session/getChallenge",
  async (payload: challengeData, { rejectWithValue }) => {
    const response = await readChallenge(payload.token, payload.id);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const removeChallenge = createAsyncThunk(
  "session/removeChallenge",
  async (payload: challengeData, { rejectWithValue }) => {
    const response = await deleteChallenge(payload.token, payload.id);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

export const getLeaders = createAsyncThunk(
  "session/getLeaders",
  async (accessToken: string | undefined, { rejectWithValue }) => {
    const response = await readLeaders(accessToken);
    if (response.error) {
      return rejectWithValue(response.data);
    }

    return response;
  }
);

//
export const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    setChallenges: (state, action: any) => {
      state.challenges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChallenges.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallenges.fulfilled, (state, action: any) => {
        state.challenges = action.payload.challenges;
        //REMOVE THIS .addCase. IT IS NOT NEEDED IN ANY OTHER COMPONENT
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallenges.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(getChallenge.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallenge.fulfilled, (state, action: any) => {
        state.data = action.payload.challenge;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getChallenge.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      })
      .addCase(getLeaders.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getLeaders.fulfilled, (state, action: any) => {
        state.leaders = action.payload.leaders;
        state.loading = false;
        state.error = false;
        state.errorMessages = [];
      })
      .addCase(getLeaders.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorMessages = action.payload.errors;
      });
  },
});

//
export const { setChallenges } = challengeSlice.actions;

//
export default challengeSlice.reducer;
