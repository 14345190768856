import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RootState } from "app/store";
import { resetErrorState, signUpUser } from "../../../sessionSlice";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import { FaChevronLeft } from "react-icons/fa";
import { validate, validateErrors } from "app/tools";
import "./ResetPasswordSuccess.scss";

function ResetPasswordSuccess() {
  interface Inputs {
    name: string;
    email: string;
    password: string;
    username: string;
    role: string;
    country: string;
    charity: string;
    bio: string;
    official_title: string;
    specialization: string;
    personal_website: string;
    company_website: string;
    linkedin_url: string;
  }

  const initialState: Inputs = {
    name: "",
    email: "",
    password: "",
    username: "",
    role: "",
    country: "",
    charity: "",
    bio: "",
    official_title: "",
    specialization: "",
    personal_website: "",
    company_website: "",
    linkedin_url: "",
  };

  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );
  const [errors, setErrors] = useState<Array<string>>([]);
  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [valid, setValid] = useState({
    email: "*",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate("/email_sent");
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    (await dispatch(signUpUser(inputs))) as any;

    if (errorMessages.length > 0) {
      navigate("/"); ////HAVE TO UPDATE THIS
    } else {
      return setErrors(errorMessages);
    }
  }

  return (
    <section className="reset_password_success">
      <div className="reset_password_success_modal">
        <div className="lock"></div>

        <div className="title">
          <h3>Password successfully reset!</h3>
        </div>
        <Link to="/signin">
          <button>Sign in</button>
        </Link>
      </div>
    </section>
  );
}

export default ResetPasswordSuccess;
