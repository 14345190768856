import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import FeedbackTab from "./creator_tab/FeedbackTab";
import ChallengesTab from "./creator_tab/ChallengesTab";
import "./CreatorTabs.scss";

const CreatorTabs = () => {
  const [title, setTitle] = useState();

  const location = useLocation();
  const { pathname } = location as any;
  const name = pathname.slice(pathname.lastIndexOf("/") + 1);
  const navigate = useNavigate();

  const tab = {
    feedback: "1-1 feedback submissions",
    // challenges: "Challenges submissions",
  } as any;

  const paths = {
    "1-1 feedback submissions": "feedback",
    // "Challenges submission": "creator_challenges",
  } as any;

  const handleNavigation = () => {
    navigate(-1);
  };

  const handleToggle = (e: any) => {
    if (!e.target.id) return;
    setTitle(e.target.id);
    navigate(`${paths[e.target.id]}`);
  };

  const handleCashOut = (e: any) => {
    console.log("Payment is coming");
  };

  useEffect(() => setTitle(tab[name]), []);
  return (
    <div className="creator_tabs">
      {" "}
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>{title}</h3>
          <p>See a dashboard of all your requests and challenge submissions</p>
        </div>
      </div>
      <div className="body">
        <TabContainer title={title} handleToggle={handleToggle} />
        <Routes>
          <Route path={"feedback"} element={<FeedbackTab />} />
          <Route path={"creator_challenges"} element={<ChallengesTab />} />
        </Routes>
        <div className="cash_out_container">
          <button onClick={handleCashOut}>
            <div></div>Cash out winnings!
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatorTabs;

function TabContainer({ title, handleToggle }: any) {
  return (
    <ul onClick={handleToggle}>
      <Tab title={title} name={"1-1 feedback submissions"} />
      {/* <Tab title={title} name={"Challenges submission"} /> */}
    </ul>
  );
}

function Tab({ title, name }: any) {
  const checkTitle = (title: any, selection: any) => {
    return title === selection;
  };

  return (
    <li id={name} className={checkTitle(title, name) ? "selected" : ""}>
      {name}
    </li>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
