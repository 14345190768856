import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

// import ExpertOneToOneFeedback from "../expert_one_to_one_feedback/ExpertOneToOneFeedback";
// import ExpertSubmissionViewer from "../../expert_submission_viewer/ExpertSubmissionViewer";
// import ExpertSubmissionSuccess from "../../experts_landing_page/expert_submission_success/ExpertSubmissionSuccess";

import PendingFeedbackTab from "./pending_feedback_tab/PendingFeedbackTab";
import PendingChallengeTab from "./pending_challenge_tab/PendingChallengeTab";
import PreviousChallengeTab from "./previous_challenge_tab/PreviousChallengeTab";
import PreviousFeedbackTab from "./previous_feedback_tab/PreviousFeedbackTab";

import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";

import "./ProfileExpertTab.scss";
import "app/features/experts/approved/experts_tabs/ExpertsTabs.scss";

const ProfileExpertTab = () => {
  const [title, setTitle] = useState();
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [video, setVideo] = useState();
  const [upload, setUpload] = useState({});
  const [success, setSuccess] = useState(false);

  const location = useLocation();
  const { pathname } = location as any;
  const name = pathname.slice(pathname.lastIndexOf("/") + 1);

  const tab = {
    pending_feedbacks: "Pending 1-1 feedback requests",
    pending_challenges: "Pending challenges to judge",
    previous_feedbacks: "Previous feedback requests",
    previous_challenges: "Previous challenges",
  } as any;

  const paths = {
    "Pending 1-1 feedback requests": "pending_feedbacks",
    "Pending challenges to judge": "pending_challenges",
    "Previous feedback requests": "previous_feedbacks",
    "Previous challenges": "previous_challenges",
  } as any;

  const navigate = useNavigate();

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const handleNavigation = () => {
    navigate(`/expert/${slug}/expert_profile`);
  };

  const handleToggle = (e: any) => {
    if (!e.target.id) return;
    setTitle(e.target.id);
    navigate(`${paths[e.target.id]}`);
  };

  useEffect(() => setTitle(tab[name]), []);

  return (
    <div className="experts_tabs">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>{title}</h3>
          <p>See a dashboard of all your requests and challenges</p>
        </div>
      </div>
      <div className="body">
        <TabContainer title={title} handleToggle={handleToggle} />
        <Routes>
          <Route
            path={"pending_feedbacks"}
            element={
              <PendingFeedbackTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
          <Route
            path={"pending_challenges"}
            element={
              <PendingChallengeTab
                setOpen={setOpen}
                setVideo={setVideo}
                setUpload={setUpload}
              />
            }
          />
          <Route
            path={"previous_feedbacks"}
            element={<PreviousFeedbackTab />}
          />
          <Route
            path={"previous_challenges"}
            element={<PreviousChallengeTab />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default ProfileExpertTab;

function TabContainer({ title, handleToggle }: any) {
  return (
    <ul onClick={handleToggle}>
      <Tab title={title} name={"Pending 1-1 feedback requests"} />
      {/* <Tab title={title} name={"Pending challenges to judge"} /> */}
      <Tab title={title} name={"Previous feedback requests"} />
      {/* <Tab title={title} name={"Previous challenges"} /> */}
    </ul>
  );
}

function Tab({ title, name }: any) {
  const checkTitle = (title: any, selection: any) => {
    return title === selection;
  };

  return (
    <li id={name} className={checkTitle(title, name) ? "selected" : ""}>
      {name}
    </li>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
