import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsFillHouseFill } from "react-icons/bs";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";
import "app/features/experts/experts_landing_page/expert_submission_success/ExpertSubmissionSuccess.scss";

const ExpertSignupSuccess = ({ success, setSuccess }: any) => {
  const closeModal = (e: any) => {
    document.body.style.overflow = "scroll";
    setSuccess(false);
  };

  return (
    <div
      className="expert_submission_success"
      style={{ display: success ? "flex" : "none" }}
    >
      <div className="success_modal">
        <div className="success_modal_header"></div>
        <div className="success_modal_body">
          <div className="img"></div>
          <div className="text">
            We have received your request and will be in touch soon!
          </div>
          <Link to={`/`}>
            <button onClick={closeModal}>
              <BsFillHouseFill />
              back to home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExpertSignupSuccess;
