import { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStripeForm from "app/features/challenges/challenge_payment_method/payment_stripe/payment_stripe_form/PaymentStripeForm";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/store";
import { StripePayment } from "app/features/sessions/sessionSlice";
import "./PaymentStripe.scss";

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as any;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const PaymentStripe = ({ amount, challenge_id, challenge_name }: any) => {
  const [clientSecret, setClientSecret] = useState("");
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  async function response(obj: any) {
    const res = (await dispatch(StripePayment(obj))) as any;
    setClientSecret(res.payload.clientSecret);
  }

  useEffect(() => {
    const obj = {
      token: accessToken,
      charge: {
        amount: parseInt(`${amount}00`),
        currency: "usd",
      },
    };

    response(obj);
  }, []);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <section className="stripe_container">
      <div>
        {clientSecret ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Elements options={options} stripe={stripeTestPromise}>
            <PaymentStripeForm
              challenge_id={challenge_id}
              challenge_name={challenge_name}
            />
          </Elements>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </section>
  );
};

export default PaymentStripe;
