import { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import { convertToDate } from "app/tools";
import "./Selfie.scss";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
};

const Selfie = ({ setInputs, handleSelfie }: any) => {
  const webcamRef = useRef<Webcam>(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [src, setSrc] = useState("");
  const [file, setFile] = useState();
  const [saved, setSaved] = useState<boolean>(false);
  /**
   *
   * TO CHECK IF USER IS USING MOBILE
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  //const isMobile = /Android|iPhone/i.test(navigator.userAgent);
  /**
   *
   * TO CHECK IF USER IS USING MOBILE
   */

  const handleSwitchCamera = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot() as any;
    setSrc(imageSrc);

    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `${convertToDate()}.png`, {
          type: "image/png",
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setFile(file);
      });
  }, []);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = src;
    a.download = `${convertToDate()}.png`;
    a.click();
  };

  return (
    <div className="selfie_wrapper">
      <div className="selfie_modal">
        <div className={`selfie_header ${saved ? "hide_header" : ""}`}>
          <h3>Take a photo</h3>
          <p onClick={handleSelfie}>X</p>
        </div>
        <div className="selfie_body">
          <Video
            webcamRef={webcamRef}
            videoConstraints={videoConstraints}
            facingMode={facingMode}
            capture={capture}
            src={src}
          />
          <Img
            src={src}
            setSrc={setSrc}
            handleSelfie={handleSelfie}
            setInputs={setInputs}
            file={file}
            saved={saved}
            setSaved={setSaved}
          />
        </div>
      </div>
    </div>
  );
};

export default Selfie;

function Video({ webcamRef, videoConstraints, facingMode, capture, src }: any) {
  return (
    <div
      className="webcam_container"
      style={{ display: !src ? "block" : "none" }}
    >
      <div className="webcam_video">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/png"
          screenshotQuality={1}
          videoConstraints={{
            ...videoConstraints,
            facingMode,
          }}
        />
      </div>
      <div className="webcam_video_controls">
        <div className="media_choices">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            capture();
          }}
          className="webcam_btn"
        ></button>
        {/* <button onClick={handleSwitchCamera}>Switch camera</button> */}
      </div>
    </div>
  );
}

function Img({
  src,
  setSrc,
  handleSelfie,
  setInputs,
  file,
  saved,
  setSaved,
}: any) {
  return (
    <div
      className="webcam_container"
      style={{ display: src ? "block" : "none" }}
    >
      <div className="webcam_img">
        <img src={src} />
        <div style={{ opacity: saved ? ".9" : "0" }}></div>
      </div>
      <div className={`webcam_image_controls  ${saved ? "hide" : ""}`}>
        <button
          onClick={(e) => {
            e.preventDefault();
            setSaved(true);
            setTimeout(() => {
              handleSelfie();
              setInputs((prevState: any) => ({ ...prevState, medias: file }));
            }, 1000);
          }}
        >
          Save photo
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setSrc("");
          }}
        >
          Retake photo
        </button>
      </div>
    </div>
  );
}
