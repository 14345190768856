import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { submitRequestFeedback } from "app/features/experts/expertSlice";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import MediaUpload from "app/features/media/media_upload/MediaUpload";
import { create_slug } from "app/tools";
import Loading from "app/features/tools/Loading";
import { BiLoader } from "react-icons/bi";
import "./ExpertMediaUpload.scss";

const ExpertMediaUpload = () => {
  const [loading, setLoading] = useState(false);
  const locate = useLocation();
  const { state } = locate as any;
  const { id, amount } = state;
  interface ExpertSubmission {
    description: string;
    medias: any;
    price: number;
    judge_id: number;
  }

  const initialState: ExpertSubmission = {
    description: "",
    medias: "",
    price: 0,
    judge_id: id,
  };

  const [inputs, setInputs] = useState<ExpertSubmission>(initialState);
  const title =
    "Select files, take a picture or video, or write the type of feedback you want it to receive feedback from the expert you choose.";

  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (!inputs.medias) return;
    const formData = new FormData();
    inputs.price = amount;

    for (const prop in inputs) {
      if (prop === "medias") continue;
      formData.append(`request_feedback[${prop}]`, (inputs as any)[prop]);
    }

    formData.append("request_feedback[medias][]", inputs.medias);
    setLoading(true);
    const response = (await dispatch(
      submitRequestFeedback({ token: accessToken, request_feedback: formData })
    )) as any;

    if (!response.payload.err) {
      setLoading(false);
      navigate(`/creator/expert_pay`, {
        state: { amount },
      });
    }

    //ERRORS GET TAKEN CARE OF IN THE SLICE CODE
  }

  return (
    <>
      <MediaUpload
        inputs={inputs}
        setInputs={setInputs}
        handleSubmit={handleSubmit}
        handleNavigation={handleNavigation}
        title={title}
      >
        <button type="submit" disabled={!inputs.medias ? true : false}>
          {loading ? <BiLoader /> : "Proceed to payment"}
        </button>
      </MediaUpload>
      {loading && <Loading />}
    </>
  );
};

export default ExpertMediaUpload;
