import { useState } from "react";
import Header from "../Header";
import Logo from "../logo/Logo";
import NavItem from "./nav_item/NavItem";
import HamburgerMenu from "../hamburger_menu/HamburgerMenu";
import "./Nav.scss";
import { Link } from "react-router-dom";

const Nav = () => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow((prev) => !prev);

  return (
    <Header>
      <nav>
        <Logo path={"/"} />
        <div className="menu_desktop">
          <ul className="list">
            <NavItem url="/expert_signup_start">Apply to Judge</NavItem>
            {/* <NavItem url="/challenges">Challenges</NavItem> */}
            <NavItem url="/experts">Experts</NavItem>
            <li>
              <a
                href="https://aboutstudious.notion.site/aboutstudious/Studious-1d91b653b4484a8ab5176709d7fae811"
                target="_blank"
                rel="noreferrer"
              >
                <button>About</button>
              </a>
            </li>
            <li>
              <a
                href="https://studiousnet.squarespace.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button>Blog</button>
              </a>
            </li>
            {/* <NavItem url="https://aboutstudious.notion.site/aboutstudious/Studious-1d91b653b4484a8ab5176709d7fae811">
              About
            </NavItem> */}
            {/* <NavItem url="/blog">Blog</NavItem> */}
            <NavItem url="/signin">Login</NavItem>
          </ul>
        </div>
        <HamburgerMenu handleToggle={handleToggle} />
        <MobileMenu show={show} setShow={setShow} />
      </nav>
    </Header>
  );
};

export default Nav;

function MobileMenu({ show, setShow }: any) {
  const closeMenu = () => setShow(false);

  return (
    <div
      className={`menu_mobile${show ? " menu_open" : ""}`}
      onClick={closeMenu}
    >
      <ul>
        <li>
          {" "}
          <Link to={"/expert_signup_start"}>Apply to Judge</Link>
        </li>
        <li>
          <Link to={"/experts"}>Experts</Link>
        </li>
        <li>
          <a
            href="https://aboutstudious.notion.site/aboutstudious/Studious-1d91b653b4484a8ab5176709d7fae811"
            target="_blank"
            rel="noreferrer"
          >
            About
          </a>
        </li>
        <li>
          <a
            href="https://studiousnet.squarespace.com/"
            target="_blank"
            rel="noreferrer"
          >
            Blog
          </a>
        </li>
        <li>
          <Link to={"/signin"} className="signin_btn">
            Login
          </Link>
        </li>
      </ul>
    </div>
  );
}
