import "./List.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 130 },
  { field: "email", headerName: "Email", width: 130 },
  { field: "username", headerName: "User Name", width: 130 },
  { field: "role", headerName: "Role", width: 130 },
  { field: "country", headerName: "Country", width: 130 },
  { field: "charity", headerName: "Charity", width: 130 },
];

export default function List({ rows, handleRowSelection }: any) {
  const navigate = useNavigate();

  function handleDoubleClick(event: any) {
    if (!event.id) return;
    //MIGHT BE BETTER TO GET THE ID OF THE USER FROM THE ARRAY IN STORE.  I CAN USE MEMOIZED TO SIMPLIFY THINGS
    navigate(`/admin/user`, { state: event.row });
  }

  return (
    <div className="admin_users_container">
      <div className="admin_users_list" onDoubleClick={handleDoubleClick}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          checkboxSelection
          onSelectionModelChange={handleRowSelection}
          onRowDoubleClick={handleDoubleClick}
        />
      </div>
    </div>
  );
}
