import { GiHamburgerMenu } from "react-icons/gi";

const HamburgerMenu = ({ handleToggle }: any) => {
  return (
    <div className="hamburger_menu">
      <GiHamburgerMenu onClick={handleToggle} />
    </div>
  );
};

export default HamburgerMenu;
