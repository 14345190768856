import { Key } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import "./LeaderboardExpanded.scss";

const LeaderboardExpanded = () => {
  const location = useLocation();
  const { state } = location as any;
  const leaders = JSON.parse(state);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(-1);
  };

  return (
    <div className="leaderboard_expanded">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Leaderboard</h3>
          <p>See the rankings for `&quot;`comp name`&quot;`</p>
        </div>
      </div>
      <div className="body">
        <div className="leaderboard">
          <div className="leaderboard_title">
            <span>Name</span>
            <span>Rank</span>
            <span>Points</span>
          </div>
          <div className="leaderboard_body">
            {/* TO BE REMOVED */}
            <div className="leaderboard_item">
              <div className="leaderboard_item_avatar">
                <img src={leaders[0] && leaders[0].avatar} />
              </div>
              <div className="leaderboard_item_info">
                <div className="leaderboard_item_name">
                  <h5>{leaders[0] && leaders[0].name}</h5>
                </div>
                <div className="leaderboard_item_place">
                  🥇{" "}
                  <span>
                    {(leaders[0] && leaders[0].place) || "1st Place!"} &#8729; $
                    {395}
                  </span>
                </div>
                <div className="leaderboard_item_points">
                  <h5>
                    {39} <span className="pts">pts</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="leaderboard_item">
              <div className="leaderboard_item_avatar">
                <img src={leaders[0] && leaders[0].avatar} />
              </div>
              <div className="leaderboard_item_info">
                <div className="leaderboard_item_name">
                  <h5>{leaders[0] && leaders[0].name}</h5>
                </div>
                <div className="leaderboard_item_place">
                  🥈{" "}
                  <span>
                    {(leaders[0] && leaders[0].place) || "2nd Place!"} &#8729; $
                    {250}
                  </span>
                </div>
                <div className="leaderboard_item_points">
                  <h5>
                    {37} <span className="pts">pts</span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="leaderboard_item">
              <div className="leaderboard_item_avatar">
                <img src={leaders[0] && leaders[0].avatar} />
              </div>
              <div className="leaderboard_item_info">
                <div className="leaderboard_item_name">
                  <h5>{leaders[0] && leaders[0].name}</h5>
                </div>
                <div className="leaderboard_item_place">
                  🥉{" "}
                  <span>
                    {(leaders[0] && leaders[0].place) || "3rd Place!"} &#8729; $
                    {150}
                  </span>
                </div>
                <div className="leaderboard_item_points">
                  <h5>
                    {36} <span className="pts">pts</span>
                  </h5>
                </div>
              </div>
            </div>
            {/* TO BE REMOVED */}
            {leaders.map((v: any, i: Key | null | undefined) => {
              return (
                <div className="leaderboard_item" key={i}>
                  <div className="leaderboard_item_avatar">
                    <img src={v.avatar} />
                  </div>
                  <div className="leaderboard_item_info">
                    <div className="leaderboard_item_name">
                      <h5>{v.name}</h5>
                    </div>
                    <div
                      className="leaderboard_item_place"
                      style={{ display: v.place ? "flex" : "none" }}
                    >
                      🥇{" "}
                      <span>
                        {v.place || "1st Place!"} &#8729; ${v.score}
                      </span>
                    </div>
                    <div className="leaderboard_item_points">
                      <h5>
                        {v.total} <span className="pts">pts</span>
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardExpanded;
