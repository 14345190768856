import { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { RootState } from "app/store";
import { useSelector } from "react-redux";
import { create_slug } from "app/tools";

export default function PaymentForm() {
  const URL = `${window.location.origin}`;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const slug = create_slug(currentUser?.name);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${URL}/creator/challenges/uploaded`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{
          width: 70,
          height: 35,
          border: "thin solid #888",
          borderRadius: 5,
        }}
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              Loading
            </div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
