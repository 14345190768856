import axios from "./axios";
import {
  UserSignUpData,
  newsLetterData,
  forgotData,
  resetData,
  Score,
} from "../features/sessions/sessionSlice";

/**
 * Slices to create
 * stripe or payments
 * judge
 */

/**
 * ROUTES
 */
//SESSIONS
const SIGNUP_URL = "/users.json";
const LOGIN_URL = "/oauth/token";
const LOGOUT_URL = "/oauth/revoke";

//ADMIN
const APPROVED_JUDGES_URL = "/admin/approved.json";

//USER
const CURRENT_USER_URL = "/users/me.json";
const NOTIFICATIONS_URL = "/users/notifications";
const JUDGE_NOTIFICATIONS_URL = "/users/feedback_judge_notifications";
const USER_NOTIFICATIONS_URL = "/users/feedback_user_notifications";
const STRIPE_URL = "/pay";
// const STRIPE_PAYMENT_SETUP_URL = "/payment_setup";

const FEEDBACK_URL = "/feedbacks.json";
const NEWSLETTER_SUBSCRIPTION_URL = "/newsletter_subscription.json";
const RESET_PASSWORD_URL = "/reset.json";
const FORGOT_PASSWORD_URL = "/forgot.json";

/**
 * ROUTES
 */

/**
 * DOORKEEPER KEYS
 */

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

/**
 * DOORKEEPER KEYS
 */

export async function createUser(payload: UserSignUpData) {
  const data = {
    ...payload,
    client_id: CLIENT_ID,
  };

  return axios
    .post(SIGNUP_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function loginUser(email: string, password: string) {
  const data = {
    grant_type: "password",
    email: email,
    password: password,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGIN_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function logoutUserWithToken(token: string) {
  const data = {
    token: token,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGOUT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestAccessTokenWithRefreshToken(refreshToken: string) {
  const data = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGIN_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readCurrentUser(accessToken: string) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(CURRENT_USER_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readApprovedJudges(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(APPROVED_JUDGES_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function stripePaymentIntent(
  accessToken: string | undefined,
  charge: any
) {
  const data = {
    charge: charge,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .post(STRIPE_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readNotifications(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(NOTIFICATIONS_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readJudgeNotifications(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(JUDGE_NOTIFICATIONS_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function readUserNotifications(accessToken: string | undefined) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .get(USER_NOTIFICATIONS_URL, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function createScore(
  accessToken: string | undefined,
  payload: Score
) {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const feedback = { feedback: { ...payload } };

  return axios
    .post(FEEDBACK_URL, feedback, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function creasteNewletterSubscription(payload: newsLetterData) {
  const data = {
    ...payload,
    client_id: CLIENT_ID,
  };

  return axios
    .post(NEWSLETTER_SUBSCRIPTION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function resetPassword(payload: resetData) {
  const data = {
    ...payload,
    client_id: CLIENT_ID,
  };

  return axios
    .post(RESET_PASSWORD_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function forgotPassword(payload: forgotData) {
  const data = {
    ...payload,
    client_id: CLIENT_ID,
  };

  return axios
    .post(FORGOT_PASSWORD_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
