import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FaChevronLeft } from "react-icons/fa";
import PaymentForm from "./payment_form/PaymentForm";
import PaymentProcessed from "./payment_processed/PaymentProcessed";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { StripePayment } from "../../sessions/sessionSlice";
import "./ExpertsPayment.scss";

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY as any;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const ExpertsPayment = () => {
  const location = useLocation();
  const { amount } = location.state as any;
  const navigate = useNavigate();
  const [clientSecret, setClientSecret] = useState("");
  const [processed, setProcessed] = useState<boolean>(false);

  // const URL = `${window.location.origin}/challenges/paid/${challenge_id}`;
  // const stripe = useStripe();
  // const elements = useElements();
  // const [message, setMessage] = useState<string | null>(null);
  // const [isLoading, setIsLoading] = useState(false);

  const [filled, setFilled] = useState<boolean>(false);
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate(-1);
  };

  async function response(obj: any) {
    const res = (await dispatch(StripePayment(obj))) as any;
    setClientSecret(res.payload.clientSecret);
  }

  // const handleSubmit = async (e: any) => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setIsLoading(true);

  //   const { error } = await stripe.confirmPayment({
  //     elements,
  //     confirmParams: { return_url: URL },
  //   });

  //   if (error.type === "card_error" || error.type === "validation_error") {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     setMessage(error.message);
  //   } else {
  //     setMessage("An unexpected error occured.");
  //   }
  //   setIsLoading(false);
  // };

  useEffect(() => {
    const obj = {
      token: accessToken,
      charge: {
        amount: parseInt(`${amount}00`),
        currency: "usd",
      },
    };

    response(obj);
  }, []);

  const appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "8px",
      colorBackground: "#F8F8FB",
      colorPrimaryText: "#262626",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "22px",
        marginBottom: "20px",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <section className="feedback_payment_form">
      <div className="header">
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} />
          <span>Back</span>
        </div>
        <div className="title">
          <h3>Payment</h3>
          <p>All transactions are secure and encrypted.</p>
        </div>
      </div>
      <div className="upload_container">
        <h1>
          Feedback Total: <span>{`$${amount}.00`}</span>
        </h1>
        <div className="payment_options">
          <div>
            <input type="radio" /> credit or debit card
          </div>
        </div>
        {clientSecret ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Elements options={options} stripe={stripeTestPromise}>
            <PaymentForm />
          </Elements>
        ) : (
          <div>Loading</div>
        )}
      </div>
      <PaymentProcessed processed={processed} />
    </section>
  );
};

export default ExpertsPayment;
