import Avatar from "app/features/tools/Avatar";
import ExpertTimeInterval from "../../ExpertTimeInterval";
import { parseTime } from "../../expert_tools";
import white_clock from "../../.././../../assets/images/experts/white_clock.png";
import "./CurrentChallenge.scss";

import { RootState } from "app/store";
import { useSelector } from "react-redux";

const CurrentChallenge = ({
  v,
  setOpen,
  setMedia,
  setUpload,
  setType,
}: any) => {
  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const { days } = parseTime(v.end_date);
  ///////////////////////////THIS MUST ME REMOVED//////////////////////////////////
  function random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  ///////////////////////////THIS MUST ME REMOVED//////////////////////////////////

  const toggleOpen = () => {
    setOpen(true);
    setMedia(v.media);
    setType(v.type);
    setUpload({
      submission_id: v.submission.id,
      user_id: currentUser?.id,
      criteria: v.criteria.map((criterion: any) => {
        return {
          submission_id: v.submission.id,
          user_id: currentUser?.id,
          criterium_id: criterion.id,
          value: random(3, 5),
        };
      }),
      challenge_id: v.challenge.id,
      type: "challenge submission",
    });
  };

  return (
    <div
      className={`current_challenges_item ${Number(days) < 1 ? "urgent" : ""}`}
    >
      <div className="overdue_header">
        <Avatar v={v} />
        <div className="overdue_info">
          <p>{v.challenge.name}</p>
        </div>
      </div>
      <div className="overdue_body">
        <div className="overdue_body_info">
          <img src={white_clock} />
          <p>Time left to judge challenge</p>
        </div>
        <div
          className={`overdue_challenges_time ${
            Number(days) < 1 ? "urgent_color" : ""
          }`}
        >
          {ExpertTimeInterval(v.challenge.end_date)}
        </div>
      </div>
      <div className="overdue_footer">
        <button onClick={toggleOpen}>Judge challenge now</button>
      </div>
    </div>
  );
};

export default CurrentChallenge;
