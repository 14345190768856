import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { RootState } from "../../../../store";
import { resetErrorState, signUpUser } from "../../sessionSlice";
import Star from "app/features/tools/star/Star";
import { FaChevronLeft } from "react-icons/fa";
import { validate, validateErrors, create_slug } from "../../../../tools";
import "./CreatorSignUpFinal.scss";

function CreatorSignUpFinal() {
  const location = useLocation();
  const { state } = location as any;

  interface Inputs {
    name: string;
    email: string;
    password: string;
    username: string;
    role: string;
    country: string;
    charity: string;
    bio: string;
    official_title: string;
    specialization: string;
    personal_website: string;
    company_website: string;
    linkedin_url: string;
    interests: string;
    age: string;
  }

  const initialState: Inputs = {
    name: state.name,
    email: state.email,
    password: state.password,
    username: "username",
    role: "creator",
    country: "",
    charity: "",
    bio: "",
    official_title: "",
    specialization: "",
    personal_website: "",
    company_website: "",
    linkedin_url: "",
    interests: "",
    age: "",
  };

  const errorMessages = useSelector(
    (state: RootState) => state.session.errorMessages
  );
  const [errors, setErrors] = useState<Array<string>>([]);
  const [inputs, setInputs] = useState<Inputs>(initialState);
  const [valid, setValid] = useState({
    interests: "*",
    specialization: "*",
    age: "*",
    personal_website: "*",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    navigate("/signup", { state: state });
  };

  function handleChange(event: any) {
    const { name, value } = event.target;
    setInputs((prevState: any) => ({ ...prevState, [name]: value }));
    validateErrors(valid, name, value);
    setValid(valid);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    const response = (await dispatch(signUpUser(inputs))) as any;

    if (errorMessages.length === 0) {
      navigate(`/creator`, { state: { new_user: state.new_user } });
    } else {
      return setErrors(errorMessages);
    }
    // navigate("/creator", { state: { new_user: state.new_user } });
  }

  return (
    <section className="creator_signup_final">
      <div className="header">
        <div style={{ display: errors.length > 0 ? "block" : "none" }}>
          Errors: {errors.length}
        </div>
        <div className="navigation">
          <FaChevronLeft onClick={handleNavigation} /> <span>Back</span>
        </div>
        <Star />
        <div className="title">
          <h3>Creator sign up</h3>
          <div>
            <span>2 of 2</span>
          </div>
        </div>
        <div>
          <p>
            A few simple steps and you&apos;ll get access to the Studious
            platform
          </p>
        </div>
      </div>
      <div className="form_container">
        <form onSubmit={handleSubmit}>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="interests">
                Interests <sup>*</sup>
              </label>
              <input
                type="text"
                name="interests"
                placeholder="Interests"
                value={inputs.interests}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="specialization">
                Specialization <sup>*</sup>
              </label>
              <input
                type="text"
                name="specialization"
                placeholder="specialization"
                value={inputs.specialization}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form_item_container">
            <div className="form_item">
              <label htmlFor="age">
                Age <sup>*</sup>
              </label>
              <input
                type="text"
                name="age"
                placeholder="Must be a number"
                value={inputs.age}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form_item">
              <label htmlFor="personal_website">
                Personal website <sup>*</sup>
              </label>
              <input
                type="url"
                name="personal_website"
                placeholder="URL"
                value={inputs.personal_website}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <button type="submit" disabled={!validate(valid) ? true : false}>
            Sign Up
          </button>
          <p className="sign_in_option">
            Already have an account? <Link to="/signin">Sign in</Link>
          </p>
        </form>
      </div>
    </section>
  );
}

export default CreatorSignUpFinal;
