import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootState } from "app/store";
import { useSelector, useDispatch } from "react-redux";
import {
  getRequestFeedbacks,
  getChallengeSubmissions,
} from "app/features/experts/expertSlice";
import CurrentOneToOneFeedbackRequest from "app/features/experts/experts_landing_page/current_one_to_one_feedback_request/CurrentOneToOneFeedbackRequest";
import CurrentChallenge from "app/features/experts/experts_landing_page/current_challenge/CurrentChallenge";
import PreviousFeedback from "./previous_feedback/PreviousFeedback";
import PreviousChallenge from "./previous_challenge/PreviousChallenge";

import { Displayer } from "app/features/experts/experts_landing_page/ExpertsLandingPage";
import { UploadContainer } from "app/features/experts/experts_landing_page/ExpertsLandingPage";
import ExpertSubmissionViewer from "app/features/experts/expert_submission_viewer/ExpertSubmissionViewer";
import ExpertOneToOneFeedback from "app/features/experts/approved/expert_one_to_one_feedback/ExpertOneToOneFeedback";
import ExpertSubmissionSuccess from "app/features/experts/experts_landing_page/expert_submission_success/ExpertSubmissionSuccess";

import { create_slug } from "app/tools";
import "./ProfileContent.scss";

const ProfileContent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [video, setVideo] = useState();
  const [upload, setUpload] = useState({});
  const [success, setSuccess] = useState(false);

  const currentUser = useSelector(
    (state: RootState) => state.session.currentUser
  );
  const accessToken = useSelector(
    (state: RootState) => state.session.accessToken
  );

  // const pending = useSelector((state: RootState) => state.expert.pending);
  const ongoing_challenges = useSelector(
    (state: RootState) => state.expert.ongoing_challenges
  );
  // const overdue_challenges = useSelector(
  //   (state: RootState) => state.expert.overdue_challenges
  // );

  const slug = create_slug(currentUser?.name);

  const judged = useSelector((state: RootState) => state.expert.judged);
  const answered = useSelector((state: RootState) => state.expert.answered);
  const ongoing_challenges_sliced = ongoing_challenges.slice(0, 3);
  const answered_sliced = answered.slice(0, 3);

  const ongoing_requests = useSelector(
    (state: RootState) => state.expert.ongoing_requests
  );
  // const overdue_requests = useSelector(
  //   (state: RootState) => state.expert.overdue_requests
  // );

  const dispatch = useDispatch();

  const current_requests_sliced = ongoing_requests.slice(0, 3);
  // const overdue_requests_sliced = overdue_requests.slice(0, 3);
  const previous_judged_sliced = judged.slice(0, 3);

  async function response() {
    // await dispatch(getJudgeNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    // await dispatch(getNotifications(accessToken));//THIS SHOULD BE CALLED LIKE THIS
    await dispatch(
      getRequestFeedbacks({ token: accessToken, id: currentUser?.id })
    );
    await dispatch(getChallengeSubmissions(accessToken));
  }

  useEffect(() => {
    response();
  }, []);
  return (
    <div>
      <ExpertItem
        expert={"pending_feedbacks"}
        title={"pending_feedback"}
        slug={slug}
      >
        {current_requests_sliced.map((v: any, i: any) => {
          return (
            <CurrentOneToOneFeedbackRequest
              v={v}
              key={i}
              setOpen={setOpen}
              setVideo={setVideo}
              setUpload={setUpload}
            />
          );
        })}
      </ExpertItem>
      {/* <ExpertItem
        expert={"pending_challenges"}
        title={"pending_challenge"}
        slug={slug}
      >
        {ongoing_challenges_sliced.map((v: any, i: any) => {
          return (
            <CurrentChallenge
              v={v}
              key={i}
              setOpen={setOpen}
              setVideo={setVideo}
              setUpload={setUpload}
            />
          );
        })}
      </ExpertItem> */}
      <ExpertItem
        expert={"previous_feedbacks"}
        title={"previous_feedback"}
        slug={slug}
      >
        {previous_judged_sliced.map((v: any, i: any) => {
          return <PreviousFeedback v={v} key={i} />;
        })}
      </ExpertItem>
      {/* <ExpertItem
        expert={"previous_challenges"}
        title={"previous_challenge"}
        slug={slug}
      >
        {previous_judged_sliced.map((v: any, i: any) => {
          return <PreviousChallenge v={v} key={i} />;
        })}
      </ExpertItem> */}
      {open && (
        <Displayer>
          {" "}
          <ExpertSubmissionViewer
            open={open}
            video={video}
            setOpen={setOpen}
            setShow={setShow}
          />
        </Displayer>
      )}
      {show && (
        <UploadContainer>
          {" "}
          <ExpertOneToOneFeedback
            upload={upload}
            setOpen={setOpen}
            setShow={setShow}
            setSuccess={setSuccess}
          />
        </UploadContainer>
      )}
      <ExpertSubmissionSuccess success={success} setSuccess={setSuccess} />
      {/* </ActionCableConsumer> */}
    </div>
  );
};

export default ProfileContent;

function ExpertItem({ expert, title, children, slug }: any) {
  const navigate = useNavigate();
  const handleShowAll = (e: any) => {
    const { name } = e.target.dataset;
    navigate(`/expert/${slug}/experts_profile_tab/${expert}`, {
      state: { name },
    });
  };

  const mediaObj = {
    pending_feedback: "pending_feedback",
    pending_challenge: "pending_challenge",
    previous_feedback: "previous_feedback",
    previous_challenge: "previous_challenge",
  } as any;

  const pending_class = title === mediaObj[title] ? mediaObj[title] : "";
  const pending_title = title === mediaObj[title] ? "" : title;

  return (
    <div className={`expert_item ${expert}`}>
      <div className="expert_items_header">
        <p className={pending_class}>{pending_title}</p>
        <span onClick={handleShowAll} data-name={title}>
          Show all &nbsp; &gt;
        </span>
      </div>
      <div className="expert_item_list">{children}</div>
    </div>
  );
}
